import React, {CSSProperties} from 'react';
import { Badge } from 'antd';

import { TCategory } from 'src/types/category';

import styles from './styles.module.scss';
import {getColor} from "src/shared/lib/get-color";


export const categoryCell = (status?: TCategory) => {

    const style: CSSProperties | undefined = status?.id ? {
        backgroundColor: getColor(String(status.id))
    } : undefined;

    return (
        <div className={styles.cell} style={style}>
            { status && (
                <Badge size="default">
                    { status.name }
                </Badge>
            ) }
        </div>
    );
};

