import React, { useRef } from 'react';
import cn from 'classnames';

import { TooltipComponent } from 'src/components/tooltip';
import styles from './styles.module.scss';


// function getTime(val: string) {
//     const time: string = val;
//     const now = moment(new Date());
//     const end = moment(time);
//     const duration: Duration = moment.duration(now.diff(end));
//     return duration.asMinutes();
// }
// const text_forms = ['минута', 'минуты', 'минут']
// function declOfNum(n: number) {
//     const num = Math.abs(n) % 100;
//     const n1 = num % 10;
//     if (num > 10 && num < 20) { return text_forms[2]; }
//     if (n1 > 1 && n1 < 5) { return text_forms[1]; }
//     if (n1 === 1) { return text_forms[0]; }
//     return text_forms[2];
// }
export const VoteStatusCell = (status: boolean | string, rowData: any) => {
    return <Item rowData={rowData}/>
}
function Item({rowData}:{rowData: any}) {
    const message = useRef('Вы ещё не проголосовали');
    const isGreen = useRef(false);
    const isYellow = useRef(false);
    // const timer = useRef(30);
    // const [state, setState] = useState({
    //     change: true,
    //     count: 0
    // });

    if (rowData.voteStatus === 'VOTED' ) {
        message.current = 'Ваш голос учтён';
        isGreen.current = true;
    }
    if (rowData.voteStatus === 'PART_VOTED' ) {
        message.current = 'Вы можете изменить ваш выбор';
        isGreen.current = true;
    }
    // if (rowData.voteStatus === 'PART_VOTED' && rowData.votes && rowData.votes[0].dateTime) {
    //     const minutes = getTime(rowData.votes && rowData.votes[0].dateTime);
    //     isYellow.current = minutes < 30;
    //     timer.current = Math.floor(30 - minutes)
    //     if (timer.current < 0) {
    //         message.current = `Ваш голос учтён`;
    //         isGreen.current = true;
    //         if (state.count === 0) setState({...state, count: state.count + 1})
    //     } else {
    //         isGreen.current = false;
    //         isYellow.current = true;
    //         message.current = 'Вы можете изменить ваш выбор'
    //         // message.current = `Вы можете изменить ваш выбор в течение 30 минут. У вас осталось ${timer.current} ${declOfNum(timer.current)}.`
    //     }
    // }

    // const handler = () => {
    //     try {
    //         if (rowData.voteStatus === 'PART_VOTED' && rowData.votes && rowData.votes[0].dateTime) {
    //             timer.current = Math.floor(30 - getTime(rowData.votes && rowData.votes[0].dateTime))
    //             if (timer.current < 0) {
    //                 message.current = `Ваш голос учтён`;
    //                 isGreen.current = true;
    //                 if (state.change) setState({...state, count: state.count + 1, change: false})
    //             } else {
    //                 isGreen.current = false;
    //                 isYellow.current = true;
    //                 message.current = 'Вы можете изменить ваш выбор'
    //                 setState({...state, count: state.count + 1})
    //             }
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    return (
        <div className={styles.cell}>
            <TooltipComponent
                isActive={true}
                message={message.current}
            >
                <div className={
                    cn(
                        styles.bullet,
                        {
                            [styles.active]: isGreen.current,
                            [styles.justVoted]: isYellow.current,
                        },
                    )}
                />
            </TooltipComponent>

        </div>
    );
}