import { put } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import moment from 'moment';

import { DateAndTimeServerFormat } from 'src/constatnts/format';

import {
    AddVotingAction,
} from 'src/store/voting-editor/actions';

import { mainModuleRoutes } from 'src/routing/projects-module';
import {LogsActionsStartAction} from "src/store/logs/actions";
import {NotificationOpen} from "src/shared/lib/notification";

export function* editorAddVotingWorker(
    { voting }: ReturnType<typeof AddVotingAction>,
) {
    try {
        const data = {
            ...voting,
            familiarizationDateTime: moment(voting.familiarizationDateTime).format(DateAndTimeServerFormat),
            startDateTime: moment(voting.startDateTime).format(DateAndTimeServerFormat),
            endDateTime: moment(voting.endDateTime).format(DateAndTimeServerFormat)
        }

        yield axios({
            method: 'POST',
            url: `/voting`,
            headers: {},
            data,
        });

        yield put(push(mainModuleRoutes['all-votes-list'].path));
        yield put(LogsActionsStartAction('CREATE_VOTING'))
    } catch (error) {
        const message = error?.response?.data?.message;
        NotificationOpen({type: 'error', info: {message: message || 'Ошибка создания голосования'}})
    }
}
