import React, {FC, SyntheticEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectFile, selectFiles, selectFileStatus} from "src/store/files/selectiors";
import {LogsActionsStartAction} from "src/store/logs/actions";

import styles from "src/components/cart-ptoject/styles.module.scss";

import {Image, Spin, Tooltip} from "antd";

import {PDFModal} from "src/components/modals/pdf-modal";
import {MultilineText} from "src/components/detailed-page/multiline-text";
import {InternetResources} from "src/components/detailed-page/internet-resources";

import {formatCurrency, formatCurrencyPercent} from "src/utils/currencyForematter";

import {TPDFModal} from "src/types/modals";
import {GetFileRealizationStartAction} from "src/store/files/actions";
import pdfImg from "src/assets/img/pdf.png";
import viewDocumentImg from "src/assets/img/view-document.png";
import {LoadingOutlined} from "@ant-design/icons";
import createMarkup from "src/utils/createMarkup";
import {selectRealizationProjectStatus} from "src/store/realization/selectors";

const declension = (day: number) => {
    const days = [' месяц',' месяца',' месяцев'];
    let count = day % 100;
    if (count >= 5 && count <= 20) {
        return day + days[2];
    } else {
        count = count % 10;
        if (count === 1) {
            return day + days[0];
        } else if (count >= 2 && count <= 4) {
            return day + days[1];
        } else {
            return day + days[2];
        }
    }
}

type CartProjectProps = {
    data: any,
    id: string,
    type: 'image' | 'video' | 'presentation'
}
const CartProject: FC<CartProjectProps> = ({data = {image: {id: 0}}, id, type}) => {
    const put = useDispatch();
    const image = useSelector(selectFile(
        (data && data.image) ? `${data.image.id}` : undefined
    ));
    const {isLoading: isLoadingImage} = useSelector(selectFileStatus(
        (data && data.image) ? `${data.image.id}` : undefined
    ));
    const requestFiles = useSelector(selectFiles(
        (data && data.requestFiles) ? data.requestFiles.map((file: { id: any; }) => (`${file.id}`)) : undefined
    ));
    const presentationFile = useSelector(selectFile(
        (data && data.presentationFile) ? `${data?.presentationFile?.id}` : undefined
    ));
    const {isLoading} = useSelector(selectFileStatus(
        (data && data.presentationFile) ? `${data?.presentationFile?.id}` : undefined
    ));
    const {isLoading: isLoadingProject} = useSelector(selectRealizationProjectStatus);

    const [modalData, setModalData] = useState<TPDFModal>(null);

    useEffect(() => {
        if (!image && data.image) put(GetFileRealizationStartAction(data.image.id, 'image'))
        if (requestFiles.includes(null) && data?.requestFiles) {
            data.requestFiles.forEach((el: any) => put(GetFileRealizationStartAction(el.id)))
        }
    }, [data])

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
        try {
            let fileURL = window.URL.createObjectURL(file);
            setModalData({fileURL: fileURL, id, name});
            put(LogsActionsStartAction('VIEW_PROJECT_ATTACHMENTS'));
        } catch (error) {}
    };

    const closePDFModal = () => {
        setModalData(null);
    };

    const typeContent = () => {
        switch (type) {
            case "image":
                if (image) {
                    return (
                        <Spin spinning={isLoadingImage}>
                            <Image className={styles.image}
                                src={image}
                                alt="Изображение"/>
                        </Spin>
                    )
                }
                break
            case "video":
                return (
                    <div className={styles.item}>
                        <div className={styles.text}>
                            <iframe
                                className={styles.video}
                                title="presentation"
                                width="510"
                                height="264"
                                src={data.presentation.url}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                )
            case "presentation":
                if (isLoading) return <LoadingOutlined />
                if (presentationFile === null) return null
                return (
                    <div
                        className={styles.document}
                        onClick={openPDFModal(presentationFile, 'Презентация')}>
                        <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                        <span>Презентация</span>
                        <Tooltip placement="top" title="Просмотр документа">
                            <img
                                src={viewDocumentImg}
                                alt="document"
                                className={styles.documentImg} />
                        </Tooltip>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <Spin spinning={isLoadingProject}>
            <div className={styles.container}>
                <div className={styles.contentColumn}>
                    {/*PNS-448 17.01.2023*/}
                    {/*<Button type='primary' className={styles.btn}>*/}
                    {/*    <Link*/}
                    {/*        className={styles.link}*/}
                    {/*        to={`/realization/${id}/project`}*/}
                    {/*        onClick={() => {*/}
                    {/*            put(NavigationSet('Отбор проектов'))*/}
                    {/*        }}>Открыть заявку*/}
                    {/*    </Link>*/}
                    {/*</Button>*/}


                    <div className={styles.titleBlock}>
                        <strong>{data.name}</strong>
                    </div>

                    <div className={styles.owner}>
                        {data.owner}
                    </div>

                    <br/>

                    {data.session && (
                        <>
                            <div className={styles.titleBlock}>
                                <strong>Конкурс: </strong>
                                <span>{data.session.contest.name}</span>
                            </div>
                            <div className={styles.titleBlock}>
                                <strong>Отбор: </strong>
                                <span>{data.session?.name}</span>
                            </div>
                        </>
                    )}

                    {data.category && (
                        <div className={styles.titleBlock}>
                            <strong>Номинация: </strong>
                            <span>{data.category?.name}</span>
                        </div>
                    )}


                    {data.implStatus && (
                        <div className={styles.titleBlock}>
                            <strong>Статус: </strong>
                            <strong>{data.implStatus}</strong>
                        </div>
                    )}

                    <br/>

                    {!!data.recommendedCostReduction && (
                        <div className={styles.item}>
                            <div className={`${styles.text} ${styles.recommendedCostReduction} ${styles.recommendedCostReductionSmall}`}>
                                Поддержан с учетом снижения стоимости проекта на <span>{formatCurrency(data.recommendedCostReduction || '' )}</span> рублей
                            </div>
                        </div>
                    )}

                    <div className={styles.row}>
                        <div className={styles.rowItem}>
                            <div className={styles.title}>Бюджет</div>
                            <div className={`${styles.text} ${styles.budget}`}>
                                { formatCurrency(data.budget || '') }
                            </div>
                        </div>

                        <div className={styles.rowItem}>
                            <div className={styles.title}>Плановый КПЭ</div>
                            <div className={`${styles.text} ${styles.budget}`}>
                                { formatCurrency(data.kpi || '') }
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        {!!data.supportAmount && (
                            <div className={styles.rowItem}>
                                <div className={styles.title}>Сумма оказанной поддержки</div>
                                <div className={`${styles.text} ${styles.budget}`}>
                                    { formatCurrency(data.supportAmount || '') }
                                    {data.budget && (
                                        <span> ({formatCurrencyPercent(data.supportAmount, data.budget, 0)})</span>
                                    )}
                                </div>
                            </div>
                        )}

                        {!!data.actualKpi && !!data.kpi && (
                            <div className={styles.rowItem}>
                                <div className={styles.title}>Фактический КПЭ</div>
                                <div className={`${styles.text} ${styles.budget}`}>
                                    { formatCurrency(data.actualKpi || '') }
                                    {data.kpi && (
                                        <span> ({formatCurrencyPercent(data.actualKpi, data.kpi, 0)})</span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {!!data.cofinancing && (
                        <div className={styles.item}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(data.cofinancing)}
                                className={`${styles.text} ${styles.recommendedCostReduction} ${styles.recommendedCostReductionSmall}`}/>
                        </div>
                    )}

                    {!!data.ownProjectSite && (
                        <div className={styles.item}>
                            <div className={styles.title}>Собственный сайт проекта</div>
                            <div className={styles.text} dangerouslySetInnerHTML={createMarkup(data.ownProjectSite)}/>
                        </div>
                    )}

                    {!!data.ownProjectSite && (
                        <div className={styles.item}>
                            <div className={styles.title}>Команда проекта</div>
                            <div className={styles.text} dangerouslySetInnerHTML={createMarkup(data.ownProjectSite)}/>
                        </div>
                    )}

                    { (data && data.internetResources) && (
                        <div className={styles.item}>
                            <div className={styles.title}>Интернет-ресурсы</div>
                            <div className={`${styles.text} ${styles.socials}`}>
                                <InternetResources data={data.internetResources} />
                            </div>
                        </div>
                    ) }

                    {/*{ (requestFiles && requestFiles.length > 0) && (*/}
                    {/*    // @ts-ignore*/}
                    {/*    <PdfItem files={requestFiles} data={data} label={'requestFiles'} openPDFModal={openPDFModal} text={'Заявка'} />*/}
                    {/*) }*/}

                    {!!data.achievements && (
                        <div className={styles.item}>
                            <div
                                className={`${styles.text} ${styles.recommendedCostReduction}`}
                                dangerouslySetInnerHTML={createMarkup(data.achievements)}/>
                        </div>
                    )}
                </div>
                <div className={styles.contentColumn}>
                    {typeContent()}

                    <br/>

                    { data.implStartDate && data.implEndDate && (
                        <div className={styles.item}>
                            <div className={styles.title}>Сроки реализации</div>
                            <div className={styles.text}>
                                Начало реализации планируется на {data.implStartDate} <br/>
                                Окончание реализации планируется на {data.implEndDate} <br/>
                                {data.implTerms && (
                                    <>
                                        Срок реализации {declension(data.implTerms)}
                                    </>
                                )}
                            </div>
                        </div>
                    ) }

                    { data.description && (
                        <div className={styles.item}>
                            <div className={styles.title}>Описание проекта</div>
                            <div className={styles.text}>
                                <MultilineText text={data.description || ''} showShort={true}/>
                            </div>
                        </div>
                    ) }
                    { (data && data.formatDescription) && (
                        <div className={styles.item}>
                            <div className={styles.title}>Формат контента</div>
                            <div className={styles.text}>
                                <MultilineText text={data.formatDescription || ''} />
                            </div>
                        </div>
                    ) }

                </div>

                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            </div>
        </Spin>
    )
}

export default CartProject;
