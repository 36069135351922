import { InferValueTypes } from 'src/types/common';
import { ProjectsActionTypes } from 'src/store/projects/action-types';
import * as actions from 'src/store/projects/actions';
import { TAdminProjectsOptions, TProjectContent } from 'src/types/projects';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TProjectsByVotingIdState = {
    votingId?: number;
    filters: TAdminProjectsOptions;
    projects: TProjectContent[];
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TProjectsByVotingIdState = {
    votingId: undefined,
    filters: {
        filterData: {
            nominationIds: [],
            minScore: 0,
            maxScore: 10,
            searchString: '',
            sessionName: undefined,
            year: undefined,
        },
        page: 1,
        pageSize: 20,
        totalCount: 0,
        sort: {
            direction: 'ASC',
            field: 'id',
        },
    },
    projects: [],
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function adminsProjects(
    state: TProjectsByVotingIdState = initialState,
    action: ActionTypes,
): TProjectsByVotingIdState {
    switch (action.type) {
        case ProjectsActionTypes.RESET_ADMINS_PROJECTS_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters },
            };
        case ProjectsActionTypes.SET_ADMINS_PROJECTS_FILTERS:
            return {
                ...state,
                filters: { ...action.filters },
            };
        case ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_START:
            return {
                ...state,
                isLoading: true,
            };
        case ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_SUCCESS:
            return {
                ...state,
                projects: action.data,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
                isLoading: false,
                isLoaded: true,
                error: null,
            };
        case ProjectsActionTypes.GET_PROJECTS_BY_VOTING_ID_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.error,
            };
        default:
            return state;
    }
}
