
import { NotificationModel } from '..';
import eventsBus from "src/shared/lib/eventsBus";

const eventName = 'NotificationOpen';
export const subscribe = (callback: Function) => {
    return eventsBus.subscribe(eventName, callback);
}; 

export const open = (data: NotificationModel.NotificationType) => {
    eventsBus.broadcast(eventName, data);
};
