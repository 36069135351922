import { InferValueTypes } from 'src/types/common';
import { GeneralActionTypes } from 'src/store/general/action-types';
import * as actions from './actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TGeneralState = {
    isSidebarOpen: boolean;
    isMobile: boolean;
    isSidebarActive: boolean;
    isProfileActive: boolean;
    isVoteFiltersActive: boolean;
};
const width = window.innerWidth;
export const initialState: TGeneralState = {
    isSidebarOpen: width > 992,
    isMobile: width <= 992,
    isSidebarActive: false,
    isProfileActive: false,
    isVoteFiltersActive: true,
};

export default function reducer(state: TGeneralState = initialState, action: ActionTypes): TGeneralState {
    switch (action.type) {
        case GeneralActionTypes.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarActive: typeof action.payload === 'boolean' ? action.payload : !state.isSidebarActive,
            };
        case GeneralActionTypes.TOGGLE_PROFILE:
            return {
                ...state,
                isProfileActive: typeof action.payload === 'boolean' ? action.payload : !state.isProfileActive,
            };
        case GeneralActionTypes.TOGGLE_VOTE_FILTERS:
            return {
                ...state,
                isVoteFiltersActive: typeof action.payload === 'boolean' ? action.payload : !state.isVoteFiltersActive,
            };
        case GeneralActionTypes.TOGGLE_SIDEBAR_MENU:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen
            }
        default:
            return state;
    }
}
