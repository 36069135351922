import React, {FC} from "react";
import styles from "src/components/modals/projects-modal/styles.module.scss";
import {Form, Input} from "antd";
import cn from "classnames";

type ModalInputProps = {
    form: any
    label: string
    text: string
    placeholder?: string
    showErrorText?: boolean
}

const ModalInput: FC<ModalInputProps> = ({form, label, text, placeholder, showErrorText}) => {
    return (
        <Form.Item
            className={styles.formItem}
            validateStatus={ (
                form.submitCount > 0 && form.errors[label]
            ) ? 'error' : '' }
        >
            <label htmlFor={label} className={styles.label}>
                {text}
            </label>
            <Input
                className={cn(
                    styles.input,
                    { [`${styles.error}`]: form.submitCount > 0 && form.errors[label] }
                )}
                name={label}
                placeholder={placeholder ? placeholder : ''}
                value={form.values[label]}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
            />
            {showErrorText && form.errors[label] && form.touched[label] && (
                <>
                    <br/>
                    <small style={{color: '#FF2E56'}}>{form.errors[label]}</small>
                </>
            )}

        </Form.Item>
    )
}
export default ModalInput