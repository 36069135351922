import React, {FC, useEffect} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {useDispatch, useSelector} from "react-redux";
import Connect from "src/components/connect";
import {GetCurrentUser} from "src/store/auth/actions";
import {selectIsLoggedIn} from "src/store/auth/selectors";

const KeycloakChecker: FC<{children: React.ReactNode}> = ({children}) => {
    const {keycloak, initialized} = useKeycloak();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const put = useDispatch()
    const url = new URL(window.location.href).pathname;
    useEffect(() => {
        if (!keycloak.authenticated && initialized && url !== '/error') {
            keycloak.login();
        }
    }, [initialized, keycloak])// eslint-disable-line

    useEffect(() => {
        if (keycloak.idTokenParsed) {
            put(GetCurrentUser())
        }
    }, [keycloak.idTokenParsed]);//eslint-disable-line

    if ((!keycloak.authenticated && url !== '/error') || !isLoggedIn) return <Connect/>
    return (<>{children}</>)
};

export default KeycloakChecker;