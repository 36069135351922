import React, {SyntheticEvent, useEffect, useState,} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Image, Tabs, Tooltip} from 'antd';

import pdfImg from "src/assets/img/pdf.png";
import viewDocumentImg from "src/assets/img/view-document.png";

import {GetProjInfoStartAction} from 'src/store/project-info/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

import {selectProjInfo} from 'src/store/project-info/selectiors';
import {selectFile, selectFiles} from 'src/store/files/selectiors';

import PdfItem from 'src/features/project-detailed-page/pdf-item';
import {ErrorBoundary} from 'src/components/error-boundary';
import {mainModuleRoutes} from 'src/routing/projects-module';
import {ToParentPage} from 'src/components/to-parent-page';
import {MultilineText} from 'src/components/detailed-page/multiline-text';
import {InternetResources} from 'src/components/detailed-page/internet-resources';
import {PDFModal} from 'src/components/modals/pdf-modal';

import {TProjectContent} from 'src/types/projects';
import {TPDFModal} from 'src/types/modals';

import {formatCurrency, formatCurrencyPercent} from 'src/utils/currencyForematter';


import styles from './styles.module.scss';
import createMarkup from "src/utils/createMarkup";
import classNames from "classnames";
import {selectSystemSettingSA_Ak} from "src/store/system-settings/selectors";

const colors: Record<string, string> = {
    '1': 'videoContent',
    '2': 'blogSphere',
    '4': 'multiFormat',
};

export const ProjectsDetailedPage = () => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const { pathname } = useLocation();
    const id = pathname.split('/')[2];
    const put = useDispatch();
    const data: TProjectContent = useSelector(selectProjInfo(id));
    const systemSetting = useSelector(selectSystemSettingSA_Ak);
    // @ts-ignore
    const presentationFile = useSelector(selectFile(
        (data && data.presentationFile) ? `${data.presentationFile.id}` : undefined
    ));
    const image = useSelector(selectFile(
        (data && data.image) ? `${data.image.id}` : undefined
    ));

    const files = useSelector(selectFiles(
        (data && data.otherFiles) ? data.otherFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const requestFiles = useSelector(selectFiles(
        (data && data.requestFiles) ? data.requestFiles.map((file) => (`${file.id}`)) : undefined
    ));
    const examinationFiles = useSelector(selectFiles(
        (data && data.examinationFiles) ? data.examinationFiles.map((file) => (`${file.id}`)) : undefined
    ));

    let colorStyle = '';

    if ((data && data.category) && `${data.category.id}` in colors) {
        colorStyle = colors[data.category.id];
    }

    useEffect(() => {
        if (id) {
            put(GetProjInfoStartAction(id));
        }
    }, [put, id]);

    useEffect(() => {
        if (modalData?.id && modalData?.id !== id) {
            setModalData(null);
        }
    }, [id, modalData]);

    const handleChangeTabs = (e: string) => {
        if (e === `${3}`) {
            if (presentationFile !== null) openPDFModal(presentationFile, 'Презентация')();
        }
        if (e === `${2}`) put(LogsActionsStartAction('VIEW_VIDEO_PRESENTATION', id, data.name))
    };

    const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
        try {
            let fileURL = window.URL.createObjectURL(file);

            setModalData({
                fileURL: fileURL,
                id,
                name,
            });
            put(LogsActionsStartAction('VIEW_PROJECT_ATTACHMENTS', id, data.name));
        } catch (error) {}
    };

    const closePDFModal = () => {
        setModalData(null);
    };



    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={mainModuleRoutes.list.path} />
            </div>
            <ErrorBoundary>
                <div className={styles.contentColumn}>

                    <div className={classNames(styles.projectName)}>{data.name}</div>
                    <div className={classNames(styles.owner)}>{data.owner}</div>

                    {/*{data.session && (*/}
                    {/*    <>*/}
                    {/*        <div className={styles.titleBlock}>*/}
                    {/*            <strong>Конкурс: </strong>*/}
                    {/*            <span>{data.session.contest?.name}</span>*/}
                    {/*        </div>*/}
                    {/*        <div className={styles.titleBlock}>*/}
                    {/*            <strong>Отбор: </strong>*/}
                    {/*            <span>{data.session?.name}</span>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*)}*/}

                    { (data && data.nomination) && (
                        <div className={styles.nomination}>
                            <div className={`${colorStyle && styles[colorStyle]}`}>
                                <strong>Номинация:</strong> {data.nomination.name}
                            </div>
                        </div>
                    ) }
                    { (data && !!data.score) && (
                        <div className={styles.score}>
                            <div className={`${colorStyle && styles[colorStyle]}`}>
                                <strong>Экспертная оценка:</strong> {data.score}
                            </div>
                        </div>
                    ) }

                    {!!data.recommendedCostReduction && (
                        <div className={styles.item}>
                            <div className={`${styles.text} ${styles.recommendedCostReduction} ${styles.recommendedCostReductionSmall}`}>
                                Поддержан с учетом снижения стоимости проекта на <span>{formatCurrency(data.recommendedCostReduction || '' )}</span> рублей
                            </div>
                        </div>
                    )}

                    {/*<div className={styles.row}>*/}
                    {/*<div className={styles.rowItem}>*/}
                    {/*    <div className={styles.title}>Бюджет</div>*/}
                    {/*    <div className={`${styles.text} ${styles.budget}`}>*/}
                    {/*        { formatCurrency(data.budget || '') }*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className={styles.rowItem}>*/}
                    {/*    <div className={styles.title}>Плановый КПЭ</div>*/}
                    {/*    <div className={`${styles.text} ${styles.budget}`}>*/}
                    {/*        { formatCurrency(data.kpi || '') }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*</div>*/}


                    {!!data.supportAmount && !systemSetting && (
                        <div className={styles.row}>
                            <div className={styles.rowItem}>
                                <div className={styles.title}>Сумма оказанной поддержки</div>
                                <div className={`${styles.text} ${styles.budget}`}>
                                    { formatCurrency(data.supportAmount || '') }
                                    {data.budget && (
                                        <span> ({formatCurrencyPercent(data.supportAmount, data.budget, 0)})</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {/*{!!data.actualKpi && !!data.kpi && !systemSetting && (*/}
                    {/*    <div className={styles.rowItem}>*/}
                    {/*        <div className={styles.title}>Фактический КПЭ</div>*/}
                    {/*        <div className={`${styles.text} ${styles.budget}`}>*/}
                    {/*            { formatCurrency(data.actualKpi || '') }*/}
                    {/*            {data.kpi && (*/}
                    {/*                <span> ({formatCurrencyPercent(data.actualKpi, data.kpi, 0)})</span>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}


                    {!!data.cofinancing && (
                        <div className={styles.item}>
                            <div
                                dangerouslySetInnerHTML={createMarkup(data.cofinancing)}
                                className={`${styles.text} ${styles.recommendedCostReduction} ${styles.recommendedCostReductionSmall}`}/>
                        </div>
                    )}

                    {!!data.ownProjectSite && (
                        <div className={styles.item}>
                            <div className={styles.title}>Собственный сайт проекта</div>
                            <div className={styles.text} dangerouslySetInnerHTML={createMarkup(data.ownProjectSite)}/>
                        </div>
                    )}

                    { data.linksAwards && (
                        <div className={styles.item}>
                            <div className={styles.title}>Ссылка на проект</div>
                            <div className={styles.text}>
                                <MultilineText text={data.linksAwards || ''} />
                            </div>
                        </div>
                    ) }

                    { data.nameNominees && (
                        <div className={styles.item}>
                            <div className={styles.title}>Команда проекта</div>
                            <div className={styles.text}>
                                <MultilineText text={data.nameNominees || ''} />
                            </div>
                        </div>
                    ) }

                    { (data && data.internetResources) && (
                        <div className={styles.item}>
                            <div className={styles.title}>Интернет-ресурсы</div>
                            <div className={`${styles.text} ${styles.socials}`}>
                                <InternetResources data={data.internetResources} />
                            </div>
                        </div>
                    ) }

                    { (requestFiles && requestFiles.length > 0) && (
                        // @ts-ignore
                        <PdfItem files={requestFiles} data={data} label={'requestFiles'} openPDFModal={openPDFModal} text={'Заявка'} />
                    ) }

                    { data.expertise && (
                        <div className={styles.item}>
                            <div className={styles.title}>Ссылки</div>
                            <div className={styles.text}>
                                <MultilineText text={data.expertise || ''} />
                            </div>
                        </div>
                    ) }

                    { (examinationFiles && examinationFiles.length > 0) && (
                        // @ts-ignore
                        <PdfItem files={examinationFiles} data={data} label={'examinationFiles'} openPDFModal={openPDFModal} text={'Экспертиза'} />
                    ) }

                    { data.teamExperience && (
                        <div className={styles.item}>
                            <div className={styles.title}>Команда проекта</div>
                            <div className={styles.text}>
                                <MultilineText text={data.teamExperience || ''} showShort={true} />
                            </div>
                        </div>
                    ) }

                    { (files && files.length > 0) && (
                        // @ts-ignore
                        <PdfItem files={files} data={data} label={'otherFiles'} openPDFModal={openPDFModal} text={'Дополнения'} />
                    ) }

                    {!!data.achievements && (
                        <div className={styles.item}>
                            <div
                                className={`${styles.text} ${styles.recommendedCostReduction}`}
                                dangerouslySetInnerHTML={createMarkup(data.achievements)}/>
                        </div>
                    )}

                </div>
                <div className={`${styles.contentColumn} ${styles.leftSection}`}>
                    <Tabs defaultActiveKey='1' onChange={handleChangeTabs}>
                        { (data && data.image) && (
                            <Tabs.TabPane tab="Карточка проекта" key="1">
                                { (image) && (
                                    <Image className={styles.image}
                                        src={image}
                                        alt="Изображение"/>
                                ) }
                            </Tabs.TabPane>
                        ) }

                        { (data && data.presentation && data.presentation.url) && (
                            <Tabs.TabPane tab="Видеопрезентация" key="2">
                                <div className={styles.item}>
                                    <div className={styles.text}>
                                        <iframe
                                            className={styles.video}
                                            title="presentation"
                                            width="510"
                                            height="264"
                                            src={data.presentation.url}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        ) }

                        { (data && data.presentationFile && data.presentationFile.id) && (
                            <Tabs.TabPane tab="Презентация" key="3">
                                { (presentationFile !== null) && (
                                    <div
                                        className={styles.document}
                                        onClick={openPDFModal(presentationFile, 'Презентация')}>
                                        <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                                        <span>Презентация</span>
                                        <Tooltip placement="top" title="Просмотр документа">
                                            <img
                                                src={viewDocumentImg}
                                                alt="document"
                                                className={styles.documentImg} />
                                        </Tooltip>
                                    </div>
                                ) }
                            </Tabs.TabPane>
                        ) }
                    </Tabs>

                    { data.description && (
                        <div className={styles.item}>
                            <div className={styles.title}>Описание проекта</div>
                            <div className={styles.text}>
                                <MultilineText text={data.description || ''} showShort={true} />
                            </div>
                        </div>
                    ) }

                    { (data && data.formatDescription) && (
                        <div className={styles.item}>
                            <div className={styles.title}>Формат контента</div>
                            <div className={styles.text}>
                                <MultilineText text={data.formatDescription || ''} />
                            </div>
                        </div>
                    ) }

                </div>


                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            </ErrorBoundary>
        </div>
    );
};
