import React, { SyntheticEvent, useState } from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    ExportOutlined,
    LoadingOutlined,
} from '@ant-design/icons/lib';
import qs from 'query-string';


import FilterSVG from 'src/assets/svg/filter.svg'
import PDFSVG from 'src/assets/svg/pdf.svg'

import { TPDFModal } from 'src/types/modals';

import { LogoutAction } from 'src/store/auth/actions';
import { LogsActionsStartAction } from 'src/store/logs/actions';

import {selectIsModerator, selectUserData} from 'src/store/auth/selectors';
import {
    ToggleProfileAction,
    ToggleSidebarAction,
    ToggleSidebarMenuAction,
} from 'src/store/general/actions';
import { selectCurrentPresentation, selectCurrentSessionStatus } from 'src/store/current-session/selectors';
import { selectIsProfileActive, selectIsSidebarActive } from 'src/store/general/selectors';

import { mainModuleRoutes } from 'src/routing/projects-module';
import { PDFModal } from 'src/components/modals/pdf-modal';
import { ProfileSidebar } from 'src/components/profile-sidebar';

import styles from './styles.module.scss';
import classNames from "classnames";
import { MenuFoldIcon } from 'src/shared/ui/icons';
import keycloak from "src/Keycloak";

export const MainHeader = () => {
    const [modalData, setModalData] = useState<TPDFModal>(null);
    const put = useDispatch();

    const presentation = useSelector(selectCurrentPresentation);
    const { isLoading } = useSelector(selectCurrentSessionStatus);

    const isSidebarActive = useSelector(selectIsSidebarActive);
    const isProfileActive = useSelector(selectIsProfileActive);
    // const isVoteFiltersActive = useSelector(selectIsVoteFiltersActive);
    const userData = useSelector(selectUserData);

    const isModerator = useSelector(selectIsModerator);

    const user = (userData && userData.user) ? userData.user : {};
    const { pathname, search } = useLocation();
    const { tab } = qs.parse(search);
    const isList = pathname === mainModuleRoutes.list.path && tab !== '2';
    // const isVote = pathname.split('/')[1] === 'vote' && tab !== '2';
    const isReportingList = pathname === mainModuleRoutes['reporting-list'].path;
    const isAllProjectsListList = pathname.split('/')[1] === 'all-projects-list';
    const isAllDocumentsListList = pathname.split('/')[1] === 'all-documents-list';
    const isRealizationLinks = pathname.split('/')[1] === 'realization' && tab === '3';

    const userName = () => {
        if (user && user.personalData?.firstName && user.personalData?.lastName) {
            return user.personalData?.firstName.slice(0, 1).toUpperCase() + user.personalData?.lastName.slice(0, 1).toUpperCase()
        }
        return (user && user?.username) ? user?.username.slice(0, 2).toUpperCase() : '';
    };

    const openPDFModal = (_e: SyntheticEvent) => {
        const fileURL = window.URL.createObjectURL(presentation);

        setModalData({
            fileURL: fileURL,
            name: 'Отчетная презентация',
        });
        put(LogsActionsStartAction('VIEW_REPORTING_PRESENTATION'))
    };

    const closePDFModal = () => {
        setModalData(null);
    };

    const toggleReportingSidebar = () => {
        put(ToggleSidebarAction(!isSidebarActive));
    };
    const toggleProfile = () => {
        put(ToggleProfileAction(!isProfileActive));
    };
    // const toggleVoteSidebar = () => {
    //     put(ToggleVoteFiltersAction(!isVoteFiltersActive));
    // };
    const handleLogOut = () => {
        put(LogoutAction());
        keycloak.logout();
    };


    const presentationElem = () => {
        if (isLoading) {
            return (
                <div className={styles.pdf}>
                    <LoadingOutlined />
                </div>
            )
        }
        if (presentation) {
            return (
                <div
                    className={styles.pdf}
                    onClick={openPDFModal}
                >
                    <img src={PDFSVG} alt="pdf"/>
                    <span>
                        Презентация проектов
                    </span>
                </div>
            )
        }
    }
    const onClick = () => {
        put(ToggleSidebarMenuAction())
    }
    return (
        <header
            className={styles.header}
            data-name='header'
        >
            <div className={styles.leftBar}>
                {!isModerator && (
                    <NavLink onClick={onClick} className={styles.link} to={'/vote?tab=1'}>
                        <>Голосование</>
                    </NavLink>
                )}
                {isModerator && (
                    <div className={classNames(styles.headerMain__toggle, 'menuInfo')} onClick={onClick}>
                        <MenuFoldIcon />
                    </div>
                )}

            </div>
            <div className={styles.userInfo}>
                { isList && (
                    <>
                        {presentationElem()}
                    </>
                ) }

                {/*{ isVote && (*/}
                {/*    <div className={styles.filters} onClick={toggleVoteSidebar}>*/}
                {/*        <img src={FilterSVG} alt="filter" />*/}
                {/*    </div>*/}
                {/*) }*/}

                { (
                    isReportingList ||
                    isAllProjectsListList ||
                    isAllDocumentsListList ||
                    isRealizationLinks
                ) && (
                    <div className={styles.filters} onClick={toggleReportingSidebar}>
                        <img src={FilterSVG} alt="filter" />
                    </div>
                ) }

                <div className={styles.profile}>
                    <div className={styles.avatar} onClick={toggleProfile}>
                        {typeof user.personalData?.image === 'string' ? (
                            <img src={user.personalData?.image} alt={userName()}/>
                        ) : (
                            <span className={styles.name}>{userName()}</span>
                        )}
                    </div>
                </div>

                <div className={styles.logout} onClick={handleLogOut}>
                    {/*<img src={logoutImg} alt="Logout" />*/}
                    <ExportOutlined className={styles.logoutIcon} />
                </div>

            </div>

            <PDFModal
                fileURL={modalData?.fileURL as string}
                name={modalData?.name}
                isOpened={Boolean(modalData)}
                onClose={closePDFModal}
            />

            <ProfileSidebar />
        </header>
    );
}
