import { InferValueTypes } from 'src/types/common';
import * as actions from './actions';
import {UsersActionTypes} from "src/store/users/action-types";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TUsersState = {
    user: any;
    users: any;
    actions: any;
    statistic: any;
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
};

export const initialState: TUsersState = {
    user: null,
    users: null,
    actions: null,
    statistic: null,
    isLoading: false,
    isLoaded: false,
    error: null,
};

export default function reducer(
    state: TUsersState = initialState,
    action: ActionTypes,
): TUsersState {
    switch (action.type) {
        case UsersActionTypes.CREATE_USER_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case UsersActionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
            };
        case UsersActionTypes.CREATE_USER_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.GET_USER_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case UsersActionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
                isLoading: false,
                isLoaded: true
            };
        case UsersActionTypes.GET_USER_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.GET_USERS_START:
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        case UsersActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                isLoading: false,
                isLoaded: true
            };
        case UsersActionTypes.GET_USERS_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.PUT_USER_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case UsersActionTypes.PUT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        case UsersActionTypes.PUT_USER_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.DELETE_USER_START:
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        case UsersActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        case UsersActionTypes.DELETE_USER_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.GET_USER_ACTIONS_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case UsersActionTypes.GET_USER_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                actions: action.data
            }
        case UsersActionTypes.GET_USER_ACTIONS_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        case UsersActionTypes.GET_USER_STATISTIC_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case UsersActionTypes.GET_USER_STATISTIC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                statistic: action.statistic
            }
        case UsersActionTypes.GET_USER_STATISTIC_ERROR:
            return {
                ...state,
                isLoaded: false,
                error: action.error
            };
        default:
            return state;
    }
}
