import React, {FC, useEffect, useState} from 'react';
import styles from './styles.module.scss'
import {Collapse, Spin, Tabs} from "antd";
import SliderComponent from "src/components/slider-component";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {TSlide} from "src/components/slider-component/SliderComponent";
import SlideImg from "src/components/slider-component/slides/slide-img";
import SlideVideo from "src/components/slider-component/slides/slide-video";
import SlideAudio from "src/components/slider-component/slides/slide-audio";
import createMarkup from "src/utils/createMarkup";
import {TPDFModal} from "src/types/modals";
import {PDFModal} from "src/components/modals/pdf-modal";
import {GetFileRealizationStartAction} from "src/store/files/actions";
import {selectFile, selectFileStatus} from "src/store/files/selectiors";
import {ErrorBoundary} from "src/components/error-boundary";

const { Panel } = Collapse;


export type TTypeContent = 'image' | 'video' | 'audio' | 'text';
export type ItemContentProps = {
    type: TTypeContent,
    data: any[] | undefined
}
type TState = {
    type: TTypeContent | null,
    data: TSlide | null
    description?: string
    name?: string | null
    idContent?: string | number
    image?: string
}
const ItemContent:FC<ItemContentProps> = ({type, data}) => {
    const put = useDispatch();
    const { pathname } = useLocation();
    const id = pathname.split('/')[2];

    const [idFile, setIdFile] = useState<null | number>(null)
    const file = useSelector(selectFile(`${idFile}`))
    const { isLoading } = useSelector(selectFileStatus(`${idFile}`));
    const [state, setState] = useState<TState>({
        type: null,
        data: null,
        description: undefined,
        name: null,
        idContent: undefined,
        image: undefined
    });
    const [modalData, setModalData] = useState<TPDFModal>(null);

    const openPDFModal = (file: any, name: string) => {
        try {
            let fileURL = window.URL.createObjectURL(file);
            setModalData({ fileURL: fileURL, id, name, });
        } catch (error) {}
    };
    const closePDFModal = () => {
        setModalData(null);
        setIdFile(null)
    };

    useEffect(() => {
        if (file && idFile) {
            openPDFModal(file, state.data ? state.data?.name : '')
        }
    }, [idFile, file]);


    const handlerChangeSlide = (
        description?: string, idContent?: string | number, name?: string, image?: string
    ) => (type: TTypeContent, data: TSlide, _e?: any) => {
        setState({...state, type, data, description, idContent, name, image})
        if ((type === 'text' || type === 'image') && _e) {
            put(GetFileRealizationStartAction(`${data.file?.id}`, data.file?.type))
            if (data && data.file && type === 'text') {
                setIdFile(data.file?.id)
            }
        }
    }

    return (
        <ErrorBoundary>
            <div className={styles.tab}>
                <div className={styles.tabInfo}>
                    <h3>{state.name}</h3>
                </div>
                <div className={styles.tabCard}>
                    {state.type === 'text' ? (
                        <>
                            <Spin spinning={isLoading}>
                                <Collapse defaultActiveKey={['0']}>
                                    <Panel header="Описание" key="0">
                                        {state.description && state.description && (
                                            <div className={styles.description} dangerouslySetInnerHTML={createMarkup(state.description)}/>
                                        )}
                                    </Panel>
                                    {state.data && state.data.name && state.data.description ? (
                                        <Panel header={`${state.data.name}`} key="1">
                                            <div className={styles.sliderInfo}>
                                                <p>{state.data.description}</p>
                                            </div>
                                        </Panel>
                                    ) : (<strong>{state?.data?.name}</strong>)}
                                </Collapse>
                            </Spin>
                        </>
                    ) : (
                        <>
                            <div className={styles.tabCardLeft}>
                                {state.type === 'image' && state.data && (
                                    <SlideImg file={state.data.file} url={state.data.url} name={state.data.name}/>
                                )}
                                {state.type === 'video' && state.data && (
                                    <SlideVideo file={state.data.file} url={state.data.url}/>
                                )}
                                {state.type === 'audio' && state.data && (
                                    <SlideAudio
                                        file={state.data.file}
                                        url={state.data.url}
                                        img={state.image}
                                        name={state.data.name}
                                    />
                                )}
                            </div>
                            <div className={styles.tabCardRight}>
                                <Collapse defaultActiveKey={['1']}>
                                    <Panel header="Описание" key="1">
                                        {state.description && state.description && (
                                            <div
                                                className={styles.description}
                                                dangerouslySetInnerHTML={createMarkup(state.description)}
                                            />
                                        )}
                                    </Panel>
                                    {state.data && state.data.name && state.data.description ? (
                                        <Panel header={`${state.data.name}`} key="2">
                                            <div className={styles.sliderInfo}>
                                                <p>{state.data.description}</p>
                                            </div>
                                        </Panel>
                                    ) : (<strong>{state?.data?.name}</strong>)}
                                </Collapse>
                            </div>
                        </>
                    )}

                </div>
                <Tabs defaultActiveKey='1'>
                    {data && data.map((el, id) => {
                        return (
                            <Tabs.TabPane tab={el.name} key={id + 1} >
                                {!!el.items.length && (
                                    <SliderComponent
                                        type={type}
                                        data={el.items}
                                        image={el.image}
                                        handler={handlerChangeSlide(el.description, el.id, el.name, el.image)}
                                    />
                                )}

                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>
                <PDFModal
                    fileURL={modalData?.fileURL as string}
                    name={modalData?.name}
                    isOpened={Boolean(modalData)}
                    onClose={closePDFModal}
                />
            </div>
        </ErrorBoundary>

    )
};
export default ItemContent;