import React, {FC, useEffect} from 'react';
import styles from "./styles.module.scss";
import {Modal} from "react-bootstrap";
import { ErrorBoundary } from 'src/components/error-boundary';
import {useFormik} from "formik";
import {createNomination, updateNomination} from '../api'
import {NewNomination, Nomination} from "src/features/settings/nominations-directory/types";
import {useDispatch} from "react-redux";
import {GetNominationStartAction} from "src/features/settings/nominations-directory/model/actions";
import ModalInput from "src/components/form-components/modal-input";
import * as Yup from "yup";
import {Button, Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/lib/checkbox";

type Props = {
    isOpened: boolean
    isEdit: boolean
    onClose: () => void
    data: Nomination | null
}
type TFormValues = Omit<NewNomination, 'order'> & {order: string}
const initialValues: TFormValues = {
    hidden: false,
    name: '',
    order: ''
}

const ModalAddNomination: FC<Props> = ({isOpened, onClose, isEdit, data}) => {
    const put = useDispatch();
    const form = useFormik<TFormValues>({
        initialValues: isEdit && data ? {
            name: data.name,
            hidden: data.hidden,
            order: String(data.order)
        } : initialValues,
        onSubmit: onSubmit,
        validateOnChange: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Поле обязательное'),
            order: Yup.mixed().required('Поле обязательное').test('order', 'Поле не валидно, нужно указать число', function (value) {
                return !!Number(value)
            }),
        })
    })
    useEffect(() => {
        form.resetForm();
        if (data) {
            form.setFieldValue('order', String(data.order));
            form.setFieldValue('name', data.name);
            form.setFieldValue('hidden', data.hidden);
        }
    }, [data])
    const onCheckboxChange = (e: CheckboxChangeEvent) => {
        form.setFieldValue('hidden', e.target.checked)
    }


    async function onSubmit({order,...value}: TFormValues) {
        if (form.isValid) {
            if (isEdit && data) {
                updateNomination({...value, order: Number(order)}, data.id).then(() => {
                    onClose();
                    put(GetNominationStartAction())
                })
            } else {
                createNomination({...value, order: Number(order)}).then(() => {
                    onClose();
                    put(GetNominationStartAction())
                })
            }
        } else {
            await form.validateForm();
        }
    }
    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            backdrop="static"
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <ErrorBoundary submitF={onClose}>
                <form
                    name="nominationForm"
                    onSubmit={form.handleSubmit}
                >
                    <Modal.Header className={styles.modalHeader}>
                        <h3 className={styles.header}>
                            { isEdit ? 'Редактирование номинации' : 'Добавление номинации' }
                        </h3>
                    </Modal.Header>
                    <Modal.Body className={styles.body}>
                        <ModalInput
                            form={form}
                            label={"name"}
                            text={'Название номинации'}
                            showErrorText={true}
                        />
                        <ModalInput
                            form={form}
                            label={"order"}
                            text={'Порядок отображение номинации на ui'}
                            showErrorText={true}
                        />
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={form.values.hidden}
                                onChange={onCheckboxChange}
                            />
                            <span>{!form.values.hidden ? 'Номинация не скрыта' : 'Номинация скрыта'}</span>
                        </div>
                        <div className={styles.btn}>
                            <Button onClick={onClose}>Закрыть</Button>
                            <Button type={'primary'} htmlType={'submit'}>Сохранить</Button>
                        </div>

                    </Modal.Body>
                </form>
            </ErrorBoundary>
        </Modal>
    )
};

export default ModalAddNomination;