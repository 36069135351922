import React, { useEffect } from 'react';

import { NotificationModel } from '..';
import {subscribe} from "src/shared/lib/notification/events";
import {notification} from "antd";
 
const Notification = () => {
    const openNotification = ({ type, info, placement }: NotificationModel.NotificationType) => {
        const message = typeof info.message === 'string' ? info.message : 'Неизвестная ошибка';
        notification[type]({
            message: message,
            description: info?.description && (
                <div dangerouslySetInnerHTML={InnerHTMLFunction(info.description)} />
            ),
            placement: placement || 'topRight',
        });
    };
    useEffect(() => {
        const unsubscribe = subscribe(openNotification);
        return () => unsubscribe();
	}, []); //eslint-disable-line

    return null;
};
function InnerHTMLFunction(text: string) {
    if (!text) return { __html: text };
    const regexp = /<[^>]*script/g;
    const newText = text.replace(regexp, '');
    return { __html: newText };
}
export default Notification;
