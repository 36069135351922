import {put} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';

import {
    EditProjectErrorAction,
    EditProjectStartAction,
    EditProjectSuccessAction,
    GetProjectsStartAction
} from 'src/store/projects/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* editProjectWorker({ form, project }: ReturnType<typeof EditProjectStartAction>) {
    try {
        const formData = new FormData();
        let projectData: any = {
            "budget": +form.budget,
            "nominationId": +form.nominationId,
            "description": form.description,
            "expertise": form.expertise,
            "formatDescription": form.formatDescription,
            "linksAwards": form.linksAwards,
            "nameNominees": form.nameNominees,
            "formatIds": form.formatIds,
            "kpi": +form.kpi,
            "name": form.name,
            "owner": form.owner,
            "presentationUrl": form.presentationUrl,
            "score": form.score,
            "sessionId": +form.sessionId,
            "teamExperience": form.teamExperience,
            "year": +form.year,
            "internetResourcesIds": form.internetResources.map((el: { id?: number }) => {
                if (typeof el === 'object') return el.id
                else return el
            }),
            "implEndDate": form.implEndDate,
            "implStartDate": form.implStartDate,
            "implTerms": form.implTerms,
            "requestNumber": form.requestNumber || null,
            "recommendedCostReduction": form.recommendedCostReduction,
            "achievements": form.achievements,
            "ownProjectSite": form.ownProjectSite,
            "cofinancing": form.cofinancing,
            "supportAmount": form.supportAmount,
            "actualKpi": form.actualKpi,
        };

        let existingOtherFilesIds: number[] = [];
        let existingPresentationFileIds: number[] = [];
        let existingRequestFilesIds: number[] = [];
        let existingExaminationFilesIds: number[] = [];


        if (form.existingOtherFiles) {
            existingOtherFilesIds = form.existingOtherFiles.map((item) => item.id)
        }
        if (form.existingPresentationFile) {
            existingPresentationFileIds = [form.existingPresentationFile.id]
        }
        if (form.existingRequestFiles) {
            existingRequestFilesIds = form.existingRequestFiles.map((item) => item.id)
        }
        if (form.existingExaminationFiles) {
            existingExaminationFilesIds = form.existingExaminationFiles.map((item) => item.id)
        }

        projectData.fileIds = [
            ...existingOtherFilesIds,
            ...existingPresentationFileIds,
            ...existingRequestFilesIds,
            ...existingExaminationFilesIds
        ];

        if (form.existingImage) {
            projectData.imageId = form.existingImage.id;
        }

        formData.append('project', JSON.stringify(projectData));

        formData.append(
            'image',
            form.image.length > 0
                ? form.image[0].originFileObj as Blob
                : new Blob(undefined)
        );

        form.otherFiles.forEach((file) => {
            formData.append('otherFiles', file.originFileObj as Blob);
        });
        form.presentationFile.forEach((file) => {
            formData.append('presentation', file.originFileObj as Blob);
        });
        form.requestFiles.forEach((file) => {
            formData.append('requestFiles', file.originFileObj as Blob);
        });
        form.examinationFiles.forEach((file) => {
            formData.append('examinationFiles', file.originFileObj as Blob);
        });

        if (form.otherFiles.length === 0) {
            formData.append('otherFiles', new Blob([]),);
        }
        if (form.presentationFile.length === 0) {
            formData.append('presentation', new Blob([]),);
        }
        if (form.examinationFiles.length === 0) {
            formData.append('examinationFiles', new Blob([]),);
        }
        if (form.requestFiles.length === 0) {
            formData.append('requestFiles', new Blob([]),);
        }

        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'PUT',
            url: `/projects/${project.id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        });
        yield put(EditProjectSuccessAction(responseData));
        yield put(GetProjectsStartAction());
        yield put(LogsActionsStartAction('EDIT_PROJECT'));
    } catch (error) {
        yield put(EditProjectErrorAction(error));
    }
}
