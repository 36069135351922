export function getColor(id: string): string {
    const color = getRandomColor();
    if (localStorage.getItem('color')) {
        const colorStore: Record<string, string> = JSON.parse(localStorage.getItem('color') as string);
        if (colorStore[id]) return colorStore[id];
        else {
            colorStore[id] = color;
            localStorage.setItem('color', JSON.stringify(colorStore))
        }
    } else {
        localStorage.setItem('color', JSON.stringify({[id]: color}))
        return color;
    }
    return color
}
function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color + '4d';
}