import React, { FC, useEffect, useState } from 'react';
import { Button, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Modal } from 'react-bootstrap';

import { TVoteDetails, TVotedItem } from 'src/types/vote';

import {VoteStartAction} from 'src/store/voting/actions';
import { LogsActionsStartAction } from 'src/store/logs/actions';
import { selectVoteOptionsData } from 'src/store/voting/selectors';

import styles from './styles.module.scss';

export type TVoteModalProps = {
    isOpened: boolean;
    onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    data?: {
        id: number;
        votingId: number;
        type: 'project' | 'document';
        name: string;
        vote?: TVotedItem | null;
    };
    isList?: boolean;
    handler?: any
};

export const VoteModal: FC<TVoteModalProps> = ({
    isOpened,
    onClose,
    data,
    isList,
    handler
}) => {
    const options = useSelector(selectVoteOptionsData);
    const [option, setOption] = useState<number | null>(null);
    const put = useDispatch();

    useEffect(() => {
        if (isOpened) put(LogsActionsStartAction('OPEN_VOTE_FORM'))
    }, [isOpened])

    const onChange = (event: RadioChangeEvent) => {
        setOption(event.target.value);
    };

    const onSubmit = () => {
        if (data && typeof option === 'number') {
            const voteDetails: TVoteDetails = {
                votingId: data.votingId,
                voteOptionId: option,
            }

            data.type === 'project'
                ? voteDetails.projectId = data.id
                : voteDetails.votingBlockId = data.id;

            put(VoteStartAction({
                voteDetails: voteDetails,
                id: data.vote ? data.vote.id : undefined,
                isList,
            }));
            put(LogsActionsStartAction('LEAVE_VOTE'))
        }
        if (handler !== undefined) handler()
        onClose();
    };

    // reset on close
    useEffect(() => {
        if (!data) {
            setOption(null);
        }

        if (data && data.vote && data.vote.voteOption && options.length > 0) {
            const oldChoice = options.find((item) => {
                return data && data.vote && item && (item.name === data.vote.voteOption.name);
            });

            if (oldChoice) {
                setOption(oldChoice.id);
            }
        }
    }, [data]);

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <Modal.Body className={styles.body}>
                <h3 className={styles.header}>
                    Голосование за { data ? data.name : '' }
                </h3>

                <div className={styles.message}>
                    <label>
                        Внимание! Вы можете изменить ваш ответ до окончания голосования
                    </label>
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={onChange}
                        value={option}
                    >
                        { options.map((item) => (
                            <Radio
                                className={styles.radioItem}
                                key={item.id}
                                value={item.id}
                            >
                                { item.name }
                            </Radio>
                        )) }
                    </Radio.Group>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button type="default" onClick={onClose}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={typeof option !== 'number'}
                    onClick={onSubmit}
                >Подтвердить</Button>
            </Modal.Footer>
        </Modal>
    );
};
