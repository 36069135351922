import React, {FC, useEffect, useMemo, useState,} from 'react';
import cn from 'classnames';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Table} from 'antd';
import {Key, TablePaginationConfig} from 'antd/lib/table/interface';


import {mainModuleRoutes, TMainModuleKeys} from 'src/routing/projects-module';
import {handleGoToVotingEditor} from 'src/utils/redirects';

import {
    ActivateVotingAction,
    GetAllDocumentsListStartAction,
    GetAllVotesListStartAction,
    GetVotingTypesTriggerAction,
    SetAllVotesListSortingAction,
    SetDocumentListFilter,
} from 'src/store/voting/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

import {
    selectAllDocumentsData,
    selectAllVotesData,
    selectAllVotesStatus,
    selectVotingTypesData,
    selectVotingTypesStatus,
} from 'src/store/voting/selectors';
import {selectIsModerator} from 'src/store/auth/selectors';

import {DateTimeCell} from 'src/components/data-table/date-teme-cell';
import {ActivateCell} from 'src/components/data-table/activate-cell';

import styles from './styles.module.scss';
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {ErrorBoundary} from "src/components/error-boundary";
import {Pagination} from "src/shared/lib/pagination";
import {ColumnsType} from "antd/es/table";
import {TVoteSummary} from "src/types/vote";

type TProps = {
    isArchive?: boolean;
};

export const AllVotesListPage: FC<TProps> = (props) => {
    const { pathname, search } = useLocation();
    const put = useDispatch();
    const votingTypes = useSelector(selectVotingTypesData);
    const votingTypesStatus = useSelector(selectVotingTypesStatus);
    const projectsData = useSelector(selectAllVotesData);
    const projectsStatus = useSelector(selectAllVotesStatus);
    const documentsData = useSelector(selectAllDocumentsData);
    // const documentsStatus = useSelector(selectAllDocumentsStatus);
    const isModerator = useSelector(selectIsModerator);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [tabs, setTabs] = useState<string>('1');
    // const handleChangeTabs = (e: string) => {
    //     setTabs(e)
    // };

    useEffect(() => {
        put(LogsActionsStartAction('OPEN_VOTING_MODULE'))
    }, [])

    const handleTableChange = (type: 'projects' | 'documents') => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPagination = {};
        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
                totalCount: pagination.total || 0,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }
        if (type === 'projects') {
            const { content, ...sorting } = projectsData;
            put(SetAllVotesListSortingAction({
                ...sorting,
                ...newPagination,
                ...newSorter,
            }));
            put(GetAllVotesListStartAction());
        } else {
            const { content, ...sorting } = documentsData;
            put(SetDocumentListFilter({
                ...sorting,
                ...newPagination,
                ...newSorter,
            }));
            put(GetAllDocumentsListStartAction());
        }
    };



    const onChangeActivated = (type: 'projects' | 'documents') => (
        activated: boolean,
        rowData: any,
    ) => {
        put(ActivateVotingAction({
            id: rowData.id,
            status: activated ? 'deactivate' : 'activate',
            type,
        }));
    };

    const columns = useMemo(() => {
        const result: ColumnsType<TVoteSummary> = [
            {
                title: 'Название проекта',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: true,
            },
            {
                title: 'Дата и время начала ознакомления',
                dataIndex: 'familiarizationDateTime',
                key: 'familiarizationDateTime',
                render: DateTimeCell,
                sorter: true,
                width: 200,
            },
            {
                title: 'Дата и время начала голосования',
                dataIndex: 'startDateTime',
                key: 'startDateTime',
                render: DateTimeCell,
                sorter: true,
                width: 200,
            },
            {
                title: 'Дата и время окончания голосования',
                dataIndex: 'endDateTime',
                key: 'endDateTime',
                render: DateTimeCell,
                sorter: true,
                width: 200,
            },
            {
                title: 'Схема голосования',
                dataIndex: 'voteOptionType',
                key: 'voteOptionType',
                render: (_: any, {voteOptionType}) => voteOptionType?.name,
                sorter: true,
                width: 200,
            }
        ];

        if (isModerator) {
            result.push({
                title: '',
                dataIndex: 'activated',
                key: 'activated',
                // @ts-ignore
                render: ActivateCell(onChangeActivated('projects')),
                width: 200,
            })
        }

        return result;
    }, [isModerator]);

    // const documentsColumns = useMemo(() => {
    //     const result = [
    //         {
    //             title: 'Название',
    //             dataIndex: 'name',
    //             key: 'name',
    //             width: 200,
    //             sorter: true,
    //         }, {
    //             title: 'Дата и время начала ознакомления',
    //             dataIndex: 'familiarizationDateTime',
    //             key: 'familiarizationDateTime',
    //             render: DateTimeCell,
    //             sorter: true,
    //             width: 200,
    //         }, {
    //             title: 'Дата и время начала голосования',
    //             dataIndex: 'startDateTime',
    //             key: 'startDateTime',
    //             render: DateTimeCell,
    //             sorter: true,
    //             width: 200,
    //         }, {
    //             title: 'Дата и время окончания голосования',
    //             dataIndex: 'endDateTime',
    //             key: 'endDateTime',
    //             render: DateTimeCell,
    //             sorter: true,
    //             width: 200,
    //         }
    //     ];
    //
    //     if (isModerator) { result.push({
    //         title: '',
    //         dataIndex: 'activated',
    //         key: 'activated',
    //         // @ts-ignore
    //         render: ActivateCell(onChangeActivated('documents')),
    //         width: 200,
    //     }) }
    //
    //     return result;
    // }, [isModerator]);

    const handleRowClick = (tableName: 'projects' | 'documents', record: any) => {
        let path: TMainModuleKeys = tableName === 'projects'
            ? props.isArchive ? 'archive-projects' : 'all-projects-list'
            : props.isArchive ? 'archive-documents' :  'all-documents-list';

        put(push(mainModuleRoutes[path].path
            .replace(':votingId', `${record.id}`))
        );
    };

    // initial load
    // load type ids first
    useEffect(() => {
        const { tab } = qs.parse(search);

        if (tab && typeof tab === 'string') {
            setTabs(tab);
        }
        if (votingTypes.length === 0 && !votingTypesStatus.isLoading) {
            put(GetVotingTypesTriggerAction());
        }
    }, []);

    // load tables data
    useEffect(() => {
        if (votingTypes.length > 0 && isInitialLoad) {
            put(GetAllVotesListStartAction());
            put(GetAllDocumentsListStartAction());
            setIsInitialLoad(false);
        }
    }, [put, votingTypes]);

    useEffect(() => {
        const tabIndex = tabs ? tabs : 1;
        put(push({
            pathname,
            search: `?tab=${tabIndex}`
        }));
    }, [tabs]);


    return (
        <div className={styles.container}>
            <div className={cn(styles.leftColumn)}>
                { isModerator && (
                    <div className={styles.header}>
                        <h3 className={styles.title}>Добавить голосование</h3>
                        <Button
                            type="default"
                            shape="circle"
                            className={styles.add}
                            onClick={handleGoToVotingEditor({
                                put: put,
                                type: 'projects',
                            })}
                        >+</Button>
                    </div>
                ) }

                <ErrorBoundary>
                    <Table
                        scroll={{ x: 920 }}
                        sticky
                        columns={columns}
                        dataSource={projectsData.content ? projectsData.content : []}
                        loading={votingTypesStatus.isLoading || projectsStatus.isLoading}
                        pagination={{
                            current: projectsData.page,
                            pageSize: projectsData.pageSize,
                            total: projectsData.totalCount,
                            showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: Pagination()
                        }}
                        onChange={handleTableChange('projects')}
                        rowKey="id"
                        onRow={(record, _rowIndex) => {
                            return {
                                onClick: (_event) => { handleRowClick('projects', record) },
                            };
                        }}
                    />
                </ErrorBoundary>

                {/*<Tabs defaultActiveKey={search ? `${search.slice(5)}` : '1'} activeKey={tabs} onChange={handleChangeTabs}>*/}
                {/*    <Tabs.TabPane tab="Заявки" key="1" >*/}
                {/*        <div className={styles.header}>*/}
                {/*            <h3 className={styles.title}>Голосование</h3>*/}
                {/*        </div>*/}
                {/*        <ErrorBoundary>*/}
                {/*            <Table*/}
                {/*                scroll={{ x: 920 }}*/}
                {/*                sticky*/}
                {/*                columns={columns}*/}
                {/*                dataSource={projectsData.content ? projectsData.content : []}*/}
                {/*                loading={votingTypesStatus.isLoading || projectsStatus.isLoading}*/}
                {/*                pagination={{*/}
                {/*                    current: projectsData.page,*/}
                {/*                    pageSize: projectsData.pageSize,*/}
                {/*                    total: projectsData.totalCount,*/}
                {/*                    showSizeChanger: true,*/}
                {/*                    position: ['bottomCenter'],*/}
                {/*                    pageSizeOptions: Pagination()*/}
                {/*                }}*/}
                {/*                onChange={handleTableChange('projects')}*/}
                {/*                rowKey="id"*/}
                {/*                onRow={(record, _rowIndex) => {*/}
                {/*                    return {*/}
                {/*                        onClick: (_event) => { handleRowClick('projects', record) },*/}
                {/*                    };*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </ErrorBoundary>*/}

                {/*    </Tabs.TabPane>*/}

                {/*    {isModerator && (*/}
                {/*        <Tabs.TabPane tab="Документы" key="2">*/}
                {/*            <div className={styles.header}>*/}
                {/*                <h3 className={styles.title}>Голосование</h3>*/}
                {/*            </div>*/}

                {/*            <ErrorBoundary>*/}
                {/*                <Table*/}
                {/*                    scroll={{ x: 920 }}*/}
                {/*                    sticky*/}
                {/*                    columns={documentsColumns}*/}
                {/*                    dataSource={documentsData.content ? documentsData.content : []}*/}
                {/*                    loading={votingTypesStatus.isLoading || documentsStatus.isLoading}*/}
                {/*                    pagination={{*/}
                {/*                        current: documentsData.page,*/}
                {/*                        pageSize: documentsData.pageSize,*/}
                {/*                        total: documentsData.totalCount,*/}
                {/*                        showSizeChanger: true,*/}
                {/*                        position: ['bottomCenter'],*/}
                {/*                        pageSizeOptions: Pagination()*/}
                {/*                    }}*/}
                {/*                    onChange={handleTableChange('documents')}*/}
                {/*                    onRow={(record, _rowIndex) => {*/}
                {/*                        return {*/}
                {/*                            onClick: (_event) => { handleRowClick('documents', record) },*/}
                {/*                        };*/}
                {/*                    }}*/}
                {/*                    rowKey="id"*/}
                {/*                />*/}
                {/*            </ErrorBoundary>*/}
                {/*        </Tabs.TabPane>*/}
                {/*    )}*/}

                {/*</Tabs>*/}
            </div>
        </div>
    );
};
