import {
    put,
    select,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetDocumentsByVotingIdStartAction,
    GetDocumentsByVotingIdSuccessAction,
    GetDocumentsByVotingIdErrorAction,
} from 'src/store/projects/actions';
import {selectAdminsDocumentsFilters} from 'src/store/projects/selectors';
import { TProjectsData } from 'src/types/projects';
import { selectIsModerator } from 'src/store/auth/selectors';

export function* getDocumentsByVotingIdWorker(action: ReturnType<typeof GetDocumentsByVotingIdStartAction>) {
    try {
        const isModerator = yield select(selectIsModerator);
        const { filterData, ...sorting } = yield select(selectAdminsDocumentsFilters);

        let { data: resData }: AxiosResponse<TProjectsData> = yield axios({
            method: 'POST',
            url: `${isModerator ? '' : '/user'}/voting/${action.votingId}/blocks/search`,
            headers: {},
            data: {
                filterData: {
                    nominationIds: filterData.nominationIds,
                    minScore: filterData.minScore,
                    maxScore: filterData.maxScore,
                    searchString: filterData.searchString,
                },
                ...sorting,
                page: sorting.page - 1,
            },
        });

        const { content, ...pagination } = resData;
        pagination.page = pagination.page + 1;

        yield put(GetDocumentsByVotingIdSuccessAction(content, pagination));
    } catch (error) {
        yield put(GetDocumentsByVotingIdErrorAction(error));
    }
}
