import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';
import { TPagination, TSorting } from 'src/types/pageable-request';

import { VotingActionTypes } from 'src/store/voting/action-types';
import {
    GetProjectsVotingListTriggerAction,
    GetProjectsVotingListStartAction,
    GetProjectsVotingListSuccessAction,
    GetProjectsVotingListErrorAction,
    GetDocumentsVotingListTriggerAction,
    GetDocumentsVotingListStartAction,
    GetDocumentsVotingListSuccessAction,
    GetDocumentsVotingListErrorAction, GetVotingOptionsStartAction,
} from 'src/store/voting/actions';
import { selectVotingTypesData } from 'src/store/voting/selectors';

// user's voting list
export function* getVotingListWorker(
    { type }: ReturnType<typeof GetProjectsVotingListTriggerAction> | ReturnType<typeof GetDocumentsVotingListTriggerAction>
) {
    try {
        const votingTypes = yield select(selectVotingTypesData);
        const id = type === VotingActionTypes.GET_PROJECTS_VOTING_LIST_TRIGGER ? votingTypes[0].id : votingTypes[1].id

        type === VotingActionTypes.GET_PROJECTS_VOTING_LIST_TRIGGER
            ? yield put(GetProjectsVotingListStartAction())
            : yield put(GetDocumentsVotingListStartAction());

        const { data }: AxiosResponse<{
            content: TVoteSummary[];
        } & TPagination & TSorting> = yield axios({
            method: 'POST',
            url: `/user/voting/${id}/search`,
            headers: {},
            data: {
                filterData: {
                    nominationIds: [],
                    maxScore: 0,
                    minScore: 0,
                    searchString: "",
                    year: 0
                },
                page: 0,
                pageSize: 20,
                totalCount: 0,
                sort: {
                    direction: "ASC",
                    field: "id",
                },
            },
        });

        type === VotingActionTypes.GET_PROJECTS_VOTING_LIST_TRIGGER
            ? yield put(GetProjectsVotingListSuccessAction(data.content))
            : yield put(GetDocumentsVotingListSuccessAction(data.content));
        yield put(GetVotingOptionsStartAction(data.content[0].voteOptionType.id))
    } catch (error) {
        type === VotingActionTypes.GET_PROJECTS_VOTING_LIST_TRIGGER
            ? yield put(GetProjectsVotingListErrorAction(error))
            : yield put(GetDocumentsVotingListErrorAction(error));
    }
}
