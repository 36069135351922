import React, {
    FC,
    HTMLAttributes,
    ReactNode,
} from 'react';

import { history } from 'src/index';
import { LeftArrowIcon } from 'src/assets/svg/left-arrow';

import styles from './styles.module.scss';

type TProps = {
    text: ReactNode | string;
    parentPath?: string;
} & HTMLAttributes<HTMLDivElement>;

export const ToParentPage: FC<TProps> = ({
    text,
    parentPath,
    ...divProps
}) => {
    const handleClick = () => {
        if (history.location.search && parentPath) {
            history.push(parentPath);
            return;
        }
        if (history?.length) history.goBack()
    };

    return (
        <header className={styles.header} { ...divProps }>
            <h3 className={styles.title} onClick={handleClick}>
                <div className={styles.iconContainer}>
                    <LeftArrowIcon
                        className={styles.icon}
                    />
                </div>
                {text}
            </h3>
        </header>
    );
};
