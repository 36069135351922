import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {mainModuleRoutes} from "src/routing/projects-module";
import {ToParentPage} from "src/components/to-parent-page";

import {useDispatch, useSelector} from "react-redux";
import {selectUser, selectUserActions, selectUsersStatus} from "src/store/users/selectors";
import {
    DeleteUserStartAction,
    GetUserActionsStartAction,
    GetUserActionsSuccessAction,
    GetUserByIdStartAction,
    GetUserByIdSuccessAction,
} from "src/store/users/actions";

import styles from "src/features/user-details/styles.module.scss";
import {Button, Table, Tabs} from "antd";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import UserModal from "src/components/modals/user-modal";
import {ConfirmModal} from "src/components/modals/confirm-modal";
import {NameCell} from "src/components/data-table/name-cell";
import {Key, TablePaginationConfig} from "antd/lib/table/interface";

import {DateCell} from "src/components/data-table/date-cell";
import {ErrorBoundary} from "src/components/error-boundary";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {ActionCell} from "src/components/data-table/action-cell";
import {Pagination} from "src/shared/lib/pagination";


export default function UserDetailsPage() {
    const put = useDispatch();
    const { pathname } = useLocation();
    const userId = pathname.split('/')[2];
    const user = useSelector(selectUser);
    const userActions = useSelector(selectUserActions);
    const {isLoading} = useSelector(selectUsersStatus)

    const [modal, setModal] = useState({
        isOpened: false,
        type: 'edit-one',
        data: '',
    })

    const [modalConfirm, setModalConfirm] = useState<any>({
        isOpened: false,
        id: null,
        username: ''
    })

    const [tab, setTab] = useState('1');


    useEffect(() => {
        if (!user) put(GetUserByIdStartAction(userId))
        if (!userActions) put(GetUserActionsStartAction(userId))
        return () => {
            put(GetUserByIdSuccessAction(null))
            put(GetUserActionsSuccessAction(null))
        }
    }, [])

    const userInfo = (type: string) => {
        switch (type){
            case 'ACTIVE': return 'Активирован';
            case 'BLOCKED': return 'Заблокирован';
            case 'ROLE_USER': return 'Пользовтаель';
            case 'ROLE_MODERATOR': return 'Модератор';
            case 'ROLE_ADMIN': return 'Администратор';
            default: return '';
        }
    }

    //handlers
    const toggleModal = (_type: string) => {
        setModal({...modal, isOpened: !modal.isOpened, type: 'edit-one'})
    };
    const toggleModalConfirm = () => {
        setModalConfirm({...modalConfirm, isOpened: false, id: null})
    }
    const deleteUser = () => {
        setModalConfirm({...modalConfirm, isOpened: false, id: null, username: ''})
        put(DeleteUserStartAction(modalConfirm.id))
    }
    const handleChangeTabs = (_e: any) => {
        setTab(`${_e}`)
        if (_e === '1') put(LogsActionsStartAction('OPEN_USER_INFO_TAB', userId, user.username))
        else put(LogsActionsStartAction('OPEN_USER_ACTIONS_TAB', userId, user.username))
    };
    // TODO: refactor this
    const handleTableChange = (_tableName: string) => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPageSize = {
            pageSize: 20
        }
        let newPagination = {
            page: 0
        };
        let newSorter = {
            sort: {
                direction: "DESC",
                field: "dateTime"
            }
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1
            }
            newPageSize = {
                pageSize: pagination.pageSize || 20
            }
        }
        if (sorter && sorter.order) {
            let direction = 'ASC';
            if (sorter.order === 'descend') {
                direction = 'DESC'
            }
            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }
        put(GetUserActionsStartAction(`${userId}`,{newPagination, newSorter, newPageSize}))
    };


    //render
    let columns = [
        {
            title: 'Дата последнего входа  на портал',
            dataIndex: 'dateTime',
            key: 'dateTime',
            sorter: true,
            width: 220,
            className: styles.nameCell,
            render: DateCell,
        },
        {
            title: 'IP пользователя',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            render: NameCell,
            width: 200,
        },
        {
            title: 'Действие',
            dataIndex: 'actionType',
            key: 'actionType',
            sorter: true,
            render: ActionCell,
            width: 180
        }
    ];
    const renderUser = () => {
        const {roles, status, username, personalData} = user;
        return (
            <div className={styles.container}>
                <div className={styles.contentColumn}>
                    <div className={styles.useravatar}>
                        <label htmlFor="browse_img" className={styles.avatar}>
                            <img src={!user.personalData.image ? '/images/avatar.png' : user.personalData.image} alt="avatar"/>
                            <input
                                className={styles.addImage__input}
                                id="browse_img"
                                type="file"/>
                        </label>
                        <div className={styles.cell}>
                            <div onClick={() => {
                                setModal({...modal, type: 'edit-one', isOpened: true, data: user})
                            }}>
                                <Button
                                    type="default"
                                    size="small"
                                    className={styles.btn}
                                    icon={<EditOutlined />}
                                />
                                <span>Изменить профиль</span>
                            </div>
                            <div onClick={() => {
                                setModalConfirm({...modalConfirm, isOpened: true, id: user.id, username})
                            }}>
                                <Button
                                    type="default"
                                    size="small"
                                    className={styles.btn}
                                    icon={<CloseOutlined />}
                                />
                                <span>Удалить профиль</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.contentColumn} ${styles.list}`}>
                    <h4>Общее</h4>
                    <hr/>
                    <ul>
                        {personalData.lastName ? (<li><span>Фамилия</span> {personalData.lastName}</li>) : null}
                        {personalData.firstName ? (<li><span>Имя</span> {personalData.firstName}</li>) : null}
                        {personalData.middleName ? (<li><span>Отчество</span> {personalData.middleName}</li>) : null}
                        {personalData.email ? (<li><span>Email</span> {personalData.email}</li>) : null}
                    </ul>
                    <h4>Карьера</h4>
                    <hr/>
                    <ul>
                        {personalData.position ? (<li><span>Должность</span> {personalData.position}</li>) : null}
                        {personalData.organization ? (<li><span>Организация</span> {personalData.organization}</li>) : null}
                    </ul>
                    <h4>Системные настройки</h4>
                    <hr/>
                    <ul>
                        <li><span>Роль в системе</span> {userInfo(roles[0])}</li>
                        <li><span>Логин</span> {username}</li>
                        <li><span>Статус</span> {userInfo(status)}</li>
                    </ul>
                </div>
            </div>
        )
    }
    const renderActionsTable = () => (
        <div className={styles.userAction}>
            <Table
                scroll={{ x: 600 }}
                sticky
                columns={columns}
                dataSource={userActions.content}
                loading={isLoading}
                pagination={{
                    current: userActions.page + 1,
                    pageSize: userActions.pageSize,
                    total: userActions.totalCount,
                    position: ['bottomCenter'],
                    pageSizeOptions: Pagination()
                }}
                onChange={handleTableChange('users')}
                rowKey="dateTime"
            />
        </div>
    )


    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={mainModuleRoutes.users.path} />
            </div>
            <ErrorBoundary>
                {/*// @ts-ignore*/}
                <Tabs defaultActiveKey="1" activeKey={tab} onChange={handleChangeTabs} style={{'width': '100%'}}>
                    <Tabs.TabPane tab="Информация" key="1">
                        {user && renderUser()}
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Действия пользователя" key="2">
                        {userActions && renderActionsTable()}
                    </Tabs.TabPane>
                </Tabs>
                {/*// @ts-ignore*/}
                {modal.isOpened && <UserModal {...modal} toggleModal={toggleModal}/>}
                {modalConfirm.isOpened && (
                    <ConfirmModal
                        isOpened={modalConfirm.isOpened}
                        onClose={toggleModalConfirm}
                        onSubmit={deleteUser}
                        message={`Вы действительно хотите удалить пользователя '${modalConfirm.username}'`}/>
                )}
            </ErrorBoundary>
        </div>
    )
}