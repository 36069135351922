import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from "src/features/projects-list-page/styles.module.scss";
import {Button, Input, Tooltip} from "antd";
import {FiltersIcon} from "src/shared/ui/icons";
import {useDebounce} from "src/shared/lib/useDebounce";
import {ToggleVoteFiltersAction} from "src/store/general/actions";
import {useDispatch, useSelector} from "react-redux";
import {selectIsVoteFiltersActive} from "src/store/general/selectors";
import {GetVotingProjectsStartAction, SetVoteFiltersAction} from "src/store/voting/actions";
import {selectVoteFilters} from "src/store/voting/selectors";

const Filter = () => {
    const put = useDispatch();
    const isVoteFiltersActive = useSelector(selectIsVoteFiltersActive);
    const filters = useSelector(selectVoteFilters);
    const [searchString, setSearchString] = useState<string>(filters.filterData.searchString || '')
    const debouncedValue = useDebounce<string>(searchString, 1000)

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };


    useEffect(() => {
        put(SetVoteFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: debouncedValue,
            },
            page: 1,
        }));
        put(GetVotingProjectsStartAction());
    }, [debouncedValue])

    const onSearch = () => {
        put(SetVoteFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
            },
            page: 1,
        }));
        put(GetVotingProjectsStartAction());
    };
    const toggleSidebar = () => {
        put(ToggleVoteFiltersAction(!isVoteFiltersActive));
    };
    return (
        <div className={styles.searchContainer}>
            <div className={styles.searchBox}>
                <Tooltip title={'Введите название'}>
                    <Input.Search
                        className={styles.search}
                        placeholder="Введите название"
                        onChange={handleChangeSearch}
                        onSearch={onSearch}
                        value={searchString}
                    />
                </Tooltip>
                <Button
                    type={'default'}
                    onClick={toggleSidebar}
                    className={styles.openSidebar}
                >
                    <FiltersIcon/> Фильтры
                </Button>
            </div>
            <div className={styles.searchCount}>Найдено: {filters.totalCount}</div>
        </div>
    )
};

export default Filter;