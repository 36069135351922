import { NominationsActionTypes } from './action-types';
import { NominationSearch, RequestData } from '../types';

export const SetNominationAction = (filter: RequestData) => ({
    type: NominationsActionTypes.SET_NOMINATIONS, filter
});

export const GetNominationStartAction = () => ({
    type: NominationsActionTypes.GET_NOMINATIONS_START,
});

export const GetNominationSuccessAction = (nominations: NominationSearch) => ({
    type: NominationsActionTypes.GET_NOMINATIONS_SUCCESS,
    nominations,
});

export const GetNominationErrorAction = (error: any) => ({
    type: NominationsActionTypes.GET_NOMINATIONS_ERROR,
    error,
});
