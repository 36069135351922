import { InferValueTypes } from 'src/types/common';
import { NominationsActionTypes } from './action-types';
import * as actions from './actions';
import { NominationSearch, RequestData } from '../types';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type State = {
    data: NominationSearch['content'] | [];
    filter: RequestData
    isLoading: boolean;
    error: any;
};

export const initialState: State = {
    data: [],
    filter: {
        page: 1,
        pageSize: 20,
        filterData: {}
    },
    isLoading: false,
    error: null,
};


export default function reducer(
    state: State = initialState,
    action: ActionTypes,
): State {
    switch (action.type) {
        case NominationsActionTypes.SET_NOMINATIONS:
            return {
                ...state,
                filter: action.filter
            };
        case NominationsActionTypes.GET_NOMINATIONS_START:
            return {
                ...state,
                data: state.data,
                isLoading: true,
            };
        case NominationsActionTypes.GET_NOMINATIONS_SUCCESS:
            return {
                ...state,
                data: action.nominations.content,
                filter: {
                    ...state.filter,
                    totalCount: action.nominations.totalCount
                },
                isLoading: false,
            };
        case NominationsActionTypes.GET_NOMINATIONS_ERROR:
            return {
                ...initialState,
                error: action.error,
            };
        default:
            return state;
    }
}
