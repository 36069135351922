import { VotingActionTypes } from 'src/store/voting/action-types';
import { TVotingFiltersState } from 'src/store/voting/reducer/filters';
import {
    TVoteDetailedDocument,
    TVoteDetails,
    TVoteSummary,
    TVotingComment,
    TVotingOption,
    TVotingType,
} from 'src/types/vote';
import { TPagination, TSorting } from 'src/types/pageable-request';

export const SetVoteFiltersAction = (filters: TVotingFiltersState) => ({
    type: VotingActionTypes.SET_FILTERS,
    filters,
});
export const ResetVoteFiltersAction = () => ({
    type: VotingActionTypes.RESET_FILTERS,
});

export const GetProjectsVotingIdTriggerAction = () => ({
    type: VotingActionTypes.GET_PROJECTS_VOTING_ID_TRIGGER,
});
export const GetDocumentsVotingIdTriggerAction = () => ({
    type: VotingActionTypes.GET_DOCUMENTS_VOTING_ID_TRIGGER,
});

export const GetVotingTypesTriggerAction = () => ({
    type: VotingActionTypes.GET_VOTING_TYPES_TRIGGER,
});
export const GetVotingTypesStartAction = () => ({
    type: VotingActionTypes.GET_VOTING_TYPES_START,
});
export const GetVotingTypesSuccessAction = (data: TVotingType[]) => ({
    type: VotingActionTypes.GET_VOTING_TYPES_SUCCESS,
    data,
});
export const GetVotingTypesErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_VOTING_TYPES_ERROR,
    error,
});

export const GetVotingOptionsStartAction = (voteOptionTypeId?: number) => ({
    type: VotingActionTypes.GET_VOTING_OPTIONS_START, voteOptionTypeId
});
export const GetVotingOptionsSuccessAction = (data: TVotingOption[]) => ({
    type: VotingActionTypes.GET_VOTING_OPTIONS_SUCCESS,
    data,
});
export const GetVotingOptionsErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_VOTING_OPTIONS_ERROR,
    error,
});

export const VoteStartAction = (data: {
    voteDetails: TVoteDetails,
    id?: number,
    isList?: boolean;
}) => ({
    type: VotingActionTypes.VOTE_START,
    data,
});
export const VoteSuccessAction = () => ({
    type: VotingActionTypes.VOTE_SUCCESS,
});
export const VoteErrorAction = (error: any) => ({
    type: VotingActionTypes.VOTE_ERROR,
    error,
});

// Summary
export const GetVoteProjectsSummaryTriggerAction = (id: number) => ({
    type: VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_TRIGGER,
    id,
});
export const GetVoteProjectsSummaryStartAction = () => ({
    type: VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_START,
});
export const GetVoteProjectsSummarySuccessAction = (data: TVoteSummary) => ({
    type: VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_SUCCESS,
    data,
});
export const GetVoteProjectsSummaryErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_VOTE_PROJECTS_SUMMARY_ERROR,
    error,
});

export const GetVoteDocumentsSummaryTriggerAction = (id: number) => ({
    type: VotingActionTypes.GET_VOTE_DOCUMENTS_SUMMARY_TRIGGER,
    id,
});
export const GetVoteDocumentsSummaryStartAction = () => ({
    type: VotingActionTypes.GET_VOTE_DOCUMENTS_SUMMARY_START,
});
export const GetVoteDocumentsSummarySuccessAction = (data: TVoteSummary) => ({
    type: VotingActionTypes.GET_VOTE_DOCUMENTS_SUMMARY_SUCCESS,
    data,
});
export const GetVoteDocumentsSummaryErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_VOTE_DOCUMENTS_SUMMARY_ERROR,
    error,
});

// list of documents
export const GetProjectsVotingListTriggerAction = () => ({
    type: VotingActionTypes.GET_PROJECTS_VOTING_LIST_TRIGGER,
});
export const GetProjectsVotingListStartAction = () => ({
    type: VotingActionTypes.GET_PROJECTS_VOTING_LIST_START,
});
export const GetProjectsVotingListSuccessAction = (data: TVoteSummary[]) => ({
    type: VotingActionTypes.GET_PROJECTS_VOTING_LIST_SUCCESS,
    data,
});
export const GetProjectsVotingListErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_PROJECTS_VOTING_LIST_ERROR,
    error,
});

export const GetDocumentsVotingListTriggerAction = () => ({
    type: VotingActionTypes.GET_DOCUMENTS_VOTING_LIST_TRIGGER,
});
export const GetDocumentsVotingListStartAction = () => ({
    type: VotingActionTypes.GET_DOCUMENTS_VOTING_LIST_START,
});
export const GetDocumentsVotingListSuccessAction = (data: TVoteSummary[]) => ({
    type: VotingActionTypes.GET_DOCUMENTS_VOTING_LIST_SUCCESS,
    data,
});
export const GetDocumentsVotingListErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_DOCUMENTS_VOTING_LIST_ERROR,
    error,
});

export const GetVotingProjectsStartAction = () => ({
    type: VotingActionTypes.GET_VOTING_PROJECTS_START,
});
export const GetVotingProjectsSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_VOTING_PROJECTS_SUCCESS,
    data,
});
export const GetVotingProjectsErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_VOTING_PROJECTS_ERROR,
    error,
});


export const SetProjectsListFilter = (data: any) => ({
    type: VotingActionTypes.SET_PROJECTS_LIST_FILTERS,
    data
})
export const ResetProjectsListFilter = () => ({
    type: VotingActionTypes.RESET_PROJECTS_LIST_FILTERS,
})
export const SetDocumentListFilter = (data: any) => ({
    type: VotingActionTypes.SET_DOCUMENT_LIST_FILTERS,
    data
})
export const ResetDocumentListFilter = () => ({
    type: VotingActionTypes.RESET_DOCUMENT_LIST_FILTERS,
})
export const GetDocumentListStartAction = () => ({
    type: VotingActionTypes.GET_DOCUMENT_LIST_START,
});
export const GetDocumentListSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_DOCUMENT_LIST_SUCCESS,
    data,
});
export const GetDocumentListErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_DOCUMENT_LIST_ERROR,
    error,
});

export const GetVotingDetailedProjectStartAction = (votingId: string, projectId: string) => ({
    type: VotingActionTypes.GET_DETAILED_PROJECT_START,
    votingId,
    projectId,
});
export const GetVotingDetailedProjectSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_DETAILED_PROJECT_SUCCESS,
    data,
});
export const GetVotingDetailedProjectErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_DETAILED_PROJECT_ERROR,
    error,
});

export const GetVotingDetailedDocumentStartAction = (votingId: string, projectId: string) => ({
    type: VotingActionTypes.GET_DETAILED_DOCUMENT_START,
    votingId,
    projectId,
});
export const GetVotingDetailedDocumentSuccessAction = (data: TVoteDetailedDocument) => ({
    type: VotingActionTypes.GET_DETAILED_DOCUMENT_SUCCESS,
    data,
});
export const GetVotingDetailedDocumentErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_DETAILED_DOCUMENT_ERROR,
    error,
});

//report actions //
//Project
export const GetVotingReportProjectStartAction = (votingId: string, projectId: string) => ({
    type: VotingActionTypes.GET_REPORT_PROJECT_START,
    votingId,
    projectId,
});
export const GetVotingReportProjectSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_REPORT_PROJECT_SUCCESS,
    data,
});
export const GetVotingReportProjectErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_REPORT_PROJECT_ERROR,
    error,
});

//Document
export const GetVotingReportDocumentStartAction = (votingBlockId: string) => ({
    type: VotingActionTypes.GET_REPORT_DOCUMENT_START,
    votingBlockId
});
export const GetVotingReportDocumentSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_REPORT_DOCUMENT_SUCCESS,
    data,
});
export const GetVotingReportDocumentErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_REPORT_DOCUMENT_ERROR,
    error,
});

// ADMIN | MODERATOR

export const ActivateVotingAction = (payload: {
    id: number;
    status: 'activate' | 'deactivate';
    type: 'projects' | 'documents';
}) => ({
    type: VotingActionTypes.ACTIVATE_VOTING,
    payload,
});

export const SetAllVotesListSortingAction = (data: TPagination & TSorting) => ({
    type: VotingActionTypes.SET_ALL_VOTES_LIST_SORTING,
    data,
});
export const GetAllVotesListStartAction = () => ({
    type: VotingActionTypes.GET_ALL_VOTES_LIST_START,
});
export const GetAllVotesListSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_ALL_VOTES_LIST_SUCCESS,
    data,
});
export const GetAllVotesListErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_VOTES_LIST_ERROR,
    error,
});

export const SetAllDocumentsListSortingAction = (data: TPagination & TSorting) => ({
    type: VotingActionTypes.SET_ALL_DOCUMENTS_LIST_SORTING,
    data,
});
export const GetAllDocumentsListStartAction = () => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_LIST_START,
});
export const GetAllDocumentsListSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_LIST_SUCCESS,
    data,
});
export const GetAllDocumentsListErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_LIST_ERROR,
    error,
});

export const GetAllProjectsVoteSummaryStartAction = (votingId: number) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_VOTE_SUMMARY_START,
    votingId,
});
export const GetAllProjectsVoteSummarySuccessAction = (data: TVoteSummary) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_VOTE_SUMMARY_SUCCESS,
    data,
});
export const GetAllProjectsVoteSummaryErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_VOTE_SUMMARY_ERROR,
    error,
});

export const GetAllDocumentsVoteSummaryStartAction = (votingId: number) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_VOTE_SUMMARY_START,
    votingId,
});
export const GetAllDocumentsVoteSummarySuccessAction = (data: TVoteSummary) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_VOTE_SUMMARY_SUCCESS,
    data,
});
export const GetAllDocumentsVoteSummaryErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_VOTE_SUMMARY_ERROR,
    error,
});

export const GetAllProjectsReportStartAction = (
    votingId: number,
    reportType: 'comments'| 'votes',
    projectId?: number,
) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_REPORT_START,
    votingId,
    reportType,
    projectId,
});
export const GetAllProjectsReportSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_REPORT_SUCCESS,
    data,
});
export const GetAllProjectsReportErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_PROJECTS_REPORT_ERROR,
    error,
});

export const GetDocumentReportStartAction = (
    reportType: 'comments'| 'votes',
    projectId?: number,
) => ({
    type: VotingActionTypes.GET_DOCUMENT_REPORT_START,
    reportType,
    projectId,
});

export const GetAllDocumentsReportStartAction = (
    votingId: number,
    reportType: 'comments'| 'votes',
    projectId?: number,
) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_START,
    votingId,
    reportType,
    projectId,
});
export const GetAllDocumentsReportSuccessAction = (data: any) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_SUCCESS,
    data,
});
export const GetAllDocumentsReportErrorAction = (error: any) => ({
    type: VotingActionTypes.GET_ALL_DOCUMENTS_REPORT_ERROR,
    error,
});

export const AddVotingCommentStartAction = (comment: TVotingComment, votingId: string, projectId: string) => ({
    type: VotingActionTypes.ADD_VOTING_COMMENT_START,
    comment,
    votingId,
    projectId,
});
export const AddVotingCommentSuccessAction = (comment: TVotingComment, id: number) => ({
    type: VotingActionTypes.ADD_VOTING_COMMENT_SUCCESS,
    comment,
    id,
});
export const AddVotingCommentErrorAction = (error: any) => ({
    type: VotingActionTypes.ADD_VOTING_COMMENT_ERROR,
    error,
});

export const EditVotingCommentStartAction = (
    comment: TVotingComment,
    commentId: number,
    votingId: string,
    projectId: string,
) => ({
    type: VotingActionTypes.EDIT_VOTING_COMMENT_START,
    comment,
    commentId,
    votingId,
    projectId,
});
export const EditVotingCommentSuccessAction = (comment: TVotingComment, commentId: number) => ({
    type: VotingActionTypes.EDIT_VOTING_COMMENT_SUCCESS,
    comment,
    commentId,
});
export const EditVotingCommentErrorAction = (error: any, commentId: number) => ({
    type: VotingActionTypes.EDIT_VOTING_COMMENT_ERROR,
    error,
    commentId,
});

export const DeleteVotingCommentStartAction = (
    commentId: number,
    votingId: string,
    projectId: string,
) => ({
    type: VotingActionTypes.DELETE_VOTING_COMMENT_START,
    commentId,
    votingId,
    projectId,
});
export const DeleteVotingCommentSuccessAction = (commentId: number) => ({
    type: VotingActionTypes.DELETE_VOTING_COMMENT_SUCCESS,
    commentId,
});
export const DeleteVotingCommentErrorAction = (error: any, commentId: number) => ({
    type: VotingActionTypes.DELETE_VOTING_COMMENT_ERROR,
    error,
    commentId,
});
