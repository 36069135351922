import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import {Button, DatePicker, Form, Input, Select, Switch, Tooltip} from 'antd';

import {
    useFormik,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import qs from 'query-string';
import moment from 'moment';

import { TEditVotingRequestData, TVotingParams } from 'src/types/vote';
import { DateAndTimeFormat } from 'src/constatnts/format';

import {GetVotingOptionsStartAction, GetVotingTypesTriggerAction} from 'src/store/voting/actions';
import { GetAllSessionsStartAction } from 'src/store/sesssions/actions';
import { GetYearsTriggerAction } from 'src/store/years/actions';
import {
    AddVotingAction,
    EditVotingAction,
    GetEditorDetailedProjectStartAction,
    GetEditorProjectsStartAction,
    GetEditorRegentsStartAction,
    SetDocumentAction,
} from 'src/store/voting-editor/actions';
import { LogsActionsStartAction } from 'src/store/logs/actions';

import { selectYears, selectYearsStatus } from 'src/store/years/selectors';
import { selectAllSessions } from 'src/store/sesssions/selectors';
import {
    selectVoteOptionsData,
    selectVotingTypesData,
    selectVotingTypesStatus
} from 'src/store/voting/selectors';
import {
    selectVotingEditorBlocks,
    selectVotingEditorDetailedProject,
    selectVotingEditorDetailedProjectStatus,
    selectVotingEditorProjects,
} from 'src/store/voting-editor/selectors';

import { mainModuleRoutes } from 'src/routing/projects-module';
import { ToParentPage } from 'src/components/to-parent-page';
import { SelectUsersModal } from 'src/components/modals/select-users';
import { SelectProjectsModal } from 'src/components/modals/select-projects';
import UsersList from './usersList';
import ProjectsList from './projectsList';
import BlocksList from './blocksList';

import styles from './styles.module.scss';
import axios from "axios";
import {TRegent} from "src/types/user";

type TProps = {
    isEdit?: boolean;
};

export type TVotingEditorData = {
    activated: boolean;
    familiarizationDateTime: moment.Moment | null | undefined;
    startDateTime: moment.Moment | null | undefined;
    endDateTime: moment.Moment | null | undefined;
    name: string;
    sessionId: string | number;
    votingBlockIds: number[],
    votingTypeId: string;
    year: string;
    users: TEditVotingRequestData['users'];
    projects: TEditVotingRequestData['projects'];
    votingBlocks: TEditVotingRequestData['votingBlocks'];
    voteOptionTypeId: string

    nominationVoteCount: string
    nominationVoteOptionId: string
    nominationAutoVoteOptionId: string
};

const initialData: TVotingEditorData = {
    activated: true,
    familiarizationDateTime: null,
    startDateTime: null,
    endDateTime: null,
    name: '',
    sessionId: '',
    votingBlockIds: [],
    votingTypeId: '',
    year: '',
    users: [],
    projects: [],
    votingBlocks: [],
    voteOptionTypeId: '',

    nominationVoteCount: '',
    nominationVoteOptionId: '',
    nominationAutoVoteOptionId: '',
};

const { Option } = Select;

export const VotingEditor: FC<TProps> = ({ isEdit }) => {
    const [isInit, setIsInit] = useState(true);
    const [isReset, setIsReset] = useState(true);
    const [isUsersModalActive, setIsUsersModalActive] = useState(false);
    const [conflictUsers, setConflictUsers] = useState<{
        isOpen: boolean, project: TVotingEditorData['projects'][0] | null
    }>({
        isOpen: false,
        project: null

    });
    const [isProjectsModalActive, setIsProjectsModalActive] = useState(false);
    const put = useDispatch();
    const { pathname, search } = useLocation();
    const votingId = pathname.split('/')[2];
    const { type } = qs.parse(search);
    const detailedProject = useSelector(selectVotingEditorDetailedProject);
    const detailedProjectStatus = useSelector(selectVotingEditorDetailedProjectStatus);
    const projects = useSelector(selectVotingEditorProjects);
    const years = useSelector(selectYears);
    const yearsStatus = useSelector(selectYearsStatus);
    const sessions = useSelector(selectAllSessions);
    const votingTypes = useSelector(selectVotingTypesData);
    const VoteOptions = useSelector(selectVoteOptionsData);

    const votingTypesStatus = useSelector(selectVotingTypesStatus);
    const blocks = useSelector(selectVotingEditorBlocks);

    const typesVoting = useTypesVoting();

    const votingForm = useFormik({
        initialValues: {
            ...initialData,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required('Обязательное поле'),
            year: Yup.string().required('Обязательное поле'),
            sessionId: Yup.string().required('Обязательное поле'),
            votingTypeId: Yup.string().required('Обязательное поле'),
            voteOptionTypeId: Yup.string().required('Обязательное поле'),
            familiarizationDateTime: Yup.mixed().test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ),
            startDateTime: Yup.mixed().test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ).test(
                'test2',
                'Начало не может быть раньше ознакомления',
                function(value){
                    let { familiarizationDateTime } = this.parent;
                    return moment(value).isSameOrAfter(moment(familiarizationDateTime), 'd');
                }
            ),
            endDateTime: Yup.mixed().test(
                'test1',
                'Не корректная дата',
                function (value){
                    return moment(value).isValid();
                }
            ).test(
                'test2',
                // 'endDateTime date must be after startDateTime',
                'Дата окончания должна быть после старта',
                function(value){
                    let { startDateTime } = this.parent;
                    return moment(value).isAfter(moment(startDateTime), 'h');
                }
            ),
            nominationVoteCount: Yup.mixed().test(
                'nominationVoteCount',
                'Поле обязательно',
                function (value, context){
                    const {nominationAutoVoteOptionId, nominationVoteOptionId}: TVotingEditorData = context.parent;
                    return nominationAutoVoteOptionId || nominationVoteOptionId ? !!value: true;
                }
            ),
            nominationVoteOptionId: Yup.mixed().test(
                'nominationVoteOptionId',
                'Поле обязательно',
                function (value, context){
                    const {nominationAutoVoteOptionId, nominationVoteCount}: TVotingEditorData = context.parent;
                    return nominationAutoVoteOptionId || nominationVoteCount ? !!value: true;
                }
            ),
            nominationAutoVoteOptionId: Yup.mixed().test(
                'nominationAutoVoteOptionId',
                'Поле обязательно',
                function (value, context){
                    const {nominationVoteCount, nominationVoteOptionId}: TVotingEditorData = context.parent;
                    return nominationVoteCount || nominationVoteOptionId ? !!value: true;
                }
            ),
        }),
    });

    function onSubmit(
        values: TVotingEditorData,
        _formikHelpers: FormikHelpers<TVotingEditorData>
    ) {
        const sessionId = votingForm.values.sessionId;
        const result: TVotingParams = {
            activated: votingForm.values.activated,
            endDateTime: moment(votingForm.values.endDateTime).format(),
            familiarizationDateTime: moment(votingForm.values.familiarizationDateTime).format(),
            startDateTime: moment(votingForm.values.startDateTime).format(),
            name: votingForm.values.name,
            projects: votingForm.values.projects.map((el) => ({
                projectId: el.project.id,
                interestConflictUserIds: el.interestConflictUsers?.map((el) => el.id) || null
            })),
            sessionId: typeof sessionId === 'number' ?
                sessionId : !!sessionId ? +sessionId : 1, //todo change null
            userIds: votingForm.values.users.map((item) => (+item.id)),
            votingBlockIds: blocks
                ? blocks.map((item) => (+item.id))
                : [],
            votingTypeId: +votingForm.values.votingTypeId,
            voteOptionTypeId: +votingForm.values.voteOptionTypeId,

            nominationVoteCount: +votingForm.values.nominationVoteCount || null,
            nominationVoteOptionId: +votingForm.values.nominationVoteOptionId || null,
            nominationAutoVoteOptionId: +votingForm.values.nominationAutoVoteOptionId || null,
        };

        if (isEdit) {
            put(EditVotingAction(result, votingId));
        } else {
            put(AddVotingAction(result));
        }
    }

    const getProjectsList = (sessionId: string) => {
        if (sessionId.length === 0) return;
        const session = sessions.find((item) => (
            +item.id === +votingForm.values.sessionId
        ));
        put(GetEditorProjectsStartAction({
            params: {
                sessionName: session ? session.name : '',
                year: votingForm.values.year,
            },
            // @ts-ignore
            type: type,
            votingId,
        }, sessionId));
    };

    const findSessionsByYear = (year: string) => {
        return sessions.filter((session) => (
            moment(session.endDate).format('yyyy') === year
        ));
    };

    const handleCancel = () => {
        if (isEdit) put(LogsActionsStartAction('CANCEL_EDIT_VOTING'))
        else put(LogsActionsStartAction('CANCEL_CREATE_VOTING'))
        put(push(mainModuleRoutes['all-votes-list'].path));
    };

    const getNewType = (value: string) => {
        let newType = 'projects';
        if (value === "Голосование по заявкам") { newType = 'projects' }
        if (value === "Голосование по документам") { newType = 'documents' }

        return newType;
    };

    const handleChangeSelect = (fieldName: string) => (value: string, option: any) => {
        //side effects
        if (fieldName === 'voteOptionTypeId') {
            put(GetVotingOptionsStartAction(Number(value)))
            votingForm.setFieldValue('nominationVoteOptionId', '');
            votingForm.setFieldValue('nominationAutoVoteOptionId', '');
        }

        if (option && option.value) {
            votingForm.setFieldValue(fieldName, `${option.value}`);
        } else {
            votingForm.setFieldValue(fieldName, '');
        }

        if (fieldName === 'year') {
            const currentSessions = findSessionsByYear(value);
            votingForm.setFieldValue(
                'sessionId',
                currentSessions ? currentSessions[currentSessions.length - 1].id : undefined,
            );
            votingForm.setFieldValue('projects', []);
        }
        if (fieldName === 'sessionId') {
            votingForm.setFieldValue('projects', []);
        }

        if (fieldName === 'votingTypeId') {
            put(push({
                pathname,
                search: `?type=${getNewType(option.children)}`
            }));
        }
        requestAnimationFrame(() => {
            votingForm.setTouched({[fieldName]: true}, true);
        })
    };

    const handleChangeDate = (name: string) => (value: moment.Moment | null) => {
        votingForm.setFieldValue(name, value ? value : '');
        requestAnimationFrame(() => {
            votingForm.setTouched({[name]: true}, true);
        })
    };

    // @ts-ignore
    const handleBlurDate = (_event: FocusEvent<HTMLInputElement>) => {
        // votingForm.setFieldValue(name, value ? value : '');
    };

    const onSubmitUsersModal = (users: TEditVotingRequestData['users']) => {
        votingForm.setFieldValue('users', users && Array.isArray(users) ? users : []);
        setIsUsersModalActive(false);
    };

    const onSubmitProjectsModal = (projects: TEditVotingRequestData['projects']) => {
        votingForm.setFieldValue('projects', projects);
        setIsProjectsModalActive(false);
    };

    const handleChangeActive = () => {
        votingForm.setFieldValue('activated', !votingForm.values.activated);
    };

    const fieldHasAnError = (fieldName: keyof TVotingEditorData): boolean => {
        return Boolean((isEdit && votingForm.errors[fieldName]) ||
        (!isEdit && votingForm.errors[fieldName] && votingForm.touched[fieldName]));
    };

    // initialize form
    useEffect(() => {
        if (isInit
            && !isReset
            && detailedProjectStatus.isLoaded
            && votingTypesStatus.isLoaded
            && yearsStatus.isLoaded
            && sessions.length > 0
            && isEdit) {
            const votingType = votingTypes.find((item) => {
                return (type === 'projects' && item.name === 'Голосование по заявкам')
                    || (type === 'documents' && item.name === 'Голосование по документам');
            });

            votingForm.setValues({
                ...initialData,
                ...detailedProject,
                votingTypeId: votingType ? `${votingType.id}` : '1',
                activated: Boolean(detailedProject.activated),
                familiarizationDateTime: moment(detailedProject.familiarizationDateTime),
                startDateTime: moment(detailedProject.startDateTime),
                endDateTime: moment(detailedProject.endDateTime),
                name: detailedProject.name || '',
                year: detailedProject.session
                    ? moment(detailedProject.session.endDate).format('yyyy')
                    : years[0],
                sessionId: detailedProject.session ? `${detailedProject.session.id}` : '',
                voteOptionTypeId: detailedProject.voteOptionType ? `${detailedProject.voteOptionType.id}` : '',

                nominationVoteCount: detailedProject.nominationVoteCount ? `${detailedProject.nominationVoteCount}` : '',
                nominationVoteOptionId: detailedProject.nominationVoteOption ? `${detailedProject.nominationVoteOption.id}` : '',
                nominationAutoVoteOptionId: detailedProject.nominationAutoVoteOption ? `${detailedProject.nominationAutoVoteOption.id}` : '',
            });
            put(SetDocumentAction(detailedProject.votingBlocks || []));
            setIsInit(false);
            if (detailedProject.voteOptionType?.id) {
                put(GetVotingOptionsStartAction(Number(detailedProject.voteOptionType?.id)))
            }
        }

        if (isInit
            && !isEdit
            && !isReset
            && votingTypesStatus.isLoaded
            && yearsStatus.isLoaded
            && sessions.length > 0
        ) {
            votingForm.setValues(initialData);
            put(SetDocumentAction([]));
            setIsInit(false);
        }
    }, [detailedProjectStatus, votingTypesStatus, yearsStatus, sessions, detailedProject]);
    // useEffect(() => {
    //     /**
    //      * Изменения роутинга в зависимости от голосования
    //      */
    //     // if (votingTypesStatus.isLoaded) {
    //     //     const votingType = votingTypes.find((item) => {
    //     //         return item.id.toString() === votingForm.values.votingTypeId;
    //     //     });
    //     //
    //     //     if (votingType) {
    //     //         put(push({
    //     //             pathname,
    //     //             search: `?type=${getNewType(votingType.name)}`
    //     //         }));
    //     //     }
    //     // }
    //     put(GetVotingOptionsStartAction(Number(votingForm.values.voteOptionTypeId)))
    // }, [votingForm.values.voteOptionTypeId]);
    useEffect(() => {
        if (isEdit && votingId.length === 0) {
            put(push(mainModuleRoutes['create-voting'].path));
        }
    }, [isEdit, votingId]);
    useEffect(() => {
        if (!type) {
            put(push(mainModuleRoutes['all-votes-list'].path));
        }
    }, [type]);
    useEffect(() => {
        put(GetVotingTypesTriggerAction());
        put(GetAllSessionsStartAction());
        put(GetYearsTriggerAction());
        put(GetEditorRegentsStartAction());

        if (isEdit && votingId) {
            put(GetEditorDetailedProjectStartAction(votingId));
        }

        setIsReset(false);
        if (isEdit) put(LogsActionsStartAction('OPEN_EDIT_VOTING_FORM'));
        else put(LogsActionsStartAction('OPEN_NEW_VOTING_FORM'));
    }, []);
    useEffect(() => {
        const { sessionId, year } = votingForm.values;
        if (sessionId && projects.sessionId !== sessionId && year && !isInit && !isReset && sessions.length > 0) {
            getProjectsList(`${sessionId}`);
        }
    }, [votingForm.values.sessionId, isInit, isReset, sessions]);
    useEffect(() => {
        /**
         * Выбор голосования по заявкам по умолчанию
         */
        if (votingTypes.length) {
            const voting = votingTypes.find((el) => el.name === 'Голосование по заявкам');
            if (voting) {
                votingForm.setFieldValue('votingTypeId', voting.id);
            }
        }
    }, [votingTypes])

    const validateHandler = (): boolean => {
        if (votingForm.isValid) {
            if (type === 'documents' && blocks.length) return false
            else if (type === 'projects' && votingForm.values.projects.length) return false
            else return true
        }
        return true
    }
    const validateHandlerTooltip = (): string | undefined => {
        if (votingForm.isValid) {
            if (type === 'documents' && !blocks.length) {
                return `Тип голосования "по документам", проекты на голосование отсутствуют`
            }
            else if (type === 'projects' && !votingForm.values.projects.length) {
                return `Тип голосования "по заявкам", проекты на голосование отсутствуют`
            }
            return ''
        } else {
            return 'Не валидно заполнено форма, все поля обязательны для заполнения'
        }
    }

    const openConflictUsers = (project: TVotingEditorData['projects'][0]) => {
        setConflictUsers({isOpen: true, project})
    }
    const onSubmitConflictUsersModal = (projectId?: number) => (users: TRegent[]) => {
        const projects = votingForm.values.projects;
        votingForm.setFieldValue('projects', projects.map((el) => {
            if (el.project.id === projectId) {
                return {
                    ...el,
                    interestConflictUsers: users
                }
            } else return el;
        }))
        setConflictUsers({isOpen: false, project: null})
    }

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <ToParentPage className={styles.toParentPage} text=""  />
                {/*parentPath={(type === 'projects'*/}
                {/*? mainModuleRoutes['all-projects-list'].path.replace(':votingId', `${votingId}`)*/}
                {/*: mainModuleRoutes['all-documents-list'].path.replace(':votingId', `${votingId}`))}*/}

                <header className={styles.pageTitle}>
                    { isEdit ? 'Редактирование голосования' : 'Добавление голосования' }
                </header>
            </div>

            <form
                name="votingForm"
                onSubmit={votingForm.handleSubmit}
                className={styles.form}
            >
                {!votingForm.values.votingTypeId && (
                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            votingForm.submitCount > 0 && votingForm.errors.name
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="votingTypeId" className={styles.label}>
                            Тип голосования
                        </label>
                        <Select
                            allowClear={true}
                            value={votingForm.values.votingTypeId ?`${votingForm.values.votingTypeId}` : undefined}
                            onChange={handleChangeSelect('votingTypeId')}
                            disabled={isEdit}
                            placeholder={'Выберите тип голосования'}
                        >
                            { votingTypes.map((item) => (
                                <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                            )) }
                        </Select>
                        {votingForm.errors.votingTypeId && votingForm.touched.votingTypeId && (
                            <small className={styles.error}>{votingForm.errors.votingTypeId}</small>
                        )}
                    </Form.Item>
                )}

                {type !== 'documents' && (
                    <>
                        <div className={styles.formRow}>
                            <div className={styles.formCell}>
                                <Form.Item
                                    className={styles.formItem}
                                    validateStatus={ (
                                        votingForm.submitCount > 0 && votingForm.errors.year
                                    ) ? 'error' : '' }
                                >
                                    <label htmlFor="year" className={styles.label}>
                                        Год проекта
                                    </label>
                                    <Select
                                        allowClear={true}
                                        value={votingForm.values.year ?`${votingForm.values.year}` : undefined}
                                        onChange={handleChangeSelect('year')}
                                        placeholder={'Выберите год'}
                                    >
                                        { years.map((item) => (
                                            <Option key={item} value={item}>{item}</Option>
                                        )) }
                                    </Select>
                                    {votingForm.errors.year && votingForm.touched.year && (
                                        <small className={styles.error}>{votingForm.errors.year}</small>
                                    )}
                                </Form.Item>
                            </div>
                            <div className={styles.formCell}>
                                <Form.Item
                                    className={styles.formItem}
                                    validateStatus={ (
                                        votingForm.submitCount > 0 && votingForm.errors.sessionId
                                    ) ? 'error' : '' }
                                >
                                    <label htmlFor="sessionId" className={styles.label}>
                                        Отбор
                                    </label>
                                    <Select
                                        allowClear={true}
                                        value={votingForm.values.sessionId ? `${votingForm.values.sessionId}` : undefined}
                                        onChange={handleChangeSelect('sessionId')}
                                        placeholder={'Выберите отбор'}
                                        disabled={!(!!votingForm.values.year)}
                                    >
                                        { sessions.filter((item) => {
                                            return !(years.length === 0 ||
                                                (moment(item.endDate)
                                                    .format('yyyy') !== `${votingForm.values.year}`));
                                        }).map((item) => (
                                            <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                                        )) }
                                    </Select>
                                    {votingForm.errors.sessionId && votingForm.touched.sessionId && (
                                        <small className={styles.error}>{votingForm.errors.sessionId}</small>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </>
                )}

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ (
                        votingForm.submitCount > 0 && votingForm.errors.name
                    ) ? 'error' : '' }
                >
                    <label htmlFor="name" className={styles.label}>
                        Название голосования
                    </label>
                    <Input
                        className={cn(
                            styles.input,
                            { [`${styles.error}`]: votingForm.submitCount > 0 && votingForm.errors.name }
                        )}
                        name="name"
                        placeholder=""
                        value={votingForm.values.name}
                        onChange={votingForm.handleChange}
                        onBlur={votingForm.handleBlur}
                    />
                    {votingForm.errors.name && votingForm.touched.name && (
                        <small className={styles.error}>{votingForm.errors.name}</small>
                    )}
                </Form.Item>

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ (
                        votingForm.submitCount > 0 && votingForm.errors.name
                    ) ? 'error' : '' }
                >
                    <label htmlFor="voteOptionTypeId" className={styles.label}>
                        Схема голосования
                    </label>
                    <Select
                        allowClear={true}
                        placeholder={'Выберите схему голосования'}
                        value={votingForm.values.voteOptionTypeId ?`${votingForm.values.voteOptionTypeId}` : undefined}
                        onChange={handleChangeSelect('voteOptionTypeId')}
                    >
                        { typesVoting.map((item) => (
                            <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                        )) }
                    </Select>
                    {votingForm.errors.voteOptionTypeId && votingForm.touched.voteOptionTypeId && (
                        <small className={styles.error}>{votingForm.errors.voteOptionTypeId}</small>
                    )}
                </Form.Item>

                <div className={styles.formRow}>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ (
                                votingForm.submitCount > 0 && votingForm.errors.nominationVoteCount
                            ) ? 'error' : '' }
                        >
                            <label htmlFor="nominationVoteCount" className={styles.label}>
                                Количества голосов
                            </label>
                            <Input
                                className={cn(
                                    styles.input,
                                    { [`${styles.error}`]: votingForm.submitCount > 0 && votingForm.errors.nominationVoteCount }
                                )}
                                name="vote_count"
                                placeholder="Количества голосов"
                                value={votingForm.values.nominationVoteCount}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (Number(value)) {
                                        votingForm.setFieldValue('nominationVoteCount', value)
                                    }
                                    if (!value) {
                                        votingForm.setFieldValue('nominationVoteCount', '')
                                    }
                                }}
                                onBlur={votingForm.handleBlur}
                            />
                            {votingForm.errors.nominationVoteCount && (
                                <small className={styles.error}>{votingForm.errors.nominationVoteCount}</small>
                            )}
                        </Form.Item>
                    </div>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ (
                                votingForm.submitCount > 0 && votingForm.errors.nominationVoteOptionId
                            ) ? 'error' : '' }
                        >
                            <label htmlFor="nominationVoteOptionId" className={styles.label}>
                                Какой голос нужен
                            </label>
                            <Select
                                allowClear={true}
                                placeholder={'Какой голос нужен'}
                                disabled={!votingForm.values.voteOptionTypeId}
                                value={votingForm.values.nominationVoteOptionId}
                                onChange={(value) => {
                                    votingForm.setFieldValue('nominationVoteOptionId', value)
                                }}
                            >
                                { VoteOptions.map((item) => (
                                    <Option
                                        key={votingForm.values.voteOptionTypeId + '/' + item.id}
                                        value={`${item.id}`}
                                    >
                                        {item.name}
                                    </Option>
                                )) }
                            </Select>
                            {votingForm.errors.nominationVoteOptionId && (
                                <small className={styles.error}>{votingForm.errors.nominationVoteOptionId}</small>
                            )}
                        </Form.Item>
                    </div>
                </div>

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ (
                        votingForm.submitCount > 0 && votingForm.errors.nominationAutoVoteOptionId
                    ) ? 'error' : '' }
                >
                    <label htmlFor="auto_vote_option" className={styles.label}>
                        Поле для проставления голоса остальным проектам в номинации
                    </label>
                    <Select
                        allowClear={true}
                        placeholder={''}
                        disabled={!votingForm.values.voteOptionTypeId}
                        value={votingForm.values.nominationAutoVoteOptionId}
                        onChange={(value) => {
                            votingForm.setFieldValue('nominationAutoVoteOptionId', value)
                        }}
                    >
                        { VoteOptions.map((item) => (
                            <Option
                                key={votingForm.values.voteOptionTypeId + '/' + item.id}
                                value={`${item.id}`}
                            >
                                {item.name}
                            </Option>
                        )) }
                    </Select>
                    {votingForm.errors.nominationAutoVoteOptionId && (
                        <small className={styles.error}>{votingForm.errors.nominationAutoVoteOptionId}</small>
                    )}
                </Form.Item>



                <div className={styles.heading}>
                    Дата и время ознакомления
                </div>

                <Form.Item
                    className={styles.formItem}
                    validateStatus={ fieldHasAnError('familiarizationDateTime') ? 'error' : '' }
                >
                    <label htmlFor="familiarizationDateTime" className={styles.label}>
                        Дата и время ознакомления
                    </label>
                    {/*// @ts-ignore*/}
                    <DatePicker
                        className={styles.input}
                        name="familiarizationDateTime"
                        placeholder=""
                        format={DateAndTimeFormat}
                        showTime={true}
                        value={votingForm.values.familiarizationDateTime}
                        onChange={handleChangeDate('familiarizationDateTime')}
                        onBlur={handleBlurDate}
                    />
                    {votingForm.errors.familiarizationDateTime && (
                        <small className={styles.error}>{votingForm.errors.familiarizationDateTime}</small>
                    )}
                </Form.Item>

                <div className={styles.formRow}>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ fieldHasAnError('startDateTime') ? 'error' : '' }
                        >
                            <label htmlFor="startDateTime" className={styles.label}>
                                Дата и время начала
                            </label>
                            {/*// @ts-ignore*/}
                            <DatePicker
                                className={styles.input}
                                name="startDateTime"
                                placeholder=""
                                format={DateAndTimeFormat}
                                showTime={true}
                                value={votingForm.values.startDateTime}
                                onChange={handleChangeDate('startDateTime')}
                                onBlur={handleBlurDate}
                            />
                            {votingForm.errors.startDateTime && (
                                <small className={styles.error}>{votingForm.errors.startDateTime}</small>
                            )}
                        </Form.Item>
                    </div>
                    <div className={styles.formCell}>
                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ fieldHasAnError('endDateTime') ? 'error' : '' }
                        >
                            <label htmlFor="endDateTime" className={styles.label}>
                                Дата и время окончания
                            </label>
                            {/*// @ts-ignore*/}
                            <DatePicker
                                className={styles.input}
                                name="endDateTime"
                                placeholder=""
                                format={DateAndTimeFormat}
                                showTime={true}
                                value={votingForm.values.endDateTime}
                                onChange={handleChangeDate('endDateTime')}
                                onBlur={handleBlurDate}
                            />
                            {votingForm.errors.endDateTime && (
                                <small className={styles.error}>{votingForm.errors.endDateTime}</small>
                            )}
                        </Form.Item>
                    </div>
                </div>

                <div className={styles.heading}>
                    Участники голосования
                    <Button
                        type="default"
                        shape="circle"
                        className={styles.add}
                        onClick={() => {
                            put(LogsActionsStartAction('OPEN_VOTING_USER'))
                            setIsUsersModalActive(true)
                        }}
                    >+</Button>
                </div>
                <UsersList users={votingForm.values.users} />

                { type === 'projects'
                    ? (
                        <>
                            <div className={styles.heading}>
                                Проекты на голосование
                                <Button
                                    type="default"
                                    shape="circle"
                                    className={styles.add}
                                    onClick={() => {
                                        put(LogsActionsStartAction('OPEN_SELECT_VOTING_PROJECT_FORM'))
                                        setIsProjectsModalActive(true)
                                    }}
                                >+</Button>
                            </div>
                            <ProjectsList
                                projects={votingForm.values.projects}
                                conflictUsersHandler={openConflictUsers} />
                        </>
                    ) : (
                        <BlocksList
                            votingForm={votingForm}
                        />
                    )
                }

                <div className={styles.activateBlock}>
                    <Switch
                        checked={votingForm.values.activated}
                        onChange={handleChangeActive}
                    />
                    Активировать голосование
                </div>

                <div className={styles.actions}>
                    <Tooltip title={validateHandlerTooltip()}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={validateHandler()}
                        >Сохранить</Button>
                    </Tooltip>

                    <Button type="default" onClick={handleCancel}>Отменить</Button>
                </div>

            </form>

            <SelectUsersModal
                isOpened={isUsersModalActive}
                onClose={() => { setIsUsersModalActive(false) }}
                onSubmit={onSubmitUsersModal}
                selectedUsers={votingForm.values.users.map((el) => el.id)}
            />

            <SelectUsersModal
                isOpened={conflictUsers.isOpen}
                onClose={() => {
                    setConflictUsers({isOpen: false, project: null})
                }}
                onSubmit={onSubmitConflictUsersModal(conflictUsers.project?.project.id)}
                users={votingForm.values.users}
                selectedUsers={conflictUsers.project?.interestConflictUsers?.map((el) => el.id) || []}
            />

            <SelectProjectsModal
                isOpened={isProjectsModalActive}
                onClose={() => { setIsProjectsModalActive(false) }}
                onSubmit={onSubmitProjectsModal}
                selectedProjects={votingForm.values.projects}
            />
        </div>
    );
};
function useTypesVoting() {
    const [state, setState] = useState<Array<{id: number, name: string}>>([]);
    useEffect(() => {
        getOptions().then((res) => {
            setState(res)
        })
    }, [])
    return state
}

async function getOptions() {
    try {
        const {data} = await axios.get('/voting/votes/options/types');
        return data;
    } catch (e) {
        console.log(e)
    }
}