import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetCategoriesStartAction,
    GetCategoriesSuccessAction,
    GetCategoriesErrorAction,
} from 'src/store/categories/actions';
import { TCategory } from 'src/types/category';

export function* categoriesWorker(_action: ReturnType<typeof GetCategoriesStartAction>) {
    try {
        const { data }: AxiosResponse<TCategory[]> = yield axios({
            method: 'GET',
            //TODO old categories url: '/projects/categories',
            url: '/projects/nominations',
            headers: {},
        });

        yield put(GetCategoriesSuccessAction(data));
    } catch (error) {
        yield put(GetCategoriesErrorAction(error));
    }
}
