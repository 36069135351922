import React from 'react';
import {Drawer, Button, Tooltip} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ToggleProfileAction } from 'src/store/general/actions';
import { selectIsProfileActive } from 'src/store/general/selectors';
import {selectUserData} from 'src/store/auth/selectors';

import styles from './styles.module.scss';

export const ProfileSidebar = () => {
    const put = useDispatch();
    const isProfileActive = useSelector(selectIsProfileActive);
    // const isModerator = useSelector(selectIsModerator);
    // const isAdministrator = useSelector(selectIsAdmin);
    const userData = useSelector(selectUserData);
    const user = (userData && userData.user) ? userData.user : {};

    // const [modalData, setModalData] = useState<TPDFModal>(null);

    const onClose = () => {
        put(ToggleProfileAction(false));
    };

    // const openPDFModal = (file: any, name: string) => (_e?: SyntheticEvent) => {
    //     try {
    //         let fileURL = `${window.location.origin}/${file}.pdf`;
    //         setModalData({fileURL: fileURL, name});
    //     } catch (error) {}
    // };

    // const closePDFModal = () => {
    //     setModalData(null);
    // };

    // const manualVue = () => {
    //     if (isAdministrator) return 'admin-manual'
    //     if (isModerator) return 'moderator-manual'
    //     return 'user-manual'
    // }

    return (
        <Drawer
            className={styles.drawer}
            width={360}
            visible={isProfileActive}
            closable={true}
            onClose={onClose}
        >
            <div className={styles.body}>
                { (user && user.personalData) && (
                    <div className={styles.item}>
                        { (user.personalData.lastName) && (
                            <span className={styles.name}>
                                {user?.personalData.lastName}
                            </span>
                        ) }
                        { (user.personalData.firstName) && (
                            <span className={styles.name}>
                                {user?.personalData.firstName}
                            </span>
                        ) }
                        { (user.personalData.middleName) && (
                            <span className={styles.name}>
                                {user?.personalData.middleName}
                            </span>
                        ) }


                        { (user.personalData.position) && (
                            <span className={styles.position}>
                                {user?.personalData.position}
                            </span>
                        ) }
                    </div>
                ) }

                { (user && user.username) && (
                    <div className={styles.item}>
                        <span className={styles.title}>
                            Логин
                        </span>
                        <span className={styles.info}>
                            { user.username }
                        </span>
                    </div>
                ) }

                { (user && user.personalData && user.personalData.email) && (
                    <div className={styles.item}>
                        <span className={styles.title}>
                            E-mail
                        </span>
                        <span className={styles.info}>
                            { user.personalData.email }
                        </span>
                    </div>
                ) }

                { (user && user.personalData && user.personalData.organization) && (
                    <div className={styles.item}>
                        <span className={styles.title}>
                            Организация
                        </span>
                        <span className={styles.info}>
                            { user.personalData.organization }
                        </span>
                    </div>
                ) }

                { (user && user.personalData && user.status) && (
                    <div className={styles.item}>
                        <span className={styles.title}>
                            Должность
                        </span>
                        <span className={styles.info}>
                            { user.status }
                        </span>
                    </div>
                ) }

                <Tooltip title={'После обновления необходимо обновить страницу'}>
                    <Button className={styles.btn} onClick={() => {
                        localStorage.removeItem('color')
                    }}>
                        Обновить цвет номинаций
                    </Button>
                </Tooltip>


                {/*<Button className={styles.btn} onClick={openPDFModal(manualVue(), 'Инструкция')}>*/}
                {/*    Инструкция*/}
                {/*</Button>*/}

                {/*<Button className={`${styles.btn} ${styles.linkBtn}`}>*/}
                {/*    <a download href={`${window.location.origin}/${manualVue()}.pdf`}>Скачать инструкцию</a>*/}
                {/*</Button>*/}

            </div>
            {/*<PDFModal*/}
            {/*    fileURL={modalData?.fileURL as string}*/}
            {/*    name={modalData?.name}*/}
            {/*    isOpened={Boolean(modalData)}*/}
            {/*    onClose={closePDFModal}*/}
            {/*/>*/}
        </Drawer>
    );
};
