import React from 'react';
import cn from 'classnames';

import styles from 'src/components/data-table/status-cell/styles.module.scss';
import {TooltipComponent} from "src/components/tooltip";

export const statusVoteCell = (time?: boolean) => (status?: boolean | string) => {
    const active: boolean = (typeof status === 'boolean' && status)
        || (typeof status === 'string' && status === 'VOTED');

    const message = () => {
        if ((typeof time !== 'undefined') && !time) return 'Голосование закончено'
        if (status) return 'Все участники голосования проголосовали'
        return 'Идет голосование'
    }

    return (
        <div className={styles.cell}>
            <TooltipComponent
                isActive={true}
                message={message()}
            >
                <div className={cn(styles.bullet, { [styles.active]: active })} />
            </TooltipComponent>
        </div>
    );
}
