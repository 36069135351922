import {put} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';

import {
    AddProjectErrorAction,
    AddProjectStartAction,
    AddProjectSuccessAction,
    GetProjectsStartAction,
} from 'src/store/projects/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* addProjectWorker({ project }: ReturnType<typeof AddProjectStartAction>) {
    try {
        const formData = new FormData();

        let projectData: any = {
            "budget": +project.budget,
            // "categoryId": +project.categoryId,
            "nominationId": +project.nominationId,
            "description": project.description,
            "expertise": project.expertise,
            "linksAwards": project.linksAwards,
            "nameNominees": project.nameNominees,
            "formatDescription": project.formatDescription,
            "formatIds": project.formatIds,
            "kpi": +project.kpi,
            "name": project.name,
            "owner": project.owner,
            "presentationUrl": project.presentationUrl,
            "score": project.score,
            "sessionId": +project.sessionId,
            "teamExperience": project.teamExperience,
            "year": +project.year,
            "internetResourcesIds": project.internetResources,
            "implEndDate": project.implEndDate,
            "implStartDate": project.implStartDate,
            "implTerms": project.implTerms,
            "requestNumber": null,
            "recommendedCostReduction": project.recommendedCostReduction,
            "achievements": project.achievements,
            "ownProjectSite": project.ownProjectSite,
            "cofinancing": project.cofinancing,
            "supportAmount": project.supportAmount,
            "actualKpi": project.actualKpi,
        };

        formData.append('project', JSON.stringify(projectData));

        formData.append(
            'image',
            project.image.length > 0
                ? project.image[0].originFileObj as Blob
                : new Blob(undefined)
        );

        project.otherFiles.forEach((file) => {
            formData.append('otherFiles', file.originFileObj as Blob);
        });
        project.presentationFile.forEach((file) => {
            formData.append('presentation', file.originFileObj as Blob);
        });
        project.requestFiles.forEach((file) => {
            formData.append('requestFiles', file.originFileObj as Blob);
        });
        project.examinationFiles.forEach((file) => {
            formData.append('examinationFiles', file.originFileObj as Blob);
        });
        project.requestFiles.forEach((file) => {
            formData.append('requestFiles', file.originFileObj as Blob);
        });

        if (project.otherFiles.length === 0) {
            formData.append('otherFiles', new Blob([]),);
        }
        if (project.presentationFile.length === 0) {
            formData.append('presentation', new Blob([]),);
        }
        if (project.examinationFiles.length === 0) {
            formData.append('examinationFiles', new Blob([]),);
        }
        if (project.requestFiles.length === 0) {
            formData.append('requestFiles', new Blob([]),);
        }

        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: '/projects',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        });

        yield put(AddProjectSuccessAction(responseData));
        yield put(GetProjectsStartAction());
        yield put(LogsActionsStartAction('CREATE_PROJECT'));
    } catch (error) {
        yield put(AddProjectErrorAction(error));
    }
}
