import axios from "axios";
import {NewNomination, NominationSearch, RequestData} from "./types";

export const search = async (requestData: RequestData) => {
    requestData.page = requestData.page - 1;
    const {data} = await axios.post<NominationSearch>('/projects/nominations/search', requestData)
    data.page = data.page + 1;
    return data;
}
export const deleteNomination = async (id: number) => {
    await axios.delete(`/projects/nominations/${id}`)
}
export const createNomination = async (data: NewNomination) => {
    await axios.post('/projects/nominations', data)
}
export const updateNomination = async (data: NewNomination, id: number) => {
    await axios.put(`/projects/nominations/${id}`, data)
}