import React, {ChangeEvent, SyntheticEvent, useEffect, useMemo, useState} from 'react';
import styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {GetNominationStartAction, SetNominationAction} from "./model/actions";
import {Nomination} from "./types";
import {selectNominations, selectNominationsStatus, selectFilter} from "./model/selectors";
import {Button, Input, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {Pagination} from "src/shared/lib/pagination";
import {Key, TablePaginationConfig} from "antd/lib/table/interface";
import {useDebounce} from "src/shared/lib/useDebounce";
import {ActionsCell} from "src/components/data-table/actions-cell";
import {deleteNomination} from "src/features/settings/nominations-directory/api";
import {ConfirmModal} from "src/components/modals/confirm-modal";
import ModalAddNomination from "src/features/settings/nominations-directory/ui/modal-add-nomination";

type State = {
    isOpenAddOrEdit: boolean,
    isOpenDelete: boolean,
    id: null | number
    data: null | Nomination
}
const NominationsDirectory = () => {
    const put = useDispatch();
    const data = useSelector(selectNominations)
    const filters = useSelector(selectFilter)
    const {isLoading} = useSelector(selectNominationsStatus)
    const [modal, setModal] = useState<State>({
        isOpenAddOrEdit: false,
        isOpenDelete: false,
        id: null,
        data: null
    })

    const [searchString, setSearchString] = useState<string>(filters.filterData.searchString || '')
    const debouncedValue = useDebounce<string>(searchString, 1000)

    useEffect(() => {
        put(SetNominationAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: debouncedValue,
            },
            page: 1,
        }));
    }, [debouncedValue])

    useEffect(() => {
        put(GetNominationStartAction())
    }, [])

    const handleTableChange = (pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,) => {
        let newPagination = {};
        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
                totalCount: pagination.total || 0,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }
        put(SetNominationAction({
            ...filters,
            ...newPagination,
            ...newSorter,
        }));
    }

    const onEdit = (id: number, record: Nomination) => (_e: SyntheticEvent) => {
        setModal((prevState) => ({...prevState, isOpenAddOrEdit: true, data: record}))
    }
    const onDelete = (id: number, _record: Nomination) => (_e: SyntheticEvent) => {
        setModal((prevState) => ({...prevState, isOpenDelete: true, id}))
    }
    const columns: ColumnsType<Nomination> = useMemo(() => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                sorter: true
            },
            {
                title: 'Порядок',
                dataIndex: 'order',
                key: 'order',
                width: 30,
                sorter: true
            },
            {
                title: 'Скрытый',
                dataIndex: 'hidden',
                key: 'hidden',
                render: (_, {hidden}) => hidden ? 'Да' : 'Нет',
                width: 20,
                sorter: true
            },
            {
                title: '',
                width: 200,
                dataIndex: 'id',
                key: 'id',
                render: ActionsCell(onEdit, onDelete),
            },
        ]
    }, [data]);

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    const openAdd = () => {
        setModal((prevState) => ({...prevState, isOpenAddOrEdit: true}))
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.searchBox}>
                    <Input
                        className={styles.search}
                        placeholder="Введите наименование номинации"
                        onChange={handleChangeSearch}
                        value={searchString}
                    />
                    {/*<Button*/}
                    {/*    type={'default'}*/}
                    {/*    className={styles.openSidebar}*/}
                    {/*>*/}
                    {/*    <FiltersIcon/> Фильтры*/}
                    {/*</Button>*/}
                    <div>
                        <Tooltip title={'Добавить номинацию'}>
                            <Button
                                type="default"
                                shape="circle"
                                className={styles.add}
                                onClick={openAdd}
                            >+</Button>
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.searchCount}>Найдено: {filters.totalCount}</div>
            </div>
            <div>
                <Table
                    loading={isLoading}
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        current: filters.page,
                        pageSize: filters.pageSize,
                        total: filters.totalCount,
                        position: ['bottomCenter'],
                        showSizeChanger: true,
                        pageSizeOptions: Pagination()
                    }}
                    onChange={handleTableChange}
                    rowKey="id"
                />
            </div>
            <ConfirmModal
                isOpened={modal.isOpenDelete}
                message={'Удалить номинацию?'}
                onClose={() => {
                    setModal({...modal, isOpenDelete: false, id: null})
                }}
                onSubmit={() => {
                    if (modal.id) {
                        deleteNomination(modal.id).then(() => {
                            put(GetNominationStartAction())
                            setModal({...modal, isOpenDelete: false, id: null})
                        })
                    }

                }}
            />
            <ModalAddNomination
                isEdit={!!modal.data}
                data={modal.data}
                isOpened={modal.isOpenAddOrEdit}
                onClose={() => {
                    setModal({...modal, isOpenAddOrEdit: false, data: null})
                }}
            />
        </div>
    )
};

export default NominationsDirectory;