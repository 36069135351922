import React, {FC, useMemo, useState} from 'react';

import styles from './styles.module.scss';

type TProps = {
    text: string;
    showShort?: boolean
}
export const MultilineText: FC<TProps> = ({text, showShort}) => {
    const [isShort, setIsShort] = useState(!!showShort)
    const [formattedText, formattedTextShort] = useMemo(() => {
        const value = text
            .replaceAll('\\t', "\u00a0")
            .replaceAll('\\n', '<br />')
            .replaceAll('\\r', '<br />');
        return [value, value.slice(0, 500) + '...']
    }, [text]);

    function returnText() {
        if (isShort) {
            if (formattedText.length <= 500) return formattedText
            else return formattedTextShort
        } else return formattedText
    }

    return (
        <>
            <p
                className={styles.lineBreak}
                dangerouslySetInnerHTML={{
                    __html: returnText(),
                }}
            />
            {typeof showShort === 'boolean' && formattedText.length >= 500 && (
                <div className={styles.btn} onClick={() => setIsShort(!isShort)}>{isShort ? 'Развернуть' : 'Свернуть'}</div>)
            }

        </>
    );
};
