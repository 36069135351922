import { put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
    GetWavesTriggerAction,
    GetWavesStartAction,
    GetWavesSuccessAction,
    GetWavesErrorAction,
} from 'src/store/waves/actions';

export function* wavesWorker(action: ReturnType<typeof GetWavesTriggerAction>) {
    try {
        const { year } = action;
        console.log('year', year)

        yield put(GetWavesStartAction());
        const { data }: AxiosResponse<string[]> = yield axios({
            method: 'GET',
            url: `/sessions/${year}/names`,
            headers: {},
        });

        yield put(GetWavesSuccessAction(data));
    } catch (error) {
        yield put(GetWavesErrorAction(error));
    }
}
