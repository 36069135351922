import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import qs from 'query-string';
import cn from 'classnames';
import {Collapse, Empty, Tabs} from 'antd';

import {TProjectContent} from 'src/types/projects';

import {GetRealizationLinksStartAction, GetRealizationProjectStartAction} from 'src/store/realization/actions';
import {ToggleSidebarAction} from 'src/store/general/actions';

import {selectIsModerator} from 'src/store/auth/selectors';
import {selectRealizationLinksLength, selectRealizationProject} from 'src/store/realization/selectors';
import {selectFile} from 'src/store/files/selectiors';
import {selectIsSidebarActive} from 'src/store/general/selectors';

import {mainModuleRoutes} from 'src/routing/projects-module';
import {ToParentPage} from 'src/components/to-parent-page';
import {ErrorBoundary} from 'src/components/error-boundary';
import {RealizationLinks} from 'src/components/realization-links';
import {LinksSidebar} from 'src/components/links-sidebar';
import CartProject from 'src/components/cart-ptoject';
import ItemContent from 'src/features/realization-detailed-page/item-content';

import styles from './styles.module.scss';
import {GetFileStartAction} from "src/store/files/actions";

const { Panel } = Collapse;

const RealizationDetailedPage = () => {
    const { pathname, search } = useLocation();
    const id = pathname.split('/')[2];
    const put = useDispatch();

    const sidebarState = useSelector(selectIsSidebarActive);
    const isModerator = useSelector(selectIsModerator);
    const data: TProjectContent = useSelector(selectRealizationProject(id));
    const links = useSelector(selectRealizationLinksLength);
    // @ts-ignore
    const presentationFile = useSelector(selectFile(
        (data && data.presentationFile) ? `${data?.presentationFile?.id}` : undefined
    ));

    const [tabs, setTabs] = useState<string>('1');
    const isSidebarActive = sidebarState && tabs === '3';

    useEffect(() => {
        if (!presentationFile && data.presentationFile) put(GetFileStartAction(`${data.presentationFile?.id}`))
    }, [id]);

    useEffect(() => {
        if (data.id) put(GetRealizationLinksStartAction(data.id));
    }, [data.id]);

    useEffect(() => {
        const tabIndex = tabs ? tabs : 1;
        put(push({
            pathname,
            search: `?tab=${tabIndex}`
        }));
    }, [tabs]);

    useEffect(() => {
        const { tab } = qs.parse(search);
        if (tab && typeof tab === 'string') setTabs(tab);
        if (id) put(GetRealizationProjectStartAction(id));
        if (!presentationFile && data.presentationFile) put(GetFileStartAction(`${data.presentationFile?.id}`))
    }, []);

    const handleChangeTabs = (e: string) => {
        setTabs(e)
        if (e === '3' && !presentationFile && data.presentationFile) put(GetFileStartAction(`${data.presentationFile?.id}`))
    };

    const toggleSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleSidebarAction(value));
    });


    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={mainModuleRoutes.realization.path} />
            </div>
            <ErrorBoundary>
                <div className={cn(styles.contentColumn, { [styles.contentColumnCollapsed]: isSidebarActive })}>
                    <Tabs defaultActiveKey={search ? `${search.slice(5)}` : '1'} activeKey={tabs} onChange={handleChangeTabs}>
                        <Tabs.TabPane tab="Карточка проекта" key="1">
                            <CartProject data={data} id={id} type={"image"}/>
                        </Tabs.TabPane>

                        {/*https://iricenter.atlassian.net/browse/PNS-430*/}
                        {/*{ (data && data.presentation && data?.presentation?.url) && (*/}
                        {/*    <Tabs.TabPane tab="Видеопрезентация" key="2">*/}
                        {/*        <CartProject data={data} id={id} type={"video"}/>*/}
                        {/*    </Tabs.TabPane>*/}
                        {/*) }*/}

                        {/*{ (data && data.presentationFile && data?.presentationFile?.id) && (*/}
                        {/*    <Tabs.TabPane tab="Презентация" key="3">*/}
                        {/*        <CartProject data={data} id={id} type={"presentation"} />*/}
                        {/*    </Tabs.TabPane>*/}
                        {/*) }*/}

                        {(data && ((data.photoContents || data.videoContents || data.audioContents) || isModerator)) && (links === 0) && (
                            <Tabs.TabPane tab="Контент" key="2">
                                {/*{isModerator && (*/}
                                {/*    <div className={styles.nav}>*/}
                                {/*        <h3 className={styles.title}>Добавить контент</h3>*/}
                                {/*        <Button*/}
                                {/*            type="default"*/}
                                {/*            shape="circle"*/}
                                {/*            className={styles.add}*/}
                                {/*            onClick={() => {*/}
                                {/*                history.push(`/realization/${id}/create`)*/}
                                {/*            }}*/}
                                {/*        >+</Button>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {data && (!data.videoContents && !data.audioContents && !data.photoContents && !data.textContents) && (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )}

                                <Collapse defaultActiveKey={['1']}>
                                    {data && data.videoContents && (
                                        <Panel header='Видеоконтент' key='1'>
                                            {tabs === '2' ? (
                                                <ItemContent type={'video'} data={data.videoContents}/>
                                            ) : null}
                                        </Panel>
                                    )}
                                    {data && data.audioContents && (
                                        <Panel header='Аудиоконтент' key='2'>
                                            {tabs === '2' ? (
                                                <ItemContent type={"audio"} data={data.audioContents}/>
                                            ) : null}
                                        </Panel>
                                    )}
                                    {data && data.photoContents && (
                                        <Panel header='Фотоконтент' key='3'>
                                            {tabs === '2' ? (
                                                <ItemContent type={"image"} data={data.photoContents}/>
                                            ) : null}
                                        </Panel>
                                    )}
                                    {data && data.textContents && (
                                        <Panel header='Текстовый контент' key='4'>
                                            {tabs === '2' ? (
                                                <ItemContent type={"text"} data={data.textContents}/>
                                            ) : null}
                                        </Panel>
                                    )}
                                </Collapse>

                            </Tabs.TabPane>
                        )}

                        <Tabs.TabPane tab="Ссылки на контент" key="3">
                            <RealizationLinks projectId={+id} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>

                <div className={cn(styles.rightColumn, { [styles.rightColumnClosed]: !isSidebarActive })}>
                    <LinksSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={toggleSidebar(false)}
                        projectId={+id}
                    />
                </div>



            </ErrorBoundary>
        </div>
    )
}
export default RealizationDetailedPage