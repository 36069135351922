import React, {FC, SyntheticEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {Button, Checkbox, Select} from 'antd';

import {LeftArrowIcon} from 'src/assets/svg/left-arrow';

import {
    GetProjectsStartAction,
    ResetProjectsFiltersAction,
    SetProjectsFiltersAction,
} from 'src/store/projects/actions';
import {selectCategories} from 'src/store/categories/selectors';
import {selectUsersProjectsFilters} from 'src/store/projects/selectors';
import {selectYears, selectYearsStatus} from 'src/store/years/selectors';
import {selectWaves, selectWavesStatus} from 'src/store/waves/selectors';

import styles from './styles.module.scss';
import {useLocation} from 'react-router-dom';
import {LogsActionsStartAction} from "src/store/logs/actions";
import {GetYearsTriggerAction} from "src/store/years/actions";
import {GetWavesTriggerAction} from "src/store/waves/actions";

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
    isAdmin?: boolean;
};

const { Option } = Select;

// const actions = [
//     {id: 1, name: 'Неотсортированные', value: 'notSelected'},
//     {id: 2, name: 'Доступны для Конкурсного комитета', value: 'userAvailable'},
//     {id: 3, name: 'Находятся на реализации', value: 'implemented'},
//     {id: 4, name: 'Доступны для членов Набсовета', value: 'unloaded'},
// ];

export const ProjectsListSidebar: FC<TProps> = ({
    closeSidebar,
    isAdmin,
}) => {
    const put = useDispatch();
    const { isLoading: yearsIsLoading } = useSelector(selectYearsStatus);
    const { isLoading: wavesIsLoading } = useSelector(selectWavesStatus);

    const filters = useSelector(selectUsersProjectsFilters);
    const nominations = useSelector(selectCategories);
    const years = useSelector(selectYears);
    const waves = useSelector(selectWaves);

    const { pathname } = useLocation();
    const votingId = +pathname.split('/')[2];
    const {year, sessionName } = filters.filterData;

    const handleReset = () => {
        put(ResetProjectsFiltersAction());
        put(GetYearsTriggerAction(true))
        put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
    };

    const onChangeYear = (year: string) => {
        put(SetProjectsFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                year,
                sessionName: undefined
            },
            page: 1,
        }));
        put(GetWavesTriggerAction(year))
        put(GetProjectsStartAction({
            isYearChanged: true,
            votingId: isAdmin ? votingId : undefined,
        }));
    };

    const onChangeWave = (value: string) => {
        put(SetProjectsFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                sessionName: value,
            },
            page: 1,
        }));
        put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
        put(LogsActionsStartAction('SEARCH_PROJECT'));
    };

    // const calculateMinMax = (value: number, name: 'minScore' | 'maxScore'): number => {
    //     let result = value;
    //
    //     try {
    //         result = +(value.toFixed(2));
    //     } catch (e) {}
    //
    //     if (name === 'minScore') {
    //         if (result < 0) result = 0;
    //         if (result > maxScore) result = maxScore - 1;
    //
    //         return result;
    //     }
    //
    //     if (result > 10) result = 10;
    //     if (result < minScore) result = minScore + 1;
    //
    //     return result;
    // };

    // const handleChangeScoreInputs = (name: 'minScore' | 'maxScore') => (value: any) => {
    //     put(SetProjectsFiltersAction({
    //         ...filters,
    //         filterData: {
    //             ...filters.filterData,
    //             [name]: calculateMinMax(value, name),
    //         },
    //         page: 1,
    //     }));
    //     put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
    // };

    // const onChangeSlider = (value: number[]) => {
    //     const [minScore, maxScore] = value;
    //
    //     put(SetProjectsFiltersAction({
    //         ...filters,
    //         filterData: {
    //             ...filters.filterData,
    //             minScore,
    //             maxScore,
    //         },
    //         page: 1,
    //     }));
    //     put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
    // };


    const onChangeCheckbox = (nominationId: number) => (_value: any) => {
        const isChecked = filters.filterData.nominationIds?.includes(nominationId);
        let nominations: number[] = [ ...filters.filterData.nominationIds ];

        if (isChecked) {
            const index = nominations.indexOf(nominationId);

            if (index !== -1) {
                nominations.splice(index, 1);
            }
        } else {
            nominations.push(nominationId);
        }

        put(SetProjectsFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                nominationIds: nominations,
            },
            page: 1,
        }));
        put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
    }

    // const onChangeCheckboxActions = (actionId: number) => (value: any) => {
    //     console.log('actionId', actionId)
    //     console.log('value', value)
    //     const action = actions.find(el => el.id === actionId);
    //     // @ts-ignore
    //     const target = filters.filterData[action.value];
    //     // @ts-ignore
    //     switch (action.value) {
    //         case 'notSelected':
    //             put(SetProjectsFiltersAction({
    //                 ...filters, filterData: {...filters.filterData, notSelected: !target}, page: 1,
    //             }));
    //             break;
    //         case 'implemented':
    //             put(SetProjectsFiltersAction({
    //                 ...filters, filterData: {...filters.filterData, implemented: !target}, page: 1,
    //             }));
    //             break;
    //         case 'userAvailable':
    //             put(SetProjectsFiltersAction({
    //                 ...filters, filterData: {...filters.filterData, userAvailable: !target}, page: 1,
    //             }));
    //             break;
    //         case 'unloaded':
    //             put(SetProjectsFiltersAction({
    //                 ...filters, filterData: {...filters.filterData, unloaded: !target}, page: 1,
    //             }));
    //             break;
    //         default: return
    //     }
    //     put(GetProjectsStartAction({ votingId: isAdmin ? votingId : undefined }));
    // }


    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon} />
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Год проекта</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите год"
                        optionFilterProp="children"
                        onChange={onChangeYear}
                        loading={yearsIsLoading}
                        value={year}
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                        { years.map((value) => (
                            <Option key={value} value={value}>{value}</Option>
                        )) }
                    </Select>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.heading}>Отбор</div>
                <div className={styles.content}>
                    <Select
                        className={styles.search}
                        allowClear={true}
                        placeholder="Выберите отбор"
                        optionFilterProp="children"
                        onChange={onChangeWave}
                        loading={yearsIsLoading || wavesIsLoading}
                        value={sessionName}
                        getPopupContainer={(trigger) => trigger.parentNode}
                    >
                        { waves.map((value) => (
                            <Option
                                key={value}
                                selected={value === sessionName}
                                value={value}
                            >{value}</Option>
                        )) }
                    </Select>
                </div>
            </div>

            {/*<div className={styles.section}>*/}
            {/*    <div className={styles.heading}>Балл</div>*/}
            {/*    <div className={styles.content}>*/}
            {/*        <div className={styles.range}>*/}
            {/*            <InputNumber*/}
            {/*                className={styles.rangeInput}*/}
            {/*                placeholder="0"*/}
            {/*                name="minScore"*/}
            {/*                min={0}*/}
            {/*                max={9}*/}
            {/*                value={minScore}*/}
            {/*                onChange={handleChangeScoreInputs('minScore')}*/}
            {/*            />*/}
            {/*            <span>-</span>*/}
            {/*            <InputNumber*/}
            {/*                className={styles.rangeInput}*/}
            {/*                placeholder="10"*/}
            {/*                name="maxScore"*/}
            {/*                min={1}*/}
            {/*                max={10}*/}
            {/*                value={maxScore}*/}
            {/*                onChange={handleChangeScoreInputs('maxScore')}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        <Slider*/}
            {/*            className={styles.slider}*/}
            {/*            range*/}
            {/*            min={0}*/}
            {/*            max={10}*/}
            {/*            defaultValue={[minScore, maxScore]}*/}
            {/*            value={[minScore, maxScore]}*/}
            {/*            onChange={onChangeSlider}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={styles.section}>
                <div className={styles.heading}>Номинация</div>
                <div className={cn(styles.content, styles.checkboxes)}>
                    { nominations.map(nomination => (
                        <Checkbox
                            key={nomination.id}
                            className={styles.checkbox}
                            checked={filters.filterData.nominationIds?.includes(nomination.id)}
                            onChange={onChangeCheckbox(nomination.id)}
                        >{nomination.name}</Checkbox>
                    ))}
                </div>
            </div>

            {/*{isModerator && (*/}
            {/*    <div className={styles.section}>*/}
            {/*        <div className={styles.heading}>Вид действия</div>*/}
            {/*        <div className={cn(styles.content, styles.checkboxes)}>*/}
            {/*            { actions.map(action => (*/}
            {/*                <Checkbox*/}
            {/*                    key={action.id}*/}
            {/*                    className={styles.checkbox}*/}
            {/*                    // @ts-ignore*/}
            {/*                    checked={filters.filterData[action.value]}*/}
            {/*                    onChange={onChangeCheckboxActions(action.id)}*/}
            {/*                >{action.name}</Checkbox>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset} className={styles.btn}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
