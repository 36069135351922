import React from "react";
import CreateUserModal from "src/components/modals/user-modal/create-user";
import EditUserModal from "src/components/modals/user-modal/eddit-user";

export type UserModalProps = {
    isOpened: boolean,
    type: 'create' | 'edit',
    toggleModal: any,
    isEdit: boolean,
    data: {
        id: number
        password: string,
        personalData: {
            "email": string,
            "firstName": string,
            "image": string,
            "lastName": string,
            "middleName": string,
            "organization": string,
            "position": string
        },
        "roles": Array<'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_MODERATOR'>,
        "status": 'ACTIVE' | 'BLOCKED',
        "username": string
    }
}
export type formData = {
    password?: string,
    email: string,
    existingImage?: any
    firstName: string,
    lastName: string,
    middleName: string,
    organization: string,
    position: string,
    roles: Array<'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_MODERATOR'>,
    status: 'ACTIVE' | 'BLOCKED',
    username: string
    image: any
}

// @ts-ignore
const UserModal: React.FC<UserModalProps> = (props) => {

    const prop = {
        toggleModal: props.toggleModal,
        type: props.type,
        data: props.data
    }
    if (props.type === 'create') {
        return <CreateUserModal {...prop} />
    } else return <EditUserModal  {...prop}/>
}

export default UserModal;