import React, {ChangeEvent, SyntheticEvent, useEffect, useMemo, useState,} from 'react';
import cn from 'classnames';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {Button, Input, notification, Select, Table, Tabs, Tooltip} from 'antd';
import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';
import moment from 'moment';

import {TProjectsOptions} from 'src/types/projects';
import {TSession} from 'src/types/sessions';

import {
    DeleteProjectStartAction,
    GetProjectsStartAction,
    PutProjectsActionsStartAction,
    SetProjectsFiltersAction,
} from 'src/store/projects/actions';
import {ToggleSidebarAction} from 'src/store/general/actions';
import {DeleteSessionStartAction, GetSessionsStartAction, SetSessionsFiltersAction,} from 'src/store/sesssions/actions';
import {GetFormatsStartAction} from 'src/store/formats/actions';
import {LogsActionsStartAction} from "src/store/logs/actions";

import {
    selectActionsProjectsRejected,
    selectActionsProjectsRejectedStatus,
    selectUsersProjects,
    selectUsersProjectsFilters,
    selectUsersProjectsStatus
} from 'src/store/projects/selectors';
import {selectYearsStatus} from 'src/store/years/selectors';
import {selectWavesStatus} from 'src/store/waves/selectors';
import {selectIsSidebarActive} from 'src/store/general/selectors';
import {selectIsModerator, selectUserData} from 'src/store/auth/selectors';
import {selectSessions, selectSessionsFilters, selectSessionsStatus,} from 'src/store/sesssions/selectors';

import {statusCell} from 'src/components/data-table/status-cell';
import {categoryCell} from 'src/components/data-table/category-cell';
import {NameCell} from 'src/components/data-table/name-cell';
import {ActionsCell} from 'src/components/data-table/actions-cell';
import {ProjectsListSidebar} from 'src/components/projects-list-sidebar';
import {SessionModal, TSessionModalProps} from 'src/components/modals/session-modal';
import {ProjectModal} from 'src/components/modals/projects-modal';
import {ConfirmModal} from 'src/components/modals/confirm-modal';
import AlertComponent from "src/components/alerts";
import {ErrorBoundary} from "src/components/error-boundary";

import styles from './styles.module.scss';
import {RequestCell} from "src/components/data-table/request-cell";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import {GetYearsTriggerAction} from "src/store/years/actions";
import {useDebounce} from "src/shared/lib/useDebounce";
import axios from "axios";
import {FiltersIcon} from "src/shared/ui/icons";
import {Pagination} from "src/shared/lib/pagination";
import {ColumnsType} from "antd/es/table";

const { Option } = Select;

const massActions = [
    {value: 'display', name: 'Отобразить для участников голосования'},
    {value: 'hide', name: 'Скрыть для участников голосования'},
    // {value: 'mark', name: 'Отправить в ЛК Набсовет'},
    // {value: 'unmark', name: 'Убрать из ЛК Набсовет'},
    {value: 'delete', name: 'Удалить'}
];

export const ProjectsListPage = () => {
    const { pathname, search } = useLocation();
    const votingId = +pathname.split('/')[2];

    const put = useDispatch();

    const { isLoading: projectsIsLoading } = useSelector(selectUsersProjectsStatus);
    const { isLoading: yearsIsLoading } = useSelector(selectYearsStatus);
    const { isLoading: wavesIsLoading } = useSelector(selectWavesStatus);
    const { isLoading: sessionsIsLoading } = useSelector(selectSessionsStatus);
    const { isLoading: actionsProjectsIsLoading} = useSelector(selectActionsProjectsRejectedStatus);

    const data = useSelector(selectUsersProjects);
    const isSidebarActive = useSelector(selectIsSidebarActive);
    const filters: TProjectsOptions = useSelector(selectUsersProjectsFilters);
    const sessionsData = useSelector(selectSessions);
    const sessionsFilters = useSelector(selectSessionsFilters);
    const isModerator = useSelector(selectIsModerator);
    const actionsProjectsRejected = useSelector(selectActionsProjectsRejected);

    const userData = useSelector(selectUserData);
    const user = (userData && userData.user) ? userData.user : {};

    const [searchString, setSearchString] = useState<string>(filters.filterData.searchString || '')
    const debouncedValue = useDebounce<string>(searchString, 1000)

    const [sessionData, setSessionData] = useState<TSessionModalProps['data']>(undefined);
    const [projectData, setProjectData] = useState<any>(undefined);
    // const systemSetting = useSelector(selectSystemSettingSA_Ak);
    // const {isLoading: systemSettingIsLoading} = useSelector(selectSystemSettingStatus);
    //модалка для иморта
    // const [importData, setImportData] = useState<any>(undefined);
    const [deleteId, setDeleteId] = useState<number | undefined>();
    const [projectDeleteId, setProjectDeleteId] = useState<number | undefined>();

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [actions, setActions] = useState({
        rejected: [],
        selected: [],
        description: '',
        type: ''
    });

    const [tabs, setTabs] = useState<string>('1');

    useEffect(() => {
        if (!isModerator) {
            put(push('/vote?tab=1'));
        }
    }, );


    useEffect(() => {
        put(GetProjectsStartAction());
        put(GetFormatsStartAction());
        put(GetSessionsStartAction());
        put(GetYearsTriggerAction(true))
    }, []);

    useEffect(() => {
        put(SetProjectsFiltersAction({
            ...filters,
            filterData: {
                ...filters.filterData,
                searchString: debouncedValue,
            },
            page: 1,
        }));
        put(LogsActionsStartAction('USE_SEARCH_BAR'));
        put(GetProjectsStartAction());
    }, [debouncedValue])

    useEffect(() => {
        const tabIndex = tabs ? tabs : 1;
        put(push({
            pathname,
            search: `?tab=${tabIndex}`
        }));
    }, [tabs]);

    useEffect(() => {
        const { tab } = qs.parse(search);
        if (tab && typeof tab === 'string') setTabs(tab);
        put(LogsActionsStartAction('OPEN_SELECT_MODULE'));
    }, [])
    useEffect(() => {
        if (actionsProjectsRejected.length) {
            const selected = actions.selected;
            const rejected = actionsProjectsRejected;
            const description = `Нельзя обновить:${rejected.map(el => {
                const findEl = data.find(idx => idx.id === el )
                // @ts-ignore
                return `'${findEl.name}'`
            })}`;
            const filter = selected.filter((el) => rejected.indexOf(el) < 0);
            setActions({
                ...actions,
                rejected: [...actionsProjectsRejected],
                selected: [...filter],
                description: description
            })
        }
    }, [put, actionsProjectsRejected])

    const onCloseProjectModal = () => {
        setProjectData(undefined);
    };
    const onEditProject = (id: number, rowData: TSession) => (e: SyntheticEvent) => {
        e.stopPropagation();
        setProjectData(rowData);
    };
    const onDeleteProject = (id: number) => (e: SyntheticEvent) => {
        e.stopPropagation();
        setProjectDeleteId(id);
    };
    const closeDeleteProject = () => {
        setProjectDeleteId(undefined);
    };

    const deleteProject = () => {
        if (projectDeleteId) {
            put(DeleteProjectStartAction(+projectDeleteId));
            closeDeleteProject();
        }
    };

    const openAddSession = () => {
        setSessionData({});
    };
    const onCloseSessionModal = () => {
        setSessionData(undefined);
    };

    let columns = useMemo(() => {
        let result = [];
        if (isModerator) {
            result.push({
                title: 'Статус отбора',
                dataIndex: 'implemented',
                key: 'implemented',
                render: statusCell,
                width: 100,
            })
        }
        result.push(...[
            {
                title: 'Номер заявки',
                dataIndex: 'requestNumber',
                key: 'requestNumber',
                sorter: true,
                className: styles.nameCell,
                render: RequestCell,
            }, {
                title: 'Название проекта',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                className: styles.nameCell,
                render: NameCell,
            },{
                title: 'Организация',
                dataIndex: 'owner',
                key: 'owner',
                sorter: true,
                className: styles.nameCell,
            }, {
                title: 'Номинация',
                dataIndex: 'nomination',
                key: 'nomination',
                width: 200,
                render: categoryCell,
            },
        ])

        if (isModerator) {
            result.push({
                title: '',
                width: 200,
                dataIndex: 'id',
                key: 'id',
                // @ts-ignore
                render: ActionsCell(onEditProject, onDeleteProject),
            });
        }
        /**
         * {
         *  title: 'Бюджет проекта',
         *  dataIndex: 'budget',
         *  width: 200,
         *  key: 'budget',
         *  sorter: true,
         *  render: budgetCell,
         * },
         * {
        *   title: 'Экспертная оценка',
        *   width: 110,
        *   dataIndex: 'score',
        *   key: 'score',
        *   sorter: true,
        * }
        */
        return result;
    }, [isModerator]);

    const closeDeleteSession = () => {
        setDeleteId(undefined);
    };

    const deleteSession = () => {
        // @ts-ignore
        put(DeleteSessionStartAction(+deleteId));
        closeDeleteSession();
    };

    const onEditWave = (id: number, rowData: TSession) => (_e?: SyntheticEvent) => {
        setSessionData(rowData);
    };
    const onDeleteWave = (id: number) => (_e?: SyntheticEvent) => {
        setDeleteId(id);
    };

    //Открытие модалки для импорта
    // const openImportModal = (id: number, name: string, year: number) => {
    //     setImportData({id, name, year})
    // }
    // const closeImportModal = () => {
    //     setImportData(undefined)
    // }
    const onSelectChange = (selectedRowKeys: any) => {
        // @ts-ignore
        setSelectedRowKeys([...selectedRowKeys]);
        // @ts-ignore
        setActions({...actions, selected: [...selectedRowKeys]})
    };
    const onSelectList = (e: any) => {
        setActions({...actions, type: e, selected: [...selectedRowKeys]})
    }
    const closeAlert = () => {
        setActions({ ...actions, description: '', rejected: [] })
    }

    const submitAction = () => {
        if (!actions.type && !selectedRowKeys.length && !actions.selected.length) return
        switch (actions.type) {
            case 'display':
                // @ts-ignore
                put(PutProjectsActionsStartAction(actions.selected, 'display'))
                break
            case 'hide':
                // @ts-ignore
                put(PutProjectsActionsStartAction(actions.selected, 'hide'))
                break
            case 'mark':
                // @ts-ignore
                put(PutProjectsActionsStartAction(actions.selected, 'mark'))
                break
            case 'unmark':
                // @ts-ignore
                put(PutProjectsActionsStartAction(actions.selected, 'unmark'))
                break
            case 'delete':
                // @ts-ignore
                put(PutProjectsActionsStartAction(actions.selected, 'delete'))
                break
            default:
                return;
        }
    }
    const renderProjectsTable = () => {
        if (isModerator) {
            return (
                <Table
                    scroll={{ x: 1150 }}
                    sticky
                    rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    columns={columns}
                    dataSource={data}
                    loading={projectsIsLoading || yearsIsLoading || wavesIsLoading || actionsProjectsIsLoading}
                    pagination={{
                        current: filters.page,
                        pageSize: filters.pageSize,
                        total: filters.totalCount,
                        position: ['bottomCenter'],
                        showSizeChanger: true,
                        pageSizeOptions: Pagination()
                    }}
                    onChange={handleTableChange('projects')}
                    rowKey="id"
                    onRow={(record, _rowIndex) => {
                        return {
                            // TODO: resolve type error
                            onClick: (_event) => { handleRowClick(record.id as number) },
                        };
                    }}
                />
            )
        } else {
            return (
                <>
                    <Table
                        scroll={{ x: 1150 }}
                        sticky
                        columns={columns}
                        dataSource={data}
                        loading={projectsIsLoading || yearsIsLoading || wavesIsLoading || actionsProjectsIsLoading}
                        pagination={{
                            current: filters.page,
                            pageSize: filters.pageSize,
                            total: filters.totalCount,
                            position: ['bottomCenter'],
                            showSizeChanger: true,
                            pageSizeOptions: Pagination()
                        }}
                        onChange={handleTableChange('projects')}
                        rowKey="id"
                        onRow={(record, _rowIndex) => {
                            return {
                                // TODO: resolve type error
                                onClick: (_event) => { handleRowClick(record.id as number) },
                            };
                        }}
                    />
                </>
            )
        }
    };
    const onchangeStatusSession = (session: TSession) => async () => {
        try {
            await axios.put(`/sessions/${session.id}/hidden`, {}, {
                params: {
                    hidden: !session.hidden
                }
            })
            put(GetSessionsStartAction());
        } catch (error) {
            const errorMessage = axios.isAxiosError(error) ? error.response?.data.message : 'Не удалось изменить статус';
            notification['error']({
                message: 'Ошибка',
                description: (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                )
            });
        }
    }

    const sessionsColumns = useMemo(() => {
        let result: ColumnsType<TSession> = [{
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        }, {
            title: 'Год',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (date: string) => (moment(date).format('YYYY')),
            width: 80,
        }, {
            title: 'Начало отбора',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 120,
        }, {
            title: ' Конец отбора',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 120,
        },{
            title: 'Номинации',
            dataIndex: 'nomination',
            key: 'nomination',
            width: 220,
            render: (_, {nominations}) => {
                if (nominations.length === 0) return '-';
                return (
                    <ul style={{listStyle: "none", margin: 0, padding: 0, fontSize: '12px'}}>
                        {nominations.map((el) => (<li>{el.name}</li>))}
                    </ul>
                )
            }
        }, {
            title: ' Статус',
            dataIndex: 'hidden',
            key: 'hidden',
            width: 120,
            render: (value: boolean, session: TSession) => (
                <Button
                    onClick={onchangeStatusSession(session)}
                    type={'text'}
                    className={classNames(styles.session__btn, {'active': !value})}
                >
                    {!value ? 'Активна' : 'Скрыта'}
                </Button>
            ),
            sorter: true
        }];

        if (isModerator) {
            result.push({
                title: '',
                dataIndex: 'id',
                key: 'id',
                // @ts-ignore
                render: ActionsCell(onEditWave, onDeleteWave),
                width: 200,
            },);
        }

        // {
        //     title: 'Импорт заявок',
        //         dataIndex: 'import',
        //     key: 'import',
        //     render: ButtonCell(openImportModal),
        //     width: 80,
        // }

        return result;
    }, [isModerator]);


    const handleRowClick = (id: number) => {
        put(push(`/list/${id}`));
    };

    // TODO: refactor this
    const handleTableChange = (tableName: 'projects' | 'session') => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPagination = {};
        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
                totalCount: pagination.total || 0,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }

        if (tableName === 'session') {
            put(SetSessionsFiltersAction({
                ...sessionsFilters,
                ...newPagination,
            }));
            put(LogsActionsStartAction('SORT_SESSION'));
            return;
        }

        put(SetProjectsFiltersAction({
            ...filters,
            ...newPagination,
            ...newSorter,
        }));
        put(GetProjectsStartAction());
        put(LogsActionsStartAction('SORT_PROJECT'));
    };


    const tabsHandler = (e: string) => {
        setTabs(e)
        if (e === '1') put(put(LogsActionsStartAction('OPEN_PROJECT_TAB')))
        else put(put(LogsActionsStartAction('OPEN_SESSION_TAB')))
    };
    const openAddProject = () => {
        setProjectData({});
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    const onSearch = () => {
        put(GetProjectsStartAction({ votingId: isModerator ? votingId : undefined }));
    };
    const toggleSidebar = () => {
        if (!user) {
            put(ToggleSidebarAction(false));
            return;
        }
        put(ToggleSidebarAction(!isSidebarActive));
        put(GetProjectsStartAction());
        put(LogsActionsStartAction('OPEN_SEARCH_BAR'))
    };
    const closeSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleSidebarAction(value));
    });
    // const onSwitchChange = (checked: boolean, _event: React.MouseEvent<HTMLButtonElement>) => {
    //     put(UpdateSystemSettingAction(`${checked}`))
    // }

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={cn(styles.leftColumn, { [styles.isSidebarCollapsed]: !isSidebarActive })}>
                    {!!actions.rejected.length && (
                        <AlertComponent
                            type={'warning'}
                            message={'Возникла ошибка при обработке данных. Выбранные заявки участвуют в голосовании или используются в системе ЛК НабСовет. Применить действие только к тем полям которые можно обновить?'}
                            description={actions.description}
                            submitF={submitAction}
                            closeF={closeAlert}
                        />
                    )}
                    {tabs === '1' && (
                        <div className={styles.header}>
                            <div className={styles.searchContainer}>
                                <div className={styles.searchBox}>
                                    <Tooltip title={'Введите наименование проекта или участника отбора'}>
                                        <Input.Search
                                            className={styles.search}
                                            placeholder="Введите наименование проекта или участника отбора"
                                            onChange={handleChangeSearch}
                                            onSearch={onSearch}
                                            value={searchString}
                                        />
                                    </Tooltip>
                                    <Button
                                        type={'default'}
                                        onClick={toggleSidebar}
                                        className={styles.openSidebar}
                                    >
                                        <FiltersIcon/> Фильтры
                                    </Button>
                                </div>
                                <div className={styles.searchCount}>Найдено: {filters.totalCount}</div>
                            </div>
                            {isModerator && (
                                <Tooltip title={'Добавить проект'}>
                                    <Button
                                        type="default"
                                        shape="circle"
                                        className={styles.add}
                                        onClick={openAddProject}
                                    >+</Button>
                                </Tooltip>
                            )}
                            {isModerator && (
                                <div className={styles.masActions}>
                                    <div className={styles.masActionsSelect}>
                                        <span>Массовые действия</span>
                                        <Select defaultValue="" onChange={onSelectList}>
                                            {massActions.map(el => (
                                                <Option key={el.value} value={el.value}>{el.name}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <Button
                                        type="default"
                                        disabled={!actions.selected.length}
                                        onClick={submitAction}
                                    >Применить</Button>
                                </div>
                            )}
                            {/*{isModerator && (*/}
                            {/*    <Spin spinning={systemSettingIsLoading}>*/}
                            {/*        <div className={styles.checkboxItem}>*/}
                            {/*            <Switch onChange={onSwitchChange} checked={systemSetting}/>*/}
                            {/*            <div className={classNames(styles.checkboxItem__text)}>{systemSetting ? 'Включить' : 'Выключить'} отображение Суммы оказанной <br/>поддержки и Фактического КПЭ</div>*/}
                            {/*        </div>*/}
                            {/*    </Spin>*/}
                            {/*)}*/}
                        </div>
                    )}
                    { isModerator ? (
                        <Tabs onChange={tabsHandler} defaultActiveKey={search ? `${search.slice(5)}` : '1'} activeKey={tabs}>
                            <Tabs.TabPane tab="Проекты" key="1">
                                { renderProjectsTable() }
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Отбор" key="2">
                                <div className={styles.header}>
                                    <h3 className={styles.title}>Отбор</h3>
                                    <Button
                                        type="default"
                                        shape="circle"
                                        className={styles.add}
                                        onClick={openAddSession}
                                    >+</Button>
                                </div>

                                <Table
                                    scroll={{ x: 660 }}
                                    sticky
                                    columns={sessionsColumns}
                                    dataSource={sessionsData}
                                    loading={sessionsIsLoading}
                                    pagination={{
                                        current: sessionsFilters.page,
                                        pageSize: sessionsFilters.pageSize,
                                        total: sessionsFilters.totalCount,
                                        position: ['bottomCenter'],
                                        pageSizeOptions: Pagination()
                                    }}
                                    onChange={handleTableChange('session')}
                                    rowKey="id"
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (
                        <>
                            { renderProjectsTable() }
                        </>
                    ) }
                </div>
                <div className={cn(styles.rightColumn, { [styles.isCollapsed]: !isSidebarActive })}>
                    <ProjectsListSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={closeSidebar(false)}
                    />
                </div>

                <SessionModal
                    isOpened={Boolean(sessionData)}
                    data={sessionData}
                    onClose={onCloseSessionModal}
                />

                {Boolean(projectData) && (
                    <ProjectModal
                        isOpened={Boolean(projectData)}
                        data={projectData}
                        onClose={onCloseProjectModal}
                    />
                )}

                <ConfirmModal
                    isOpened={Boolean(typeof deleteId === 'number')}
                    onClose={closeDeleteSession}
                    onSubmit={deleteSession}
                    message={`Удалить эту сессию?`}
                />

                <ConfirmModal
                    isOpened={Boolean(typeof projectDeleteId === 'number')}
                    onClose={closeDeleteProject}
                    onSubmit={deleteProject}
                    message={`Удалить проект?`}
                />
                {/*{Boolean(importData) && (*/}
                {/*    <ImportModal*/}
                {/*        data={importData}*/}
                {/*        onClose={closeImportModal}*/}
                {/*    />*/}
                {/*)}*/}
            </div>
        </ErrorBoundary>
    );
};
