import React from 'react';
import styles from './style.module.scss';
import classNames from "classnames";
import { Link } from 'react-router-dom';
import {CrossIcon, LogoTextSvg } from 'src/shared/ui/icons';
import Menu from "src/components/menu";
import {useDispatch, useSelector} from "react-redux";
import {selectMenuInfo} from "src/store/general/selectors";
import {ToggleSidebarMenuAction} from "src/store/general/actions";
const Sidebar = () => {
    const put = useDispatch();

    const menuInfo = useSelector(selectMenuInfo);
    const { isSidebarOpen } = menuInfo;

    const onClick = () => {
        put(ToggleSidebarMenuAction())
    }

    return (
        <aside className={classNames(styles.sidebar, menuInfo)}>
            <div className={classNames(styles.sidebar__content, menuInfo)}>
                <div className={classNames(styles.sidebar__logo, menuInfo)}>
                    <Link to={'/'} className={classNames(styles.link)}>
                        <LogoTextSvg className={classNames(styles.logo)}/>
                        <span className={styles.text}>Личный кабинет для<br/>голосования</span>
                    </Link>
                    <div className={classNames(styles.sidebar__cross, menuInfo)} onClick={onClick}>
                        <CrossIcon />
                    </div>
                </div>
                <Menu isOpen={isSidebarOpen}/>
            </div>
        </aside>
    )
}

export default Sidebar;