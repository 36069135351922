import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import cn from 'classnames';
import {Table} from 'antd';

import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';

import {DeleteUserStartAction, GetUsersStartAction} from 'src/store/users/actions';
import {selectUsers, selectUsersStatus} from 'src/store/users/selectors';

import {NameCell} from 'src/components/data-table/name-cell';
import {ActionsCell} from 'src/components/data-table/actions-cell';
import {UserDataCell} from 'src/components/data-table/userData-cell';
import UserModal from 'src/components/modals/user-modal';
import {ConfirmModal} from 'src/components/modals/confirm-modal';

import {push} from "react-router-redux";
import styles from './styles.module.scss';
import {ErrorBoundary} from "src/components/error-boundary";
import {LogsActionsStartAction} from "src/store/logs/actions";
import {Pagination} from "src/shared/lib/pagination";


const UsersListPage = () => {
    const put = useDispatch();
    const [modal, setModal] = useState({
        isOpened: false,
        isEdit: false,
        data: '',
        type: 'create'
    })
    const [modalConfirm, setModalConfirm] = useState<any>({
        isOpened: false,
        id: null,
        username: '',
    });

    useEffect(() => {
        if (modalConfirm.isOpened) put(LogsActionsStartAction('OPEN_DELETE_USER'));
    }, [modalConfirm])

    const {isLoading} = useSelector(selectUsersStatus)
    const users = useSelector(selectUsers);

    useEffect(() => {
        put(GetUsersStartAction(null));
    }, [put]);

    //handlers

    const onEditProject = (id: number, rowData: any) => (e: SyntheticEvent) => {
        e.stopPropagation();
        setModal({...modal, type: 'edit', isOpened: true, data: rowData})
    };
    const onDeleteProject = (id: number, rowData: any) => (e: SyntheticEvent) => {
        e.stopPropagation();
        setModalConfirm({...modalConfirm, isOpened: true, id: id, username: rowData.username});
    };

    const toggleModal = (type: string = 'create') => {
        if (type === 'create') setModal({...modal, isOpened: !modal.isOpened, type: 'create', data: ''})
        else setModal({...modal, isOpened: !modal.isOpened, type: 'edit', data: ''})
    };

    const toggleModalConfirm = () => {
        setModalConfirm({...modalConfirm, isOpened: false, id: null})
    }
    const deleteUser = () => {
        setModalConfirm({...modalConfirm, isOpened: false, id: null, username: ''})
        put(DeleteUserStartAction(modalConfirm.id))
    };

    const handleRowClick = (id: number) => {
        //открытие карточки
        put(push(`/users/${id}`));
    };

    // TODO: refactor this
    const handleTableChange = (_tableName: string) => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPagination = {
            page: 0,
            pageSize: 20
        };
        let newSorter = {
            sort: {
                direction: 'ASC',
                field: 'status',
            },
        };

        if (pagination) {

            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';
            if (sorter.order === 'descend') {
                direction = 'DESC'
            }
            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }
        put(GetUsersStartAction({newPagination, newSorter}));
        put(LogsActionsStartAction('SORT_USER'))
    };

    //render
    let columns = [
        {
            title: 'Имя пользователя',
            dataIndex: 'username',
            key: 'username',
            sorter: true,
            width: '60%',
            className: styles.nameCell,
            render: UserDataCell,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            sorter: true,
            key: 'status',
            render: NameCell,
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            // @ts-ignore
            render: ActionsCell(onEditProject, onDeleteProject),
        },
    ];

    const renderProjectsTable = () => (
        <>
            <div className={styles.header}>
                <h3 className={styles.title}>Пользователи</h3>
                {/*<Button*/}
                {/*    type="default"*/}
                {/*    shape="circle"*/}
                {/*    className={styles.add}*/}
                {/*    onClick={() => toggleModal('create')}*/}
                {/*>+</Button>*/}
            </div>

            <Table
                columns={columns}
                dataSource={users.content}
                loading={isLoading}
                pagination={{
                    current: users.page + 1,
                    pageSize: users.pageSize,
                    total: users.totalCount,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    pageSizeOptions: Pagination()
                }}
                onChange={handleTableChange('users')}
                rowKey="id"
                onRow={(record, _rowIndex) => {
                    return {
                        // TODO: resolve type error
                        onClick: (_event) => {
                            handleRowClick(record.id as number)
                        },
                    };
                }}
            />
        </>
    );

    return (
        <div className={styles.container}>
            <div className={cn(styles.leftColumn)}>
                <ErrorBoundary>
                    {users && renderProjectsTable()}
                </ErrorBoundary>
            </div>
            {/*// @ts-ignore*/}
            {modal.isOpened && <UserModal {...modal} toggleModal={toggleModal}/>}
            {modalConfirm.isOpened && (
                <ConfirmModal
                    isOpened={modalConfirm.isOpened}
                    onClose={toggleModalConfirm}
                    onSubmit={deleteUser}
                    message={`Вы действительно хотите удалить пользователя '${modalConfirm.username}'`}/>
            )}
        </div>
    )
}

export default UsersListPage