import {put} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {TVotingOption} from 'src/types/vote';

import {
    GetVotingOptionsStartAction,
    GetVotingOptionsSuccessAction,
    GetVotingOptionsErrorAction,
} from 'src/store/voting/actions';

export function* getVotingOptionsWorker({voteOptionTypeId}: ReturnType<typeof GetVotingOptionsStartAction>) {
    try {
        const { data }: AxiosResponse<TVotingOption[]> = yield axios({
            method: 'GET',
            url: `/voting/votes/options`,
            headers: {},
            params: {voteOptionTypeId: voteOptionTypeId}
        });

        yield put(GetVotingOptionsSuccessAction(data));

    } catch (error) {
        yield put(GetVotingOptionsErrorAction(error));
    }
}
