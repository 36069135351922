import { call, put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { GetWavesTriggerAction } from 'src/store/waves/actions';
import {
    GetSessionsStartAction,
    AddSessionStartAction,
    AddSessionSuccessAction,
    AddSessionErrorAction,
} from 'src/store/sesssions/actions';
import { selectUsersProjectsFilters } from 'src/store/projects/selectors';

import { wavesWorker } from 'src/workers/waves-worker';

import { DateFormat } from 'src/constatnts/format';
import {LogsActionsStartAction} from "src/store/logs/actions";

export function* addSessionWorker({ data }: ReturnType<typeof AddSessionStartAction>) {
    try {
        const projectFilters = yield select(selectUsersProjectsFilters);

        const { file, ...formFields } = data;

        const session = {
            ...formFields,
            startDate: moment(data.startDate).format(DateFormat),
            endDate: moment(data.endDate).format(DateFormat),
            nominationIds: data.nominationIds?.map((el) => Number(el))
        };

        const formData = new FormData();

        formData.append('session', JSON.stringify(session));

        formData.append(
            'file',
            (file.length > 0) ? file[0].originFileObj as Blob : new Blob(undefined),
        );

        const { data: responseData }: AxiosResponse<any> = yield axios({
            method: 'POST',
            url: '/sessions',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        });

        yield put(AddSessionSuccessAction(responseData));
        yield put(GetSessionsStartAction());

        if (projectFilters.filterData.year) {
            yield call(wavesWorker, GetWavesTriggerAction(projectFilters.filterData.year));
        }
        yield put(LogsActionsStartAction('CREATE_SESSION'))
    } catch (error) {
        yield put(AddSessionErrorAction(error));
    }
}
