import React, { FC } from 'react';
import moment from 'moment';
import { SummaryDateFormat } from 'src/constatnts/format';
import { TVoteSummary } from 'src/types/vote';

type TProps = {
    voteSummaryData: Partial<TVoteSummary>;
    isAdmin?: boolean;
    isArchive?: boolean;
}

export const VoteSummaryTable: FC<TProps> = ({
    voteSummaryData,
    isAdmin,
    isArchive,
}) => {
    const familiarizationDateTime = () => {
        return voteSummaryData.familiarizationDateTime
            ? `${moment(voteSummaryData.familiarizationDateTime).format(SummaryDateFormat)} в ${moment(voteSummaryData.familiarizationDateTime).format('HH:mm')}`
            : '-';
    }
    const startDateTime = () => {
        return voteSummaryData.startDateTime
            ? `${moment(voteSummaryData.startDateTime).format(SummaryDateFormat)} в ${moment(voteSummaryData.startDateTime).format('HH:mm')}`
            : '-';
    }
    const endDateTimeTo = () => {
        return voteSummaryData.endDateTime
            ? `${moment(voteSummaryData.endDateTime).format(SummaryDateFormat)} в ${moment(voteSummaryData.endDateTime).format('HH:mm')}`
            : '-';
    }

    const voted: number = voteSummaryData.votedItemsCount ? voteSummaryData.votedItemsCount : 0

    if (isAdmin) {
        return (
            <tr>
                <th data-label='Дата ознакомления'>{familiarizationDateTime()}</th>
                <th data-label='Начало голосования'>{startDateTime()}</th>
                <th data-label='Окончание голосования'>{endDateTimeTo()}</th>
                { !isArchive && (
                    <th data-label='Проголосовали за все проекты'>{ voteSummaryData.votingItemCount
                        ? `${voted}/${voteSummaryData.votingItemCount}`
                        : ''}
                    </th>
                ) }

            </tr>
        )
    }

    return (
        <tr>
            <th data-label='Дата ознакомления'>{familiarizationDateTime()}</th>
            <th data-label='Начало голосования'>{startDateTime()}</th>
            <th data-label='Окончание голосования'>{endDateTimeTo()}</th>
            <th>{voteSummaryData.votingItemCount || '-'}</th>
            <th>{voteSummaryData.votedItemsCount || '-'}</th>
            { !isArchive && (
                <th data-label='Проголосовали за все проекты'>{(
                    voteSummaryData.votingItemCount && voteSummaryData.votedItemsCount
                ) ? voteSummaryData.votingItemCount - voteSummaryData.votedItemsCount : '-'}</th>
            ) }
        </tr>
    );
};
