import React, {SyntheticEvent, useEffect, useMemo, useState,} from 'react';
import {push} from 'connected-react-router';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Table, Tabs} from 'antd';
import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';

import {mainModuleRoutes} from 'src/routing/projects-module';

import {ToggleVoteFiltersAction} from 'src/store/general/actions';
import {
    GetDocumentListStartAction,
    GetDocumentsVotingIdTriggerAction,
    GetProjectsVotingIdTriggerAction,
    GetVotingProjectsStartAction,
    GetVotingTypesTriggerAction,
    SetDocumentListFilter,
    SetVoteFiltersAction,
} from 'src/store/voting/actions';

import {
    selectDocumentsVoteSummaryData,
    selectDocumentsVoteSummaryStatus,
    selectProjectsVoteSummaryData,
    selectProjectsVoteSummaryStatus,
    selectVoteDocumentsData,
    selectVoteDocumentsStatus,
    selectVoteFilters,
    selectVoteProjectsData,
    selectVoteProjectsStatus,
    selectVotingTypesData,
} from 'src/store/voting/selectors';
import {selectIsVoteFiltersActive} from 'src/store/general/selectors';

import {categoryCell} from 'src/components/data-table/category-cell';
import {NameCell} from 'src/components/data-table/name-cell';
import {VoteCell} from 'src/components/data-table/vote-cell';
import {VoteOptionCell} from 'src/components/data-table/vote-option-cell';
import {VoteStatusCell} from 'src/components/data-table/vote-status-cell';
import {VoteSidebar} from 'src/components/vote-sidebar';
import {VoteSummaryTable} from 'src/components/vote-summary-table';
import {TVoteModalProps, VoteModal} from 'src/components/modals/vote-modal';
import {CountdownComponent} from 'src/components/countdown';
import {ErrorBoundary} from 'src/components/error-boundary';

import styles from './styles.module.scss';
import {VariantAnswerCell} from "src/components/data-table/variantAnswer-cell";
import qs from "query-string";
import {useLocation} from "react-router-dom";
import moment from 'moment';
import {Pagination} from "src/shared/lib/pagination";
import {selectIsModerator} from "src/store/auth/selectors";
import Filter from "src/features/vote-page/filter";

export const VotePage = () => {
    const { pathname, search } = useLocation();
    const [voteModalData, setVoteModalData] = useState<TVoteModalProps['data'] | undefined>(undefined);
    const put = useDispatch();
    const isModerator = useSelector(selectIsModerator);
    const votingTypes = useSelector(selectVotingTypesData);
    const voteProjectsSummaryData = useSelector(selectProjectsVoteSummaryData);
    const voteProjectsSummaryStatus = useSelector(selectProjectsVoteSummaryStatus);
    const voteDocumentsSummaryData = useSelector(selectDocumentsVoteSummaryData);
    const voteDocumentsSummaryStatus = useSelector(selectDocumentsVoteSummaryStatus);
    const projectsData = useSelector(selectVoteProjectsData);
    const projectsStatus = useSelector(selectVoteProjectsStatus);
    const documentsData = useSelector(selectVoteDocumentsData);
    const documentsStatus = useSelector(selectVoteDocumentsStatus);
    const isSidebarActive = useSelector(selectIsVoteFiltersActive);
    const filters = useSelector(selectVoteFilters);

    const [tabs, setTabs] = useState<string>('1');
    const handleChangeTabs = (e: string) => {
        setTabs(e)
    };


    const openVoteModal = (type: 'project' | 'document') => (
        id: number,
        rowData: any,
    ) => {
        const voteDetails: TVoteModalProps['data'] = {
            id: +id,
            // @ts-ignore
            votingId: type === 'project' ? +voteProjectsSummaryData.id : +voteDocumentsSummaryData.id,
            name: rowData.name ? rowData.name : '',
            type,
            vote: rowData.votes && rowData.votes.length ? rowData.votes[0] : null,
        }

        setVoteModalData(voteDetails);
    };

    const closeVoteModal = () => {
        setVoteModalData(undefined);
    };

    let columns = useMemo(() => {
        let result = [
            {
                title: 'Статус',
                dataIndex: 'voteStatus',
                key: 'voteStatus',
                render: VoteStatusCell,
                width: 100,
            },
            {
                title: 'Название проекта',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                className: styles.nameCell,
                render: NameCell,
            },
            {
                title: 'Организация',
                dataIndex: 'owner',
                key: 'owner',
                className: styles.nameCell,
                render: (_: any, record: any) => NameCell(record?.owner, {}),
            },
            {
                title: 'Номинация',
                dataIndex: 'nomination',
                key: 'nomination',
                width: 220,
                render: categoryCell,
            },
            {
                title: 'Балл',
                dataIndex: 'score',
                key: 'score',
                sorter: true,
                width: 100,
            },
            {

                title: 'Результат голосования',
                dataIndex: 'option',
                key: 'option',
                render: VoteOptionCell,
                width: 180
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                render: (id: number, rowData: any) => VoteCell(openVoteModal('project'))(id, rowData),
                width: 150,
            }
        ];

        // const now = moment(new Date());
        // const end = moment(voteProjectsSummaryData.startDateTime);
        //
        // if (moment.duration(now.diff(end)).asMinutes() > 0) {
        //     result.push({
        //         title: '',
        //         dataIndex: 'id',
        //         key: 'id',
        //         // @ts-ignore
        //         render: VoteCell(openVoteModal('project')),
        //         width: 150,
        //     });
        // }

        return result;
    }, [voteProjectsSummaryData.startDateTime]);

    let documentsColumns = useMemo(() => {
        let result = [
            {
                title: 'Статус',
                dataIndex: 'voteStatus',
                key: 'voteStatus',
                render: VoteStatusCell,
                width: 100,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                width: '40%',
                className: styles.nameCell,
                render: NameCell,
            },
            {
                title: 'Вариант ответа',
                dataIndex: 'option',
                key: 'option',
                render: VariantAnswerCell,
                width: 180
            }
        ];

        const now = moment(new Date());
        console.log('voteDocumentsSummaryData.startDateTime', voteDocumentsSummaryData.startDateTime)
        const end = moment(voteDocumentsSummaryData.startDateTime);

        if (moment.duration(now.diff(end)).asMinutes() > 0) {
            result.push({
                title: '',
                dataIndex: 'id',
                key: 'id',
                // @ts-ignore
                render: VoteCell(openVoteModal('document')),
                width: 180
            });
        }

        return result;
    }, [voteDocumentsSummaryData.startDateTime]);

    const toggleSidebar = (value: boolean) => ((_event: SyntheticEvent) => {
        put(ToggleVoteFiltersAction(value));
    });

    const handleRowClick = (tableName: 'projects' | 'documents', record: any) => {
        const path = tableName === 'projects'
            ? mainModuleRoutes['vote-detailed'].path
            : mainModuleRoutes['vote-document'].path;
        put(push(
            path.replace(
                ':votingId',
                tableName === 'projects' ? `${voteProjectsSummaryData.id}` : `${voteDocumentsSummaryData.id}`
            ).replace(':projectId', `${record.id}`)
        ));
    };

    const handleTableChange = (tableName: 'projects' | 'documents') => (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        let newPagination = {};
        let newSorter = {
            sort: {
                field: 'id',
                direction: 'ASC',
            },
        };

        if (pagination) {
            newPagination = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 20,
                totalCount: pagination.total || 0,
            }
        }

        if (sorter && sorter.order) {
            let direction = 'ASC';

            if (sorter.order === 'descend') {
                direction = 'DESC'
            }

            newSorter = {
                sort: {
                    field: sorter.field,
                    direction,
                },
            }
        }

        const newFilters = {
            ...newPagination,
            ...newSorter,
        }
        tableName === 'documents' && put(SetDocumentListFilter(newFilters));
        if (tableName === 'projects') {
            let newFiltersProjects = {
                filterData: {
                    nominationIds: filters.filterData.nominationIds,
                    minScore: filters.filterData.minScore,
                    maxScore: filters.filterData.maxScore,
                    searchString: filters.filterData.searchString
                },
                page: pagination.current || filters.page,
                pageSize: pagination.pageSize || filters.pageSize,
                totalCount: pagination.total || filters.totalCount,
                ...newSorter
            }
            put(SetVoteFiltersAction(newFiltersProjects))
        }
        tableName === 'projects' ? put(GetVotingProjectsStartAction()) : put(GetDocumentListStartAction());
    };

    // initial load
    useEffect(() => {
        const { tab } = qs.parse(search);
        if (tab && typeof tab === 'string') {
            setTabs(tab);
        }
        put(GetVotingTypesTriggerAction());
        if (votingTypes.length > 0) {
            put(GetProjectsVotingIdTriggerAction());
            put(GetDocumentsVotingIdTriggerAction());
        }
    }, []);
    useEffect(() => {
        const tabIndex = tabs ? tabs : 1;
        put(push({
            pathname,
            search: `?tab=${tabIndex}`
        }));
    }, [tabs]);

    useEffect(() => {
        if (votingTypes.length > 0) {
            if (!voteProjectsSummaryData.id) put(GetProjectsVotingIdTriggerAction());
            if (!voteDocumentsSummaryData.id) put(GetDocumentsVotingIdTriggerAction());
        }
    }, [votingTypes]);

    const ProjectsTab = (
        <>
            <div className={styles.header}>
                <h3 className={styles.title}>Голосование</h3>
                <Filter/>
            </div>

            <CountdownComponent endTime={voteProjectsSummaryData.endDateTime || undefined} />

            <table className={styles.infoTable}>
                <thead>
                    <tr>
                        <th>Дата ознакомления</th>
                        <th>Начало голосования</th>
                        <th>Окончание голосования</th>
                        <th>Количество проектов</th>
                        <th>Проголосовано</th>
                        <th>Ожидает голосования</th>
                    </tr>
                </thead>

                <tbody>
                    <VoteSummaryTable voteSummaryData={voteProjectsSummaryData} />
                </tbody>
            </table>
 
            <Table
                scroll={{ x: 1150 }}
                sticky
                columns={columns}
                dataSource={projectsData.content ? projectsData.content : []}
                loading={voteProjectsSummaryStatus.isLoading || projectsStatus.isLoading}
                pagination={{
                    current: filters.page,
                    pageSize: filters.pageSize,
                    total: filters.totalCount,
                    showSizeChanger: true,
                    position: ['bottomCenter'],
                    pageSizeOptions: Pagination()
                }}
                onChange={handleTableChange('projects')}
                rowKey="id"
                onRow={(record, _rowIndex) => {
                    return {
                        onClick: (_event) => { handleRowClick('projects', record) },
                    };
                }}
            />
        </>
    )

    return (
        <ErrorBoundary>
            <div className={styles.container}>
                <div className={cn(styles.leftColumn, { [styles.isSidebarCollapsed]: !isSidebarActive })}>
                    {isModerator ? (
                        <Tabs defaultActiveKey={search ? `${search.slice(5)}` : '1'} activeKey={tabs} onChange={handleChangeTabs}>
                            <Tabs.TabPane tab={"Заявки"} key="1">
                                {ProjectsTab}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Документы" key="2">
                                <ErrorBoundary>
                                    <div className={styles.header}>
                                        <h3 className={styles.title}>Голосование</h3>
                                    </div>

                                    <CountdownComponent endTime={voteDocumentsSummaryData.endDateTime || undefined} />

                                    <table className={styles.infoTable}>
                                        <thead>
                                            <tr>
                                                <th>Дата ознакомления</th>
                                                <th>Начало голосования</th>
                                                <th>Окончание голосования</th>
                                                <th>Количество документов</th>
                                                <th>Проголосовано</th>
                                                <th>Ожидает голосования</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <VoteSummaryTable voteSummaryData={voteDocumentsSummaryData} />
                                        </tbody>
                                    </table>
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <Table
                                        scroll={{ x: 1150 }}
                                        sticky
                                        columns={documentsColumns}
                                        // @ts-ignore
                                        dataSource={documentsData.content ? documentsData.content : []}
                                        loading={voteDocumentsSummaryStatus.isLoading || documentsStatus.isLoading}
                                        pagination={{
                                            current: documentsData.page,
                                            pageSize: documentsData.pageSize,
                                            total: documentsData.totalCount,
                                            showSizeChanger: true,
                                            position: ['bottomCenter'],
                                            pageSizeOptions: Pagination()
                                        }}
                                        onChange={handleTableChange('documents')}
                                        onRow={(record, _rowIndex) => {
                                            return {
                                                onClick: (_event) => { handleRowClick('documents', record) },
                                            };
                                        }}
                                        rowKey="id"
                                    />
                                </ErrorBoundary>
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (
                        <>{ProjectsTab}</>
                    )}

                </div>
                <div className={cn(styles.rightColumn, { [styles.isCollapsed]: !isSidebarActive })}>
                    <VoteSidebar
                        isSidebarOpened={isSidebarActive}
                        closeSidebar={toggleSidebar(false)}
                    />
                </div>

                <VoteModal
                    isOpened={Boolean(voteModalData)}
                    data={voteModalData}
                    onClose={closeVoteModal}
                    isList={true}
                />
            </div>
        </ErrorBoundary>
    );
};
