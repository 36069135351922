import React, {FC} from 'react';
import {Badge, Button, Table} from 'antd';

import {TEditVotingRequestData} from 'src/types/vote';

import {NameCell} from 'src/components/data-table/name-cell';
import {categoryCell} from 'src/components/data-table/category-cell';

import styles from './styles.module.scss';
import {UserOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {TVotingEditorData} from "src/features/admin/voting-editor/index";

type TProps = {
    projects: TEditVotingRequestData['projects'];
    conflictUsersHandler: (project: TVotingEditorData['projects'][0]) => void
}

const ProjectsList: FC<TProps> = ({ projects, conflictUsersHandler }) => {
    const columns: ColumnsType<TEditVotingRequestData['projects'][0]> = [
        {
            title: 'Название',
            dataIndex: 'project.name',
            key: 'project.name',
            width: '40%',
            className: styles.nameCell,
            render: (_: any, record) => NameCell(record.project.name, {}),
        },
        {
            title: 'Организация',
            dataIndex: 'project.owner',
            key: 'project.owner',
            width: '40%',
            className: styles.nameCell,
            render: (_: any, record) => NameCell(record.project.owner, {}),
        },
        {
            title: 'Номинация',
            dataIndex: 'project.nomination',
            key: 'project.nomination',
            render: (_, record) => categoryCell(record.project.nomination),
        },
        {
            title: 'Конфликт интересов',
            dataIndex: 'interestConflictUsers',
            key: 'interestConflictUsers',
            render: (_: any, record) => {
                return (
                    <Badge count={record.interestConflictUsers?.length}>
                        <Button onClick={() => conflictUsersHandler(record)} type={'ghost'}>
                            <UserOutlined/>
                        </Button>
                    </Badge>
                )
            },
        },
    ];
    /**
     *     {
     *         title: 'Бюджет',
     *         dataIndex: 'budget',
     *         key: 'budget',
     *         render: budgetCell,
     *     }, {
     *         title: 'Балл',
     *         dataIndex: 'score',
     *         key: 'score',
     *     }
     */

    return (
        <div className={styles.projectsList}>
            <Table
                columns={columns}
                dataSource={projects}
                pagination={false}
                rowKey="project.id"
            />
        </div>
    );
};

export default ProjectsList;
