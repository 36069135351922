import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import {createBrowserHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {ConfigProvider} from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';

import reportWebVitals from './reportWebVitals';
import {RootRoutes} from './routing/root';
import configureStore from './configure-store';

import './utils/fetchers-config';
import {fetchersInterceptor} from 'src/utils/fetchers-interceptor';

// global styles
import './styles/antd-theme.less';
import './styles/index.scss';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "src/Keycloak";
import {AuthClientEvent} from "@react-keycloak/core/lib/types";

import {Notification} from 'src/shared/lib/notification/ui'

const eventLogger = (eventType: AuthClientEvent, error: any) => {
    console.groupCollapsed('eventLogger')
    console.log('eventType', eventType)
    console.log('error', error)
    console.groupEnd()


    if (eventType === 'onAuthSuccess') {
        // LoginAction()
    }
    if (eventType === 'onAuthRefreshSuccess') {
        // StoreAction.put(GetCurrentUser)
    }

    if (eventType === 'onAuthError' || eventType === 'onInitError') {
        const path = window.location.pathname;
        if (path !== '/error') {
            window.location.replace('/error')
        }

    }
}
export const history = createBrowserHistory();

const { store } = configureStore({}, history);
fetchersInterceptor(store.dispatch);


ReactDOM.render(
    <ConfigProvider locale={ruRu}>
        <Provider store={store}>
            <ReactKeycloakProvider
                authClient={keycloak}
                onEvent={eventLogger}
            >
                {/*// @ts-ignore*/}
                <ConnectedRouter history={history}>
                    <RootRoutes />
                    <Notification/>
                </ConnectedRouter>
            </ReactKeycloakProvider>

        </Provider>
    </ConfigProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
