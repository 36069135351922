import { put, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import { TVoteSummary } from 'src/types/vote';
import { TPagination, TSorting } from 'src/types/pageable-request';

import {
    GetAllVotesListStartAction,
    GetAllVotesListSuccessAction,
    GetAllVotesListErrorAction, SetAllVotesListSortingAction,
} from 'src/store/voting/actions';
import { selectIsModerator } from 'src/store/auth/selectors';
import { selectAllVotesData, selectVotingTypesData } from 'src/store/voting/selectors';

// admin's voting list
export function* getAllVotesListAdminWorker(_action?: ReturnType<typeof GetAllVotesListStartAction>) {
    try {
        const votingTypes = yield select(selectVotingTypesData);
        const projectsData = yield select(selectAllVotesData);
        const isModerator = yield select(selectIsModerator);

        let { data }: AxiosResponse<{
            content: TVoteSummary[];
        } & TPagination & TSorting> = yield axios({
            method: 'POST',
            url: isModerator
                ? `/voting/${votingTypes[0].id}/search`
                : `/user/voting/archive/${votingTypes[0].id}/search`,
            headers: {},
            data: {
                page: projectsData.page - 1,
                pageSize: projectsData.pageSize,
                totalCount: projectsData.totalCount,
                sort: projectsData.sort,
            },
        });

        // as page count starts from 0, add one
        if (data) {
            data.page = data.page + 1;
        }

        if (data.content.length === 0 && data.page > 1) {
            projectsData.page = projectsData.page - 1;
            const {content, ...rest} = projectsData;
            yield put(SetAllVotesListSortingAction(rest))
            yield put(GetAllVotesListStartAction());
        }

        yield put(GetAllVotesListSuccessAction(data));
    } catch (error) {
        yield put(GetAllVotesListErrorAction(error));
    }
}
