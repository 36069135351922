import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { Modal } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import { TEditVotingRequestData } from 'src/types/vote';

import { selectVotingEditorProjects } from 'src/store/voting-editor/selectors';

import styles from './styles.module.scss';
import {LogsActionsStartAction} from "src/store/logs/actions";

type TSelectProjectsModalProps = {
    isOpened: boolean;
    onClose: () => void;
    onSubmit: (projects: TEditVotingRequestData['projects']) => void;
    selectedProjects: TEditVotingRequestData['projects'];
};

export const SelectProjectsModal: FC<TSelectProjectsModalProps> = ({
    isOpened,
    onClose,
    onSubmit,
    selectedProjects,
}) => {
    const put = useDispatch()
    const projects = useSelector(selectVotingEditorProjects);
    const [selected, setSelected] = useState<number[]>([]);

    const onChange = (id: number) => () => {
        if (selected.includes(id)) {
            setSelected(selected.filter((item) => (item !== id)));
        } else {
            setSelected([
                ...selected,
                id,
            ]);
        }
    };

    const handleChangeAll = () => {
        if (selected.length === projects.data.length) {
            setSelected([]);
        } else {
            setSelected(projects.data.map((item) => (+item.id)));
        }
    };

    const handleClose = () => {
        put(LogsActionsStartAction('CANCEL_CREATE_VOTING_BLOCK'))
        put(LogsActionsStartAction('CANCEL_ADD_VOTING_PROJECT'))
        onClose()
    };
    const handleSubmit = () => {
        const result = projects.data.filter((item) => {
            return selected.includes(item.id);
        }).map((el) => {
            const interestConflictUsers = selectedProjects.find((selected) => selected.project.id === el.id)
            return {
                project: el,
                interestConflictUsers: interestConflictUsers?.interestConflictUsers || []
            }
        });
        //@ts-ignore
        onSubmit(result);
    };

    useEffect(() => {
        if (isOpened) {
            setSelected(selectedProjects
                ? selectedProjects.map((item) => (item.project.id)) : []
            );
            put(LogsActionsStartAction('OPEN_VOTING_BLOCK'))
        } else {
            setSelected([]);
        }
    }, [isOpened]);

    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <Modal.Body className={styles.body}>
                <h3 className={styles.header}>
                    Выбор заявок на голосование
                </h3>

                <div className={styles.content}>
                    <div className={styles.selectAll}>
                        <Checkbox
                            className={styles.checkBox}
                            checked={selected.length === projects.data.length}
                            onChange={handleChangeAll}
                        >Выбрать все</Checkbox>
                    </div>
                    { projects.data.map((item) => (
                        <div key={item.id + item.name} className={styles.list}>
                            <div className={styles.item} onClick={onChange(item.id)}>
                                <Checkbox
                                    className={styles.checkBox}
                                    name={`${item.id}`}
                                    checked={selected.includes(item.id)}
                                />
                                <span>
                                    { `${item.name}` }
                                </span>
                            </div>
                        </div>
                    )) }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                >Добавить</Button>
                <Button type="default" onClick={handleClose}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    );
}
