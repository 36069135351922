import React, { SyntheticEvent, useCallback } from 'react';
import { Tooltip } from 'antd';


import styles from './styles.module.scss';
import {useDispatch} from "react-redux";
import {LogsActionsStartAction} from "src/store/logs/actions";

// @ts-ignore
export const InternetResources = ({data}) => {
    const put = useDispatch()
    const handleSocialLink = useCallback((link?: string) => (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let url = link;
        if (!url) return;
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
        put(LogsActionsStartAction('VIEW_INTERNET_RESOURCE'))
    }, []);

    const list = () => (
        data?.map((el: any) => {
            return (
                <Tooltip key={el.id} placement="top" title={el.name}>
                    <img
                        src={el.image ? el.image: '/images/no-icon.png'}
                        alt={el.name}
                        className={styles.icon}
                        onClick={handleSocialLink(el.domainLink)}
                    />
                </Tooltip>
            )
        })
    )
    return data && list()
};