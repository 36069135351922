import { createSelector } from 'reselect';

import { TApplicationState } from 'src/store/application-state';

const getState = (state: TApplicationState) => state.nomination;

export const selectFilter = createSelector(
    getState,
    (state) => state.filter,
);
export const selectNominations = (
    createSelector(
        getState,
        (state) => state.data
    ));
export const selectNominationsStatus = createSelector(
    getState,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error,
    }),
);
