import React, {FC, SyntheticEvent, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
    Button,
    Checkbox, Spin,
} from 'antd';

import { LeftArrowIcon } from 'src/assets/svg/left-arrow';

import {
    GetVotingProjectsStartAction,
    ResetVoteFiltersAction,
    SetVoteFiltersAction,
} from 'src/store/voting/actions';
import {
    GetDocumentsByVotingIdStartAction,
    GetProjectsByVotingIdStartAction, ResetAdminsDocumentsFiltersAction,
    ResetAdminsProjectsFiltersAction,
    SetAdminsDocumentsFiltersAction,
    SetAdminsProjectsFiltersAction,
} from 'src/store/projects/actions';
import { selectVoteFilters } from 'src/store/voting/selectors';
import { selectAdminsDocumentsFilters, selectAdminsProjectsFilters } from 'src/store/projects/selectors';

import styles from './styles.module.scss';
import axios from "axios";

type TProps = {
    isSidebarOpened: boolean;
    closeSidebar: (event: SyntheticEvent) => void;
    type?: 'vote' | 'all-projects-list' | 'all-documents-list',
    id?: number;
};

export const VoteSidebar: FC<TProps> = ({
    closeSidebar,
    type,
    id,
}) => {
    const put = useDispatch();
    const filters = useSelector(getFilters());
    const {loading, nominations} = useGetNominations();

    function getFilters() {
        switch (type) {
            case 'all-projects-list':
                return selectAdminsProjectsFilters;
            case 'all-documents-list':
                return selectAdminsDocumentsFilters;
            default:
                return selectVoteFilters;
        }
    }

    function getAction() {
        switch (type) {
            case 'all-projects-list':
                // @ts-ignore
                return GetProjectsByVotingIdStartAction(+id);
            case 'all-documents-list':
                // @ts-ignore
                return GetDocumentsByVotingIdStartAction(+id);
            default:
                return GetVotingProjectsStartAction();
        }
    }

    function getSetFilters(): Function {
        switch (type) {
            case 'all-projects-list':
                return SetAdminsProjectsFiltersAction;
            case 'all-documents-list':
                return SetAdminsDocumentsFiltersAction;
            default:
                return SetVoteFiltersAction;
        }
    }

    const handleReset = () => {
        switch (type) {
            case 'all-projects-list':
                put(ResetAdminsProjectsFiltersAction());
                break;
            case 'all-documents-list':
                put(ResetAdminsDocumentsFiltersAction());
                break;
            default:
                put(ResetVoteFiltersAction());
        }
        put(getAction());
    };

    // const calculateMinMax = (value: number, name: 'minScore' | 'maxScore'): number => {
    //     let result = value;
    //
    //     try {
    //         result = +(value.toFixed(2));
    //     } catch (e) {}
    //
    //     if (name === 'minScore') {
    //         if (result < 0) result = 0;
    //         if (result > maxScore) result = maxScore - 1;
    //
    //         return result;
    //     }
    //
    //     if (result > 10) result = 10;
    //     if (result < minScore) result = minScore + 1;
    //
    //     return result;
    // };

    // const handleChangeScoreInputs = (name: 'minScore' | 'maxScore') => (value: any) => {
    //     put(getSetFilters()({
    //         ...filters,
    //         filterData: {
    //             ...filters.filterData,
    //             [name]: calculateMinMax(value, name),
    //         },
    //         page: 1,
    //     }));
    //     put(getAction());
    // };

    // const onChangeSlider = (value: number[]) => {
    //     const [minScore, maxScore] = value;
    //
    //     put(getSetFilters()({
    //         ...filters,
    //         filterData: {
    //             ...filters.filterData,
    //             minScore,
    //             maxScore,
    //         },
    //         page: 1,
    //     }));
    //     put(getAction());
    // };

    const onChangeCheckbox = (nominationId: number) => (_value: any) => {
        const isChecked = filters.filterData.nominationIds?.includes(nominationId);
        let nominations: number[] = [ ...filters.filterData.nominationIds ];

        if (isChecked) {
            const index = nominations.indexOf(nominationId);

            if (index !== -1) {
                nominations.splice(index, 1);
            }
        } else {
            nominations.push(nominationId);
        }

        put(getSetFilters()({
            ...filters,
            filterData: {
                ...filters.filterData,
                nominationIds: nominations,
            },
            page: 1,
        }));
        put(getAction());
    }

    // const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    //     put(getSetFilters()({
    //         ...filters,
    //         filterData: {
    //             ...filters.filterData,
    //             searchString: event.target.value,
    //         },
    //         page: 1,
    //     }));
    // };
    //
    // const onSearch = () => {
    //     put(getAction());
    // };

    return (
        <div className={styles.sidebar}>
            <div className={styles.handle} onClick={closeSidebar}>
                <LeftArrowIcon className={styles.arrowIcon} />
            </div>

            {/*<div className={styles.section}>*/}
            {/*    <div className={styles.heading}>Название проекта</div>*/}
            {/*    <div className={styles.content}>*/}
            {/*        <Input.Search*/}
            {/*            className={styles.search}*/}
            {/*            placeholder="Введите"*/}
            {/*            onChange={handleChangeSearch}*/}
            {/*            onSearch={onSearch}*/}
            {/*            value={filters.filterData.searchString}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className={styles.section}>*/}
            {/*    <div className={styles.heading}>Балл</div>*/}
            {/*    <div className={styles.content}>*/}
            {/*        <div className={styles.range}>*/}
            {/*            <InputNumber*/}
            {/*                className={styles.rangeInput}*/}
            {/*                placeholder="0"*/}
            {/*                name="minScore"*/}
            {/*                min={0}*/}
            {/*                max={9}*/}
            {/*                value={minScore}*/}
            {/*                onChange={handleChangeScoreInputs('minScore')}*/}
            {/*            />*/}
            {/*            <span>-</span>*/}
            {/*            <InputNumber*/}
            {/*                className={styles.rangeInput}*/}
            {/*                placeholder="10"*/}
            {/*                name="maxScore"*/}
            {/*                min={1}*/}
            {/*                max={10}*/}
            {/*                value={maxScore}*/}
            {/*                onChange={handleChangeScoreInputs('maxScore')}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        <Slider*/}
            {/*            className={styles.slider}*/}
            {/*            range*/}
            {/*            min={0}*/}
            {/*            max={10}*/}
            {/*            defaultValue={[minScore, maxScore]}*/}
            {/*            value={[minScore, maxScore]}*/}
            {/*            onChange={onChangeSlider}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Spin spinning={loading}>
                <div className={styles.section}>
                    <div className={styles.heading}>Номинация</div>
                    <div className={cn(styles.content, styles.checkboxes)}>
                        { nominations.map(nomination => (
                            <Checkbox
                                key={nomination.id}
                                className={styles.checkbox}
                                checked={filters.filterData.nominationIds?.includes(nomination.id)}
                                onChange={onChangeCheckbox(nomination.id)}
                            >{nomination.name}</Checkbox>
                        ))}
                    </div>
                </div>
            </Spin>


            <div className={styles.section}>
                <div className={styles.content}>
                    <Button type="text" onClick={handleReset}>
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
function useGetNominations() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Array<{id: number, name: string}>>([]);
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const {data} = await axios.post<{content: Array<{id: number, name: string }>}>('/projects/nominations/search', {
                    page: 0,
                    pageSize: 1000,
                    filterData: {
                        hidden: false
                    }
                })
                setData(data.content);
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    return {nominations: data, loading}
}