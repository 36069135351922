import React, { SyntheticEvent } from 'react';
import { Button } from 'antd';

import styles from './styles.module.scss';

export const VoteCell = (
    onClick: Function,
) => (id: number, rowData: any) => {
    const canVote = rowData.voteStatus === 'NOT_VOTED' || rowData.voteStatus === 'PART_VOTED';


    const onCellClick = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    const onButtonClick = () => {
        onClick(id, rowData);
    };

    return (
        <div className={styles.cell} onClick={onCellClick}>
            { canVote && (
                <Button
                    type="default"
                    size="small"
                    className={styles.btn}
                    onClick={onButtonClick}
                >
                    Проголосовать
                </Button>
            )}
        </div>
    );
};
