import React from "react";
import styles from "src/features/project-detailed-page/styles.module.scss";
import pdfImg from "src/assets/img/pdf.png";
import {Tooltip} from "antd";
import viewDocumentImg from "src/assets/img/view-document.png";


type PdfItemProps = {
    files: [],
    data: object,
    label: string,
    openPDFModal: any,
    text: string
}

const PdfItem:React.FC<PdfItemProps> = ({files, data, label, openPDFModal, text}) => {
    return (
        <div className={styles.item}>
            <div className={styles.title}>{text}</div>
            <div className={styles.documentsList}>
                { files.map((file, index: number) => {
                    let name: string = '';

                    try {
                        // @ts-ignore
                        name = data[label] ? data[label][index].fileName : '';
                        name = name.split('.').slice(0, -1).join('.')
                    } catch (_e) {
                        // @ts-ignore
                        name = data[label] ? data[label][index].fileName : '';
                    }
                    return (
                        <div
                            key={`${index}_${name}`}
                            className={styles.document}
                            onClick={openPDFModal(file, name)}>
                            <img src={pdfImg} alt="pdf" className={styles.pdfImg} />
                            <span>{ name }</span>
                            <Tooltip placement="top" title="Просмотр документа">
                                <img
                                    src={viewDocumentImg}
                                    alt="document"
                                    className={styles.documentImg} />
                            </Tooltip>
                        </div>
                    );
                }) }
            </div>
        </div>
    )
}

export default PdfItem;