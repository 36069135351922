import {put, select} from 'redux-saga/effects';
import axios from 'axios';

import {
    GetNominationStartAction,
    GetNominationSuccessAction,
    GetNominationErrorAction,
    SetNominationAction
} from 'src/features/settings/nominations-directory/model/actions';
import {NominationSearch} from "src/features/settings/nominations-directory/types";
import {selectFilter} from "src/features/settings/nominations-directory/model/selectors";
import {RequestData} from "src/features/settings/nominations-directory/types";

export function* setNominationsWorker(_action: ReturnType<typeof SetNominationAction>) {
    try {
        yield put(GetNominationStartAction());
    } catch (error) {
        console.log(error)
    }
}

export function* getNominationsWorker(_action: ReturnType<typeof GetNominationStartAction>) {
    try {
        const filter: RequestData = yield select(selectFilter)
        const filterData = {...filter, page: filter.page - 1}
        const {data} = yield axios.post<NominationSearch>('/projects/nominations/search', filterData)

        yield put(GetNominationSuccessAction(data));
    } catch (error) {
        yield put(GetNominationErrorAction(error));
    }
}
