import { InferValueTypes } from 'src/types/common';
import { VotingActionTypes } from 'src/store/voting/action-types';
import * as actions from '../actions';
import { TVoteOptions } from 'src/types/vote';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TVotingFiltersState = TVoteOptions;

export const initialState: TVotingFiltersState = {
    filterData: {
        nominationIds: [],
        minScore: 0,
        maxScore: 10,
        searchString: '',
    },
    page: 1,
    pageSize: 20,
    totalCount: 0,
    sort: {
        direction: 'ASC',
        field: 'id',
    }
};

export default function reducer(
    state: TVotingFiltersState = initialState,
    action: ActionTypes,
): TVotingFiltersState {
    switch (action.type) {
        case VotingActionTypes.SET_FILTERS:
            return {
                ...state,
                ...action.filters,
            };
        case VotingActionTypes.RESET_FILTERS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
