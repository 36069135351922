import React from "react";
import styles from "src/components/modals/projects-modal/styles.module.scss";

import {Modal} from "react-bootstrap";
import {Button, Form, Radio, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface";
import {FileSize} from "src/constatnts/format";
import ModalInput from "src/components/form-components/modal-input";

type ModalProps = {
    toggleModal: any,
    userForm: any,
    handleChangeCategory: any,
    type: string,
    pass: boolean,
    changePass?: any
}

const ModalContent: React.FC<ModalProps> = ({
    toggleModal, userForm,
    handleChangeCategory, type,
    pass, changePass
}) => {
    //загрузка изображения
    const validateUpload = (_file: File) => {
        return false;
    };
    const removeExistingImage = () => {
        if (userForm.values.existingImage) {
            userForm.setFieldValue(
                'existingImage',
                undefined,
            );
        }
    };
    const uploadImage = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.fileList.length === 0) {
            return;
        }
        const newFile = info.fileList[info.fileList.length - 1];
        try {
            // @ts-ignore
            const type = newFile.type.split('/')[0];
            if (type !== 'image') {
                throw new Error('File is not an image');
            }
            // @ts-ignore
            if (newFile.size > FileSize) {
                throw new Error('File too big');
            }
        } catch (_error) {
            return false;
        }
        removeExistingImage();
        userForm.setFieldValue('image', [newFile]);
    };
    const onRemoveFile = (fieldName: string) => (_file: any) => {
        userForm.setFieldValue(fieldName, []);
    }
    return (
        <Modal
            show={true}
            onHide={toggleModal}
            backdrop="static"
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <form
                name="userForm"
                onSubmit={userForm.handleSubmit}
                className={styles.form}
            >

                <Modal.Header className={styles.modalHeader}>
                    <h3 className={styles.header}>
                        {type === 'create' ? 'Добавление пользователя' : 'Редактирование пользователя'}
                    </h3>
                </Modal.Header>
                <Modal.Body className={styles.body}>
                    <div className={styles.avatar}>
                        <img src={!userForm.initialValues.existingImage ?
                            '/images/avatar.png' :
                            userForm.initialValues.existingImage} alt="avatar" className={styles.img}/>
                        <Upload
                            accept="image/*"
                            className={styles.upload}
                            listType="picture"
                            multiple={false}
                            beforeUpload={validateUpload}
                            onChange={uploadImage}
                            onRemove={onRemoveFile('image')}
                            fileList={userForm.values.image}
                        >
                            <Button icon={<UploadOutlined />}>
                                {userForm.initialValues.existingImage ? 'Изменить изображение' : 'Добавить изображение'}
                            </Button>
                        </Upload>
                    </div>
                    <hr/>
                    <ModalInput form={userForm} label={'username'} placeholder={'User name'} text={'Логин пользователя'}/>
                    {!pass ? (
                        <Button
                            style={{'marginBottom': '15px'}}
                            type="primary"
                            onClick={changePass}
                        >Изменить пароль</Button>
                    ) : (
                        <ModalInput form={userForm} label={'password'} placeholder={'password'} text={'Пароль пользователя'}/>
                    )}
                    <ModalInput form={userForm} label={'email'} placeholder={'Email'} text={'Email пользователя'}/>
                    <hr/>
                    <ModalInput form={userForm} label={'lastName'} placeholder={'Фамилия'} text={'Фамилия пользователя'}/>
                    <ModalInput form={userForm} label={'firstName'} placeholder={'Имя'} text={'Имя пользователя'}/>
                    <ModalInput form={userForm} label={'middleName'} placeholder={'Отчество'} text={'Отчество пользователя'}/>
                    <hr/>
                    <ModalInput form={userForm} label={'organization'} placeholder={'Организация'} text={'Организация'}/>
                    <ModalInput form={userForm} label={'position'} placeholder={'Позиция'} text={'Должность'}/>


                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            userForm.submitCount > 0 && userForm.errors.roles
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="roles" className={styles.label}>
                            Роль в системе
                        </label>
                        <Radio.Group
                            name="roles"
                            value={userForm.values.roles[0]}
                            onChange={handleChangeCategory}
                        >
                            <Radio key={1} value={'ROLE_USER'} className={styles.radio}>
                                User
                            </Radio>
                            <Radio key={2} value={'ROLE_MODERATOR'} className={styles.radio}>
                                Moderator
                            </Radio>
                            <Radio key={3} value={'ROLE_ADMIN'} className={styles.radio}>
                                Admin
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        className={styles.formItem}
                        validateStatus={ (
                            userForm.submitCount > 0 && userForm.errors.status
                        ) ? 'error' : '' }
                    >
                        <label htmlFor="status" className={styles.label}>
                            Статус
                        </label>
                        <Radio.Group
                            name="status"
                            value={userForm.values.status}
                            onChange={handleChangeCategory}
                        >
                            <Radio key={1} value={'BLOCKED'} className={styles.radio}>
                                BLOCKED
                            </Radio>
                            <Radio key={2} value={'ACTIVE'} className={styles.radio}>
                                ACTIVE
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="default" onClick={toggleModal}>Отменить</Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >Сохранить</Button>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

export default ModalContent