import { put } from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';

import {
    VoteStartAction,
    VoteSuccessAction,
    VoteErrorAction,
    GetProjectsVotingIdTriggerAction,
    GetDocumentsVotingIdTriggerAction,
} from 'src/store/voting/actions';
import {NotificationOpen} from "src/shared/lib/notification";

type ResponseVote = {
    dateTime: string,
    id: number,
    message: null | string
}
export function* voteWorker({ data: { voteDetails, id, isList } }: ReturnType<typeof VoteStartAction>) {
    try {
        if (id) {
            const {data}: AxiosResponse<ResponseVote> = yield axios.put(
                `/user/voting/votes/${id}`,
                voteDetails
            )
            if (data.message) {
                NotificationOpen({type: 'info', info: {message: data.message}})
            }
        } else {
            const { data }: AxiosResponse<ResponseVote> = yield axios({
                method: 'POST',
                url: `/user/voting/votes`,
                headers: {},
                data: voteDetails,
            });
            if (data.message) {
                NotificationOpen({type: 'info', info: {message: data.message}})
            }
        }

        yield put(VoteSuccessAction());

        if (isList) {
            yield put(GetProjectsVotingIdTriggerAction());
            yield put(GetDocumentsVotingIdTriggerAction());
        }
    } catch (error) {
        console.log('voteWorker', error);
        yield put(VoteErrorAction(error));
    }
}
