import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import styles from "src/components/modals/projects-modal/styles.module.scss";
import {Button, Checkbox, Form, Radio, Select, Spin} from "antd";
import moment from "moment";
import {VideoPresentationModal} from "src/components/modals/video-presentation-modal";
import {InternetResourcesModal} from "src/components/modals/internet-resources-modal";
import {useDispatch, useSelector} from "react-redux";
import {selectFormats} from "src/store/formats/selectors";
import {selectYears} from "src/store/years/selectors";
import {selectAllSessions} from "src/store/sesssions/selectors";
import {RadioChangeEvent} from "antd/es/radio";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {TProjectFormData} from "src/components/modals/projects-modal/index";
import ModalInput from "src/components/form-components/modal-input";
import ModalInputNumber from "src/components/form-components/modal-inputNumber";
import ModalUpload from "src/components/form-components/modal-upload";
import {LogsActionsStartAction} from "src/store/logs/actions";
import DatepickerComponent from "src/components/form-components/datepicker";
import {GetFormatsStartAction} from "src/store/formats/actions";
import TextAreaQuill from "src/components/form-components/textAreaQuill";
import {ErrorBoundary} from "src/components/error-boundary";
import axios from "axios";
import {Nomination} from "src/features/settings/nominations-directory/types";


type ModalContentProps = {
    isOpened: boolean,
    isEdit: boolean,
    onClose: any,
    projectForm: any,
    isRealization?: boolean
}

const { Option } = Select;

const ModalContent:React.FC<ModalContentProps> = ({
    isOpened,
    isEdit,
    onClose,
    projectForm,
    isRealization
}) => {
    const [state, setState] = useState({
        is2020: false
    });
    const {nominations, loading, GetNominations} = useNominations();
    const formats = useSelector(selectFormats);
    const years = useSelector(selectYears);
    const sessions = useSelector(selectAllSessions);

    const put = useDispatch();

    const [presentationUrl, setPresentationUrl] = useState<string | undefined>(undefined);
    const [internetResources, setInternetResources] = useState<TProjectFormData['internetResources'] | undefined>(undefined);

    useEffect(() => {
        if (formats.length === 0) put(GetFormatsStartAction())
        if (isEdit && projectForm.values.sessionId) {
            GetNominations(Number(projectForm.values.sessionId))
        }
    }, [])

    const openIRModal = () => {setInternetResources(projectForm.values.internetResources)};
    const closeIRModal = () => {setInternetResources(undefined)};
    const submitIRModal = (value: TProjectFormData['internetResources']) => {
        projectForm.setFieldValue('internetResources', value);
        closeVideoModal();
    };

    const openVideoModal = () => {setPresentationUrl(projectForm.values.presentationUrl)};
    const closeVideoModal = () => {setPresentationUrl(undefined)};
    const submitVideoModal = (value: string) => {
        projectForm.setFieldValue('presentationUrl', value);
        closeVideoModal();
    };
    const handleChangeSelect = (fieldName: string, fieldNameSecond?: string) => (value: string, option: any) => {
        if (option && option.value) {
            projectForm.setFieldValue(fieldName, option.value);
        } else {
            projectForm.setFieldValue(fieldName, '');
        }
        fieldNameSecond && projectForm.setFieldValue(fieldNameSecond, '');
        if (fieldName === 'sessionId') {
            GetNominations(Number(value))
        }
    };
    const handleQuillField = (fieldName: string) => (content: string) => {
        if (content === '<p><br></p>') projectForm.setFieldValue(fieldName, '');
        else projectForm.setFieldValue(fieldName, content);
    };
    const handleChangeCategory = (e: RadioChangeEvent) => {
        projectForm.setFieldValue('nominationId', e.target.value);
    };
    const handleChangeFormat = (values: CheckboxValueType[]) => {
        projectForm.setFieldValue('formatIds', values);
    };

    useEffect(() => {
        if (!projectForm.values.implStartDate) return
        if (!projectForm.values.implEndDate) return;
        if (projectForm.values.implStartDate >= projectForm.values.implEndDate) {
            projectForm.setErrors({'implEndDate': 'Дата окончания не может быть раньше даты начала'})
        }
    }, [projectForm.values.implStartDate, projectForm.values.implEndDate])

    useEffect(() => {
        if (String(projectForm.values.year) === '2020' && !state.is2020) setState({is2020: true})
        else if (String(projectForm.values.year) !== '2020' && state.is2020) setState({is2020: false})
    }, [projectForm.values.year])


    return (
        <Modal
            show={isOpened}
            onHide={onClose}
            backdrop="static"
            keyboard={true}
            scrollable={true}
            backdropClassName={styles.backDrop}
            dialogClassName={styles.dialog}
        >
            <ErrorBoundary submitF={onClose}>
                <form
                    name="projectForm"
                    onSubmit={projectForm.handleSubmit}
                    className={styles.form}
                >
                    <Modal.Header className={styles.modalHeader}>
                        <h3 className={styles.header}>
                            { isEdit ? 'Редактирование проекта' : 'Добавление проекта' }
                        </h3>
                    </Modal.Header>
                    <Modal.Body className={styles.body}>
                        <ModalInput form={projectForm} label={"name"} text={'Название проекта'} />


                        <div className={styles.cantChange}>
                            <small>Номер заявки</small>
                            <span>{projectForm.values.requestNumber}</span>
                        </div>

                        <ModalInput form={projectForm} label={"owner"} text={'Организация'} />

                        {isRealization ? (
                            <div className={styles.cantChange}>
                                <span>Год проекта: {projectForm.values.year}</span>
                                <span>{projectForm.values.sessionId} сессия</span>
                            </div>
                        ) : (
                            <div className={styles.formRow}>
                                <div className={styles.formCell}>
                                    <Form.Item
                                        className={styles.formItem}
                                        validateStatus={ (
                                            projectForm.submitCount > 0 && projectForm.errors.year
                                        ) ? 'error' : '' }
                                    >
                                        <label htmlFor="year" className={styles.label}>
                                            Год проекта
                                        </label>
                                        <Select
                                            allowClear={true}
                                            value={projectForm.values.year ?`${projectForm.values.year}` : undefined}
                                            onChange={handleChangeSelect('year', 'sessionId')}
                                        >
                                            { years.map((item) => (
                                                <Option key={item} value={item}>{item}</Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className={styles.formCell}>
                                    <Form.Item
                                        className={styles.formItem}
                                        validateStatus={ (
                                            projectForm.submitCount > 0 && projectForm.errors.sessionId
                                        ) ? 'error' : '' }
                                    >
                                        <label htmlFor="sessionId" className={styles.label}>
                                            Отбор
                                        </label>
                                        <Select
                                            allowClear={true}
                                            disabled={!projectForm.values.year}
                                            value={projectForm.values.sessionId ? `${projectForm.values.sessionId}` : undefined}
                                            onChange={handleChangeSelect('sessionId')}
                                        >
                                            { sessions.filter((item) => {
                                                return !(years.length === 0 ||
                                                    (moment(item.endDate).format('yyyy') !== `${projectForm.values.year}`));
                                            }).map((item) => (
                                                <Option key={item.id} value={`${item.id}`}>{item.name}</Option>
                                            )) }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        )}


                        {isRealization ? projectForm.values.expertise && (
                            <div className={styles.cantChange}>
                                <small>Юридическая экспертиза</small>
                                <span>{projectForm.values.expertise}</span>
                            </div>
                        ) : (
                            <TextAreaQuill
                                form={projectForm}
                                text={'Ссылки'}
                                label={'linksAwards'}
                                handler={handleQuillField('linksAwards')}/>
                        )}
                        <TextAreaQuill
                            form={projectForm}
                            text={'Описание проекта'}
                            label={'description'}
                            handler={handleQuillField('description')}/>

                        {isRealization ? projectForm.values.nameNominees && (
                            <div className={styles.cantChange}>
                                <small>Команда проекта</small>
                                <span>{projectForm.values.nameNominees}</span>
                            </div>
                        ) : (
                            <TextAreaQuill
                                form={projectForm}
                                text={'Команда проекта'}
                                label={'nameNominees'}
                                handler={handleQuillField('nameNominees')}/>
                        )}

                        <Spin spinning={loading}>
                            <Form.Item
                                className={styles.formItem}
                                validateStatus={ (
                                    projectForm.submitCount > 0 && projectForm.errors.nominationId
                                ) ? 'error' : '' }
                            >
                                <label htmlFor="categoryId" className={styles.label}>
                                    Номинация
                                </label>
                                <Radio.Group
                                    name="nominationId"
                                    value={projectForm.values.nominationId}
                                    disabled={!projectForm.values.sessionId}
                                    onChange={handleChangeCategory}
                                >
                                    { nominations.map((nomination) => (
                                        <Radio key={nomination.id} value={nomination.id} className={styles.radio}>
                                            { nomination.name }
                                        </Radio>
                                    )) }
                                </Radio.Group>
                                {nominations.length === 0 && 'Нет номинаций в данном отборе'}
                            </Form.Item>
                        </Spin>

                        <Form.Item
                            className={styles.formItem}
                            validateStatus={ (
                                projectForm.submitCount > 0 && projectForm.errors.formatIds
                            ) ? 'error' : '' }
                        >
                            <label htmlFor="formatIds" className={styles.label}>
                                Формат контента
                            </label>
                            <Checkbox.Group
                                name="formatIds"
                                onChange={handleChangeFormat}
                                value={projectForm.values.formatIds}
                                className={styles.checkBoxGroup}
                                options={formats}
                            />
                        </Form.Item>

                        <TextAreaQuill
                            form={projectForm}
                            text={'Описание формата контента'}
                            label={'formatDescription'}
                            handler={handleQuillField('formatDescription')}/>

                        <TextAreaQuill
                            form={projectForm}
                            text={'Собственный сайт проекта'}
                            label={'ownProjectSite'}
                            handler={handleQuillField('ownProjectSite')}/>

                        {!isRealization && (
                            <TextAreaQuill
                                form={projectForm}
                                text={'Достижения'}
                                label={'achievements'}
                                handler={handleQuillField('achievements')}/>
                        )}

                        {isRealization ? (
                            <div className={styles.cantChange}>

                                <small>Бюджет</small>
                                <span>{projectForm.values.budget}</span><br/>

                                <small>Плановый КПЭ</small>
                                <span>{projectForm.values.kpi}</span><br/>

                                <small>Софинансирование</small>
                                <span>{projectForm.values.cofinancing}</span><br/>

                                {projectForm.values.score > 0 && (
                                    <>
                                        <small>Балл</small>
                                        <span>{projectForm.values.score}</span>
                                    </>
                                )}

                            </div>
                        ) : (
                            <>
                                <ModalInputNumber form={projectForm} label={'budget'} text={'Бюджет'} />
                                <ModalInputNumber form={projectForm} label={'supportAmount'} text={'Сумма оказанной поддержки'} />
                                <ModalInputNumber form={projectForm} label={'kpi'} text={'Плановый КПЭ'} />
                                <ModalInputNumber form={projectForm} label={'actualKpi'} text={'Фактический КПЭ'} />
                                <TextAreaQuill
                                    form={projectForm}
                                    text={'Софинансирование'}
                                    label={'cofinancing'}
                                    handler={handleQuillField('cofinancing')}/>
                                <ModalInputNumber form={projectForm} label={'score'} text={'Балл'} />
                                <ModalInputNumber form={projectForm} label={'recommendedCostReduction'} text={'Рекомендуемое снижение стоимости'} />
                            </>
                        )}

                        <hr/>

                        <div className={styles.realisation}>
                            <span className={styles.title}>Сроки реализации</span>
                            <div className={styles.formRow}>
                                <div className={styles.formCell}>
                                    <DatepickerComponent
                                        form={projectForm}
                                        label={'implStartDate'}
                                        text={'Планируемая дата начала реализации'}
                                    />
                                </div>
                                <div className={styles.formCell}>
                                    <DatepickerComponent
                                        form={projectForm}
                                        label={'implEndDate'}
                                        text={'Планируемая дата окончания реализации'}/>
                                </div>
                            </div>
                            {projectForm.errors.implEndDate && (
                                <span className={styles.title} style={{'color': 'tomato'}}>{projectForm.errors.implEndDate}</span>
                            )}
                            <br/>
                            <ModalInputNumber form={projectForm} label={'implTerms'} text={'Срок реализации в месяцах'} />
                        </div>

                        <hr/>

                        {!isRealization && (
                            <div className={styles.modalLink}>
                                <label className={styles.label}>Видеопрезентация</label>
                                <Button
                                    type="default"
                                    shape="circle"
                                    className={styles.add}
                                    onClick={openVideoModal}
                                >+</Button>
                            </div>
                        )}


                        <div className={styles.modalLink}>
                            <label className={styles.label}>Интернет-ресурсы</label>
                            <Button
                                type="default"
                                shape="circle"
                                className={styles.add}
                                onClick={openIRModal}
                            >+</Button>
                        </div>

                        {!isRealization && (
                            <>
                                <ModalUpload
                                    form={projectForm}
                                    label={'image'}
                                    existingLabel={'existingImage'}
                                    text={'Изображение проекта'}
                                    isEdit={isEdit}
                                    type={'single'}
                                    isImg={true}
                                />
                                <ModalUpload
                                    form={projectForm}
                                    label={'presentationFile'}
                                    existingLabel={'existingPresentationFile'}
                                    text={'Презентация проекта'}
                                    isEdit={isEdit}
                                    type={'single'}
                                    isImg={false}
                                />
                                <ModalUpload
                                    form={projectForm}
                                    label={'requestFiles'}
                                    existingLabel={'existingRequestFiles'}
                                    text={'Заявка'}
                                    isEdit={isEdit}
                                    type={'multi'}
                                    isImg={false}
                                />

                                <ModalUpload
                                    form={projectForm}
                                    label={'examinationFiles'}
                                    existingLabel={'existingExaminationFiles'}
                                    text={'Экспертиза'}
                                    isEdit={isEdit}
                                    type={'multi'}
                                    isImg={false}
                                />

                                <ModalUpload
                                    form={projectForm}
                                    label={'otherFiles'}
                                    existingLabel={'existingOtherFiles'}
                                    text={'Дополнения'}
                                    isEdit={isEdit}
                                    type={'multi'}
                                    isImg={false}
                                />
                            </>
                        )}


                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="default" onClick={(e) => {
                            onClose(e)
                            if (isEdit) put(LogsActionsStartAction('CANCEL_EDIT_PROJECT'))
                            else put(LogsActionsStartAction('CANCEL_CREATE_PROJECT'))
                        }}>Отменить</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >Сохранить</Button>
                    </Modal.Footer>
                </form>

                <VideoPresentationModal
                    isOpened={typeof presentationUrl === 'string'}
                    onSubmit={submitVideoModal}
                    onClose={closeVideoModal}
                    value={projectForm.values.presentationUrl}
                />
                {Boolean(internetResources) && (
                    <InternetResourcesModal
                        isOpened={Boolean(internetResources)}
                        onSubmit={submitIRModal}
                        onClose={closeIRModal}
                        value={projectForm.values.internetResources}
                    />
                )}
            </ErrorBoundary>
        </Modal>
    );
}
function useNominations() {
    const [state, setState] = useState<Nomination[]>([]);
    const [loading, setLoading] = useState(false)
    async function GetNominations(sessionId?: number) {
        try {
            const params = sessionId ? `?sessionIds=${sessionId}` : '';
            setLoading(true);
            const { data } = await axios.get<Nomination[]>(
                '/projects/nominations' + params,
            )
            setState(data)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        (async () => {
            await GetNominations();
        })();
    }, []);
    return {nominations: state, loading, GetNominations}
}
export default ModalContent;