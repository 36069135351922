import React, {FC, useRef} from 'react';
import styles from './styles.module.scss';
import classNames from "classnames";
import Sidebar from "src/widgets/sidebar";
import {useDispatch, useSelector} from "react-redux";
import {selectMenuInfo} from "src/store/general/selectors";
import {ToggleSidebarMenuAction} from "src/store/general/actions";
import {ArrowUpOutlined} from "@ant-design/icons";
import {BackTop} from "antd";
import {selectIsModerator} from "src/store/auth/selectors";

const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#0050B2',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const Main: FC<{children: React.ReactNode}> = ({children}) => {
    const put = useDispatch();
    const isModerator = useSelector(selectIsModerator);
    const menuInfo = useSelector(selectMenuInfo);
    const { isSidebarOpen } = menuInfo;
    const handler = () => {
        if (isSidebarOpen) {
            if (window.innerWidth <= 992) {
                put(ToggleSidebarMenuAction())
            }
        }
    };

    const ref = useRef(null);

    return (
        <main className={classNames(styles.mainTemplate, menuInfo)}>
            {isModerator && <Sidebar/>}
            <div ref={ref} className={classNames(styles.mainTemplate__content, menuInfo)} onClick={handler}>
                {children}
            </div>
            <BackTop target={() => {
                return ref.current ?? document
            }}>
                {/*// @ts-ignore*/}
                <div style={style}><ArrowUpOutlined /></div>
            </BackTop>
        </main>
    )
}

export default Main;