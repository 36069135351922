import React, {FC, useEffect, useState,} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {push} from 'connected-react-router';
import {Button, Table} from 'antd';
import {Key, TablePaginationConfig,} from 'antd/lib/table/interface';

import {mainModuleRoutes} from 'src/routing/projects-module';

import {GetDocumentsByVotingIdStartAction, SetAdminsDocumentsFiltersAction,} from 'src/store/projects/actions';
import {GetAllDocumentsReportStartAction, GetAllDocumentsVoteSummaryStartAction,} from 'src/store/voting/actions';
import {DeleteVotingAction} from 'src/store/voting-editor/actions';
import {LogsActionsStartAction} from 'src/store/logs/actions';

import {
    selectAdminsDocuments,
    selectAdminsDocumentsFilters,
    selectAdminsDocumentsStatus,
} from 'src/store/projects/selectors';
import {selectAllDocumentsVoteSummary,} from 'src/store/voting/selectors';
import {selectIsModerator} from 'src/store/auth/selectors';

import {handleGoToVotingEditor} from 'src/utils/redirects';

import {statusVoteCell} from "src/components/data-table/status-vote-cell";
import {NameCell} from 'src/components/data-table/name-cell';
import {VoteSummaryTable} from 'src/components/vote-summary-table';
import {CountdownComponent} from 'src/components/countdown';
import {ConfirmModal} from 'src/components/modals/confirm-modal';
import {TooltipComponent} from 'src/components/tooltip';

import {ToParentPage} from 'src/components/to-parent-page';
import styles from './styles.module.scss';
import moment from "moment";
import {Pagination} from "src/shared/lib/pagination";


type TProps = {
    isArchive?: boolean;
};

export const AllDocumentsListPage: FC<TProps> = (props) => {
    const [confirmDeleteIsOpened, setConfirmDeleteIsOpened] = useState(false);
    const put = useDispatch();
    const { pathname } = useLocation();
    const votingId = +pathname.split('/')[2];
    const documents = useSelector(selectAdminsDocuments);
    const filters = useSelector(selectAdminsDocumentsFilters);
    const { isLoading } = useSelector(selectAdminsDocumentsStatus);
    const summary = useSelector(selectAllDocumentsVoteSummary);
    const isModerator = useSelector(selectIsModerator);
    // const documentsReport = useSelector(selectAllDocumentsReportData);

    const handleDelete = () => {
        put(DeleteVotingAction(votingId))
        setConfirmDeleteIsOpened(false);
    };

    const handleDownloadReport = (reportType: 'comments'| 'votes') => () => {
        put(GetAllDocumentsReportStartAction(votingId, reportType));
    };

    const canVoting = () => {
        if (!!summary.endDateTime) {
            const now = moment(new Date());
            const end = moment(summary.endDateTime);
            if (end.diff(now, "minutes") > 0) return true
            return false
        } else return true
    }
    let columns = [
        {
            title: props.isArchive ? 'Статус голосования' : 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: statusVoteCell(canVoting()),
            width: 100,
        }, {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            width: 200,
            className: styles.nameCell,
            render: NameCell,
        }, {
            title: 'Вопрос',
            dataIndex: 'question',
            key: 'question',
            sorter: true,
            width: 200,
            className: styles.nameCell,
            render: NameCell,
        },
    ];

    const handleRowClick = (id: number) => {
        const url = (props.isArchive
            ? mainModuleRoutes['vote-document-archive'].path
            : mainModuleRoutes['vote-document'].path)
            .replace(':votingId', `${votingId}`)
            .replace(':projectId', `${id}`)
        put(push(url));
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        const newSorting = {
            page: pagination.current ? pagination.current : 1,
            pageSize: pagination.pageSize ? pagination.pageSize : 20,
            sort: {
                direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
                field: sorter.field ? sorter.field : 'id',
            },
        };

        put(SetAdminsDocumentsFiltersAction({
            ...filters,
            ...newSorting,
        }));

        put(GetDocumentsByVotingIdStartAction(votingId));
    };

    useEffect(() => {
        put(GetDocumentsByVotingIdStartAction(votingId));
        put(GetAllDocumentsVoteSummaryStartAction(votingId));
    }, [put, votingId]);

    useEffect(() => {
        put(LogsActionsStartAction('OPEN_DOCUMENT_VOTING', `${votingId}`))
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.leftColumn}>
                <ToParentPage text="" parentPath={props.isArchive
                    ? mainModuleRoutes['archive-votes'].path
                    : mainModuleRoutes['all-votes-list'].path} />
                { isModerator && (
                    <div className={styles.actions}>
                        <TooltipComponent
                            isActive={Boolean(summary.activated)}
                            message="Деактивируйте голосование что бы редактировать"
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={Boolean(summary.activated)}
                                onClick={handleGoToVotingEditor({
                                    put: put,
                                    type: 'documents',
                                    votingId: votingId.toString(),
                                })}
                            >Редактировать</Button>
                            <Button
                                type="default"
                                disabled={Boolean(summary.activated)}
                                onClick={() => {setConfirmDeleteIsOpened(true)}}
                            >Удалить</Button>
                        </TooltipComponent>

                        {confirmDeleteIsOpened && (
                            <ConfirmModal
                                isOpened={confirmDeleteIsOpened}
                                onClose={() => {setConfirmDeleteIsOpened(false)}}
                                onSubmit={handleDelete}
                                message={`Вы действительно хотите удалить это голосование?'`}/>
                        )}
                    </div>
                ) }

                <CountdownComponent endTime={summary.endDateTime || undefined} />

                { isModerator && (
                    <div className={styles.reports}>
                        <Button
                            className={styles.report}
                            type="link"
                            onClick={handleDownloadReport('votes')}
                        >
                            Итоговое голосование
                        </Button>
                        {/*<Button*/}
                        {/*    className={styles.report}*/}
                        {/*    type="link"*/}
                        {/*    onClick={handleDownloadReport('comments')}*/}
                        {/*>*/}
                        {/*    Особое мнение*/}
                        {/*</Button>*/}
                    </div>
                ) }

                <table className={styles.infoTable}>
                    <thead>
                        <tr>
                            <th>Дата ознакомления</th>
                            <th>Начало голосования</th>
                            <th>Окончание голосования</th>
                            { !props.isArchive && (<th>Проголосовали за все документы</th>) }
                        </tr>
                    </thead>

                    <tbody>
                        <VoteSummaryTable voteSummaryData={summary} isAdmin={true} isArchive={props.isArchive} />
                    </tbody>
                </table>

                <Table
                    scroll={{ x: 900 }}
                    sticky
                    columns={columns}
                    dataSource={documents}
                    loading={isLoading}
                    pagination={{
                        current: filters.page,
                        pageSize: filters.pageSize,
                        total: filters.totalCount,
                        showSizeChanger: true,
                        position: ['bottomCenter'],
                        pageSizeOptions: Pagination()
                    }}
                    onChange={handleTableChange}
                    rowKey="id"
                    onRow={(record, _rowIndex) => {
                        return {
                            onClick: (_event) => { handleRowClick(record.id as number) },
                        };
                    }}
                />
            </div>
        </div>
    );
};
